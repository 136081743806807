import axios from "axios";

const urlPath = "aaff";

export const getList = async () => {
  try {
    const response = await axios.get(`${urlPath}/getlist`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getWelcomeAlert = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/getWelcomeAlertList`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const validateWelcomeAlert = async (id) => {
  console.log(id)
  try {
    const response = await axios.post(`${urlPath}/WelcomeAlertValidate/${id}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getReclamationAlert = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/getReclamationAlertList`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const validateReclamationAlert = async (id) => {
  console.log(id)
  try {
    const response = await axios.post(`${urlPath}/ReclamationAlertValidate/${id}`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPropertiFromAAFFfilter = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.error(err)
    console.log(err.response);
  }
};

export const getListAAFFs = async () => {
  try {
    const response = await axios.get(`${urlPath}/aaffList`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getAAFFs = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getListadoAaffsSupp = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/ListadoAaffsSupp`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAAFF = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    // console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const createAAFF = async (data) => {
  console.log(data)
  // let datos = {
  //   razonSocial: data.razonSocial,
  //   nif: data.nif,
  //   email: data.email,
  //   telefono: data.telefono,
  //   nombreContacto: data.contacto,
  //   municipio: data.municipio,
  //   provincia: data.provincia,
  //   commer_id: data.commer_id,
  // };
  // console.log(datos)
  try {
    const response = await axios.post(urlPath, data);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const editarAAFF = async (data) => {
  console.log(data);

  try {
    const response = await axios.put(`${urlPath}/${data.nif}`, data);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteAAFF = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const changeAAFF = async (nifFinca, nuevoaaff) => {
  let datos = {
    nifAAFF: nuevoaaff,
  };
  try {
    const response = await axios.put(`propertie/${nifFinca}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const sendEmailBienvenida = async (id, nifFinca, razonSocialAAFF, email) => {
  const datos = {
    user_id:id,
    email,
    razonSocial: razonSocialAAFF
  }
  console.log(datos)
  try {
    const response = await axios.post(`${urlPath}/sendEmail/${nifFinca}`, datos,{
      nifFinca
    });

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
