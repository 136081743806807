import React, { useState, useEffect } from "react";
import Portada from "../../../images/PortadaRiesgos.png"
import Encabezado from "../../../images/EncabezadoRiesgos.png"
import Logo from "../../../images/LogoLAENEW.png"
import salida from "../../../images/salida.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import salidaEmergencia1 from "../../../images/salidaEmergencia1.png"
import extintor from "../../../images/extintor.png"
import ascensor from "../../../images/ascensor.png"
import peligro from "../../../images/peligro.png"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@mui/material/CircularProgress';
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet, Link } from "@react-pdf/renderer"
import { getAAFF } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";

const InformeRiesgos = (props) => {
    const styles = StyleSheet.create({
        imgPrincipal:{
            height:'400px', 
            width:'100%', 
            marginTop:30
        },
        body: {
            //paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: '0cm',
        },
        table: { 
            display: "table", 
            width: "80%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            margin:'auto'
            // padding:'25px 0'
        }, 
        tableRiesgos: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            fontStyle: 'bold',
            // padding:'25px 0'
        }, 
        tableRowHeader: { 
            marginTop:"30px",
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableColHeader: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        /* tableColHeader1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, */ 
        tableCol: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'left',
            alignItems:'left'
        }, 
        /* tableCol1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        },  */
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 8 
        },
        tableCellR: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 6 
        },
        tableColHeaderRiesgos1: { 
            width: "15%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos2: { 
            width: "10%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos3: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos4: { 
            width: "45%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColRiesgos1: { 
            width: "15%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos2: { 
            width: "10%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos3: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos4: { 
            width: "45%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableCellRiesgos: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 6 
        },
        tableCellRiesgos1: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 5 
        },
        pdfHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            backgroundColor: '#ffffff',
            /* margin:'2cm', */
        },
        pdfHeaderImg:{
            width:'100%', 
            height:'60px',
            marginBottom:'30px',
        },
        tablePage: {
            width: '100%',
        },
        headerSpace: {
            height: '90px',
        },
        div: {
            fontSize: '10px'
        },
        h1: {
            /* marginLeft:'2cm',
            marginRight:'2cm', 
            marginTop: '50px',*/
            marginBottom: '30px',
            fontSize: '14px',
            fontWeight: '600',
        },
         h2: {
            fontSize: '11px',
            fontWeight: '600',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            marginTop: '30px',
            marginBottom: '30px',
        },
        h3: {
            fontSize: '9px',
            width: '100%',
            padding: '5px 10px',
            // backgroundColor: '#f8f8f8',
            textTransform: 'uppercase',
            // border: '1px solid #e6e6e6',
            // marginTop: '25px',
            // marginBottom: '15px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'20cm', */
            textDecoration: 'underline'
        },
        h4: {
            color: '#ab2a3e',
            marginBottom: '10px',
            marginTop: '20px',
            fontSize: '9px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        h5: {
            fontSize: '10px',
            marginTop: '15px',
            marginBottom: '5px',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        /* tableRiesgos: {
            borderCollapse: 'collapse',
            width: '100%',
            tableLayout: 'fixed',
            marginBottom: '24px',
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        }, */
        simpleTable: {
            borderCollapse: 'collapse',
            width: '80%',
            margin: '0 10% 24px 10%',
        },
        simpleTableTh: {
            textTransform: 'uppercase',
        },
        simpleTableTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: '1.1'
        },
        ul: {
            // lineHeight: '1.8',
            // fontSize:'7px',
            flexDirection: "column", 
            width: 150,
        },
        ol: {
            lineHeight: '1.8',
            flexDirection: "row", 
            marginBottom: 4,
            fontSize: '7px',
        },
        p: {
            padding: '5px 10px',
            lineHeight: '1.8',
            textAlign: 'justify',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            fontSize: '7px',
        },
        imgP:{
            width:'100px', 
            marginBottom:'30px',
        },
        subp: {
            lineHeight: '1.8',
            textAlign: 'justify',
            marginLeft: '8px',
            fontSize: '8px',
        },
        br: {
            display: 'block',
            margin: '10px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        brText: {
            display: 'block',
            margin: '2px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        footerSpace: {
            height: 50
        },
        espacio: {
            display:'block',
            marginBottom: '5px'
        },
        page: {
            size: 'A4 portrait',
            margin: '2cm',
        },
        pagebreak: {
            clear: 'both',
            pageBreakAfter: 'always',
        }
    });

    const [fincas, setFincas] = useState([])
    const [fincasHV, setFincasHV] = useState([])
    const [aaff, setAAFFS] = useState([])
    const [numP, setNumP] = useState({})
    const [numPCt, setNumPCt] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        const response = await getAAFF(props.params.nif)
        console.log(response)
        setAAFFS(response)
    }

    return ( 
        <>
            {isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> :
            <PDFViewer style={{ width:"100%", height:"100vh"}}>
                <Document size="A4" margin="2cm">
                <Page>
                    <View>
                        <Image src={Portada} alt="Portada" style={{height:"100vh"}}/>
                    </View>
                </Page>
                <Page style={styles.body}>
                    <View style={{marginTop: 0, marginHorizontal: '0cm'}} fixed>
                        <View >
                            <Image src={Encabezado} style={{marginHorizontal:'auto', marginTop: 20, width:'80%', float:'left', display:'inline'}} />
                            {/* <Image src={Logo} style={{marginTop: 20, width:'20%', float:'left', display:'inline'}} />
                            <Image src={LogoTSP} style={{marginTop: 20, width:'10%', float:'left', display:'inline'}} /> */}
                        </View>
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                        <Text style={styles.p}>
                            A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios
                        </Text>
                        <Text style={styles.br}></Text>
                        <View style={styles.table}>
                            <View style={styles.tableRowHeader}> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>INFORME DE RIESGOS</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Administrador de fincas:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.razonSocial}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>CIF:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.nif}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Localidad:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.municipio}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Teléfono:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.telefono}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>E-Mail:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.user?.email}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Técnico:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.technician?.nombre+" "+aaff.technician?.apellidos}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Informe:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{formatoFechasEs(aaff.fechaInforme)}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Número de comunidades:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.properties?.length}</Text> 
                                </View> 
                            </View>
                        </View>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Objeto
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Alcance
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            El presente informe afecta a todas las comunidades de propietarios visitadas de {aaff.razonSocial}.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Metodología
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                            
                        
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Agradecemos la atención prestada por el personal de {aaff.razonSocial} en la gestión de las visitas a las comunidades así como en la continua colaboración con los proveedores a los que solicitamos la documentación necesaria para cumplir la coordinación de actividades empresariales.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Quedamos a su entera disposición para aclarar cualquier duda,
                        </Text>
                        <Image alt='logo' src={Logo} style={styles.imgP} />
                    </View>
                </Page>
                
                </Document>
            </PDFViewer>
            }
            <style jsx>{`
                .header-space {
                height: 100px;
                background-image: url(${`'${Encabezado}'`});
                background-position: bottom;
                background-size: cover;
                margin-bottom: 50px;
                }
                .footer-space {
                height: 30px;
                }
                .portada {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-position: bottom;
                background-size: cover;
                }
                .pagina {
                margin-top: 150px;
                padding: 0px 100px;
                font-size: 10px;
                }
                p {
                line-height: 1.8;
                text-align: justify;
                }
                br {
                display: block;
                margin: 18px 0 20px 0;
                line-height: 22px;
                content: " ";
                }
            .simpleTable {
                border-collapse: collapse;
                margin-bottom: 30px;
                }
                .simpleTable th {
                text-transform: uppercase
                }
                .simpleTable th, .simpleTable td {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1
                }
                .simpleTable img {
                height: 25px
                }
                @page {
                size: A4 portrait;
                margin: 0;
                }
            `}</style>
        </>
    );
}
 
export default withRouter(InformeRiesgos);