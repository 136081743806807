import React, { useState, useEffect } from "react";
import { withRouter } from "../../providers/withRouter";
import { getDelegations } from "../../services/delegation.service";
import { getProveedor } from "../../services";
import { Link } from "react-router-dom";
import { CircularProgress } from '@mui/material';

const Delegations = (props) => {
    
    const [delegaciones, setDelegaciones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    const fetchData = async () => {
        let response = []
        if(props.proveedor?.parent_id !== null){
            response = [props.proveedor?.parent] || []
        }else{
            response = props.proveedor?.delegations || []
        }
        console.log(props.proveedor)
        setDelegaciones(response)
        setIsLoading(false)
    }
    
    useEffect(() => {
        fetchData()
    },[props.nif])
    

    return ( 
        <div  style={{ margin: "15", position: 'relative' }}>
        {isLoading ? <CircularProgress style={{ width: '40px', height: '40px', color: '#92004D', position: 'absolute', top: '5%', left: '50%' }} /> : 
        delegaciones.length === 0 ?
            <p>No tiene delegaciones</p>
        :
            delegaciones.map(d => {
                return(
                    <div key={d.id}>
                        <button onClick={() => props.redirectToSomeRoute(d.nif)}>{d.razonSocial}</button>
                        
                    </div>
                )
            })
        }
        </div>
     );
}
 
export default withRouter(Delegations);