import React from "react";
import { Link } from "react-router-dom";

class InicioCard extends React.Component {
    render() {
        return (
            <div style={{
                backgroundColor: "#fff",
                borderRadius: 15,
                paddingLeft: 80,
                paddingRight: 80,
                paddingBottom: 50,
                textAlign: 'center',
                marginTop: 50,
                boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.15)'
            }}>
                <img src={this.props.icono} alt="Icono" style={{ marginTop: -50 }} />
                <div style={{
                    marginTop: 30,
                    marginBottom: 30,
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#303248',
                    width: 150
                }}>{this.props.texto}</div>
                <Link to={this.props.ruta}>
                    <div style={{
                        backgroundColor: '#eef3f8',
                        color: '#303248',
                        width: 'fit-content',
                        margin: 'auto',
                        padding: '8px 30px',
                        borderRadius: 5,
                        fontWeight: 'bold'
                    }}>ACCEDER</div>
                </Link>
            </div>
        )
    }
}

export default InicioCard;