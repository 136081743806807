import React, {useState} from 'react';
import ReactApexChart from 'react-apexcharts';

const Chart = ({datos}) => {
    // console.log(datos)
    // const [series, setSeries] = useState([])

    console.log(datos)

    let data = []
    datos = datos.map(property => property.workcenters?.length === 0 ? property : property.workcenters)?.flat()
    // console.log(datos)
    data.push(datos.filter(item => item.pendiente).length, datos.filter(item => item.visitada).length, datos.filter(item => item.renovada).length, datos.filter(item => item.renovadaVisitada).length)
    // console.log(data)
    const series = data
    // const series = [1, 15, 44, 55];
    const options = {
        chart: {
          width: '100%',
          type: 'pie',
        },
        labels: ["Pendiente", "Visita", "Renovada", "Renovada visitada"],
        theme: {
          monochrome: {
            enabled: true,
            color: '#92004D',
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        title: {
          text: "Servicio CAE"
        },
        dataLabels: window.innerWidth >= 992 ? {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed(1) + '%']
          }
        }:{},
        legend: {
          show: false
        }
    }
    return ( 
        <div id="chart">
            <ReactApexChart options={options} series={series} type="pie" />
        </div>
     );
}
 
export default Chart;