import axios from "axios";

const urlPath = "sale";

export const getSales = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/getSale/filter`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSalesPropsIds = async (id) => {
  console.log(id)
  try {
    const response = await axios.post(`${urlPath}/getSale`, {aaff_id:id});

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSale = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getSale/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSale = async (data) => {

  try {
    console.log(data);

    const response = await axios.post(urlPath, data);
    console.log(response.data);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const UpdateSale = async (id,data) => {

  try {
    console.log(data);

    const response = await axios.put(`${urlPath}/${id}`, data);
    console.log(response);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const DeleteSale = async (id) => {
  console.log(id);

  try {

    const response = await axios.delete(`${urlPath}/${id}`);
    console.log(response);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const noRealizada = async (id, data) => {
  console.log(data)
  try {
    const response = await axios.put(`${urlPath}/notVisit/${id}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const createProvsSinAcceso = async (data) => {

  try {

    const response = await axios.post(`${urlPath}/povsSinAcc`, data);
    return response.status;

  } catch (err) {
    console.log(err.response);
  }
};

export const updateProvsSinAcceso = async (id,data) => {

  try {
    
    const response = await axios.put(`${urlPath}/povsSinAcc/${id}`, data);
    return response;

  } catch (err) {
    console.log(err.response);
  }
};
export const deleteProvsSinAcceso = async (id) => {
  console.log(id);

  try {

    const response = await axios.delete(`${urlPath}/povsSinAcc/${id}`);
    console.log(response);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};