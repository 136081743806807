import axios from "axios";

const formHeaders = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  };

const urlPath = "docsType";


export const getDocsType = async () => {
    try {
      const response = await axios.get(urlPath)
      return response.data.data;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };