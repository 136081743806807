import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { formatoFechasEs } from "../../../functions/funciones";
import Add from "../../../icons/add.svg";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { TechListSuplier, getAAFF, getAaffListSuplier, getProveedores } from "../../../services";
import { daysDiff } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { BsSearch } from "react-icons/bs";
import { UsuarioContext } from "../../../providers/userContext";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";


const proveedoresRows = [
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    overrideFunc: (row) => (
      <ProgressBarWithColors
        value={row}
        label={
          <Typography>
            {row}
          </Typography>
        }
      />
    ),
  },
]
const ProveedoresLista = () => {
  const user = useContext(UsuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData();
    //setPorcentaje(65)
    //setRecargar(false)
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(offset, limit, filtros);
    setLimite(limit)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      const response = await TechListSuplier(payload);
      // const response = await getProveedores(payload);
      // const response1 = await getAAFF(user.nif);
      // console.log(response1)
      let datos = []
      const { meta, data } = response;
      // const filteredData = data.filter((item) => item.properties.some((prop) => prop.aaff.id === 5))
      // const filteredMeta = {
      //   current_page:1,
      //   first_page:1,
      //   has_more_pages: true,
      //   has_pages: true,
      //   is_empty: false,
      //   last_page:4,
      //   next_page:2,
      //   per_page:5,
      //   previous_page:null,
      //   total_pages:20
      // }
      // console.log(filteredData, filteredMeta)

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      proveedor_name:
        (
          <Link to={"/proveedores/" +row.nif}>
            {row.razonSocial}
          </Link>
        ),
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 

  return (
    <>
      <BreadCrumb rutas={[{path:`/proveedores`, label: 'Proveedores'}]} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <FiltrosProveedores onFilter={filtrarDatos} /> 
            <div style={{ display: "flex" }}>
              <div className="exportar">
                {/* <ExportarProveedoresLista busqueda={Resbusqueda} />  */}
              </div>
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
