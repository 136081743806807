import React, { useState, useEffect } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { subirDeclaracionResponsable, subirDocumentacionProveedor } from "../../../providers/documentos";
import { orderArray } from "../../../functions/ordenarListas";
import CircularProgress from "@mui/material/CircularProgress";
import { getAAFFs, getConsultor } from "../../../services";
import {
  eliminaDuplicados,
  formatoFechasEs,
  monthDiff,
  daysDiff,
  // subirDocumentacionProveedor,
  // subirDeclaracionResponsable,
  verDocumentacionProveedor,
  abrirArchivo,
  validarDocumentacion,
  noValidarDocumentacion,
} from "../../../functions/funciones";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF5252",
    "&:hover": {
      backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF5252",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#7bc977",
    backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    "&:hover": {
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#7bc977",
  },
}));

const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FFD700",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD700",
    backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
}));

const ProveedoresConsultor = (props) => {
  const [proveedores, setProveedores] = useState([]);
  const [consultor, setConsultor] = useState({});
  const [aaff, setAaff] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [dialog, setDialog] = useState({
    opened: false,
    title: "",
    docName: "",
    proveedor: undefined,
  });
  const [verDocumentacion, setVerDocumentacion] = useState({
    opened: false,
    nombreDoc: undefined,
    proveedor: undefined,
  });
  const [subirDocumentacion, setSubirDocumentacion] = useState({
    opened: false,
    nombreDoc: undefined,
    proveedor: undefined,
    fecha: undefined,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getConsultor(props.params.nifConsultor).then((res) => {
      setConsultor(res);
    });

    let data = {
      nif: props.params.nifAAFF,
    };
    getAAFFs(data).then((res) => {
      // console.log(res)
      res.rows.forEach((a) => {
        setAaff(a);
        let proveedores = [];
        a.properties.map((p) => {
          // console.log(p.suppliers)
          p.suppliers.map((s) => {
            proveedores.push(s);
            /* console.log(s)
                  console.log(new Date(s.ultimaReclamacion).toLocaleDateString(), new Date(s.createdAt).toLocaleDateString()) */
          });
        });
        // console.log(eliminaDuplicados(proveedores))
        setProveedores(eliminaDuplicados(proveedores));
        setIsLoading(false);
      });
    });
  }, []);

  const comprobarDocumentacion = () => {
    console.log("comprobarDocumentacion");
  };

  return (
    <>
      <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Consultor / {consultor.nombre + " " + consultor.apellidos} /{" "}
        {aaff.razonSocial} / <b>Proveedores</b>
      </div>
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            LISTADO PROVEEDORES
            <Link to={`/consultores/${props.params.nifConsultor}/aaff`}>
              <div className="gridHeadRight">
                {"<< volver al listado de Administradores de Fincas"}
              </div>
            </Link>
          </div>
          <div className="tableContainer">
            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Proveedor</th>
                    <th>NIF</th>
                    <th>Última reclamación</th>
                    <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                    <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                    <th style={{ textAlign: "center" }}>
                      Justificante pago SPA
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Planificación preventiva
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Identificación riesgos
                    </th>
                    <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                    <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                    <th style={{ textAlign: "center" }}>
                      Declaración responsable
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="11" style={{ textAlign: "center" }}>
                        <CircularProgress style={{ color: "#92004D" }} />{" "}
                      </td>
                    </tr>
                  ) : proveedores.length === 0 ? (
                    <tr>
                      <td colSpan="11" style={{ textAlign: "center" }}>
                        No tiene proveedores
                      </td>
                    </tr>
                  ) : (
                    proveedores.map((e, i) => {
                      const color =
                        daysDiff(new Date(e.ultimaReclamacion), new Date()) > 30
                          ? "red"
                          : "#303248";
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Link to={"/proveedores/" + e.nif}>
                              {e.razonSocial}
                            </Link>
                          </td>
                          <td>{e.nif}</td>
                          <td>
                            {e.ultimaReclamacion === undefined ||
                            e.ultimaReclamacion === null ? (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(e.createdAt),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "#303248",
                                }}
                              >
                                {formatoFechasEs(e.createdAt)}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(e.ultimaReclamacion),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "#303248",
                                }}
                              >
                                {formatoFechasEs(e.ultimaReclamacion)}
                              </span>
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.lopd?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar la LOPD de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "lopd",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={e.documentos?.lopd?.validado}
                                onClick={
                                  e.documentos?.lopd?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "lopd",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor(
                                          "lopd",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.seguro?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar el Seguro de Responsabilidad Civil de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "seguro",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={e.documentos?.seguro?.validado}
                                onClick={
                                  e.documentos?.seguro?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "seguro",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor(
                                          "seguro",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e.documentos?.seguro?.fechaCaducidad !==
                              undefined ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e.documentos?.seguro?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {formatoFechasEs(
                                    e.documentos?.seguro?.fechaCaducidad
                                  )}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.justificante?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar el Justificante de Pago SPA de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "justificante",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={e.documentos?.justificante?.validado}
                                onClick={
                                  e.documentos?.justificante?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "justificante",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor({
                                          nombreDoc: "justificante",
                                          proveedor: e,
                                        })
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e.documentos?.justificante?.fechaCaducidad !==
                              undefined ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e.documentos?.justificante?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {formatoFechasEs(
                                    e.documentos?.justificante?.fechaCaducidad
                                  )}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.planificacionPreventiva
                              ?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar la Planificación Preventiva de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "planificacionPreventiva",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={
                                  e.documentos?.planificacionPreventiva
                                    ?.validado
                                }
                                onClick={
                                  e.documentos?.planificacionPreventiva
                                    ?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "planificacionPreventiva",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor(
                                          "planificacionPreventiva",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.evaluacionRiesgos?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar la Evaluación de Riesgos de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "evaluacionRiesgos",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={
                                  e.documentos?.evaluacionRiesgos?.validado
                                }
                                onClick={
                                  e.documentos?.evaluacionRiesgos?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "evaluacionRiesgos",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor(
                                          "evaluacionRiesgos",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.certificadoSS?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar el Certificado de la Seguridad Social de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "certificadoSS",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={e.documentos?.certificadoSS?.validado}
                                onClick={
                                  e.documentos?.certificadoSS?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "certificadoSS",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacionProveedor(
                                          "certificadoSS",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e.documentos?.certificadoSS?.fechaCaducidad !==
                              undefined ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e.documentos?.certificadoSS?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {formatoFechasEs(
                                    e.documentos?.certificadoSS?.fechaCaducidad
                                  )}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.vigilancia?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar la Vigilancia de Salud de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "vigilancia",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={e.documentos?.vigilancia?.validado}
                                onClick={
                                  e.documentos?.vigilancia?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "vigilancia",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDocumentacion(
                                          "vigilancia",
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e.documentos?.vigilancia?.fechaCaducidad !==
                              undefined ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e.documentos?.vigilancia?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {formatoFechasEs(
                                    e.documentos?.vigilancia?.fechaCaducidad
                                  )}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e.documentos?.declaracionResponsable?.[
                              props.params.nifAAFF
                            ]?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() =>
                                  comprobarDocumentacion({
                                    title:
                                      "¿Desea verificar la Declaración Responsable de " +
                                      e.razonSocial +
                                      "?",
                                    docName: "declaracionResponsable",
                                    proveedor: e,
                                  })
                                }
                              />
                            ) : (
                              <CustomSwitch
                                checked={
                                  e.documentos?.declaracionResponsable?.[
                                    props.params.nifAAFF
                                  ]?.validado
                                }
                                onClick={
                                  e.documentos?.declaracionResponsable?.[
                                    props.params.nifAAFF
                                  ]?.validado
                                    ? () =>
                                        verDocumentacion({
                                          nombreDoc: "declaracionResponsable",
                                          proveedor: e,
                                        })
                                    : () =>
                                        subirDeclaracionResponsable(
                                          props.params.nifAAFF,
                                          e,
                                          setRecargar
                                        )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresConsultor);
