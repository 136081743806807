import React, { useState, useEffect, useContext } from "react";
import {
  LinearProgress,
  styled,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import ReactPaginate from "react-paginate";
import { getAAFF, getAAFFs, getFincasAAFF } from "../../../services";
import { DataTable, LaeButton } from "../../../components";
import { Iconify } from "../../../utilities";
import { useComunidades } from "../../../hooks";
import ObservacionesTecnico from "../../../components/aaff/observacionesTecnico";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import NoVisitado from "../../../components/noVisitadoComunidad";
import ListadoComunidades from "./ListadoComunidades";
import { cambiarFormatoNombre } from "../../../functions/funciones";

const comunidadesRows = [
  /*  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR DE FINCAS",
  }, */
  {
    id: "community",
    numeric: false,
    disablePadding: false,
    label: "COMUNIDAD",
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "FECHA ALTA",
  },
  {
    id: "direction",
    numeric: false,
    disablePadding: false,
    label: "DIRECCION",
  },
  {
    id: "cp",
    numeric: false,
    disablePadding: false,
    label: "C.P",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "empleados",
    numeric: false,
    disablePadding: false,
    label: "EMPLEADOS",
  },
  // {
  //   id: "servicios",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "SERVICIOS",
  // },
  // {
  //   id: "location",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
  // },
  // {
  //   id: "location2",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "PLAN DE EMERGENCIA",
  // },
  // {
  //   id: "location3",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "PLAN DE PREVENCIÓN",
  // },
  // {
  //   id: "hojasVisita",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "HOJA DE VISITA",
  //   overrideFunc: (row) => (
  //     <Box className="lae-flex lae-flex-row">
  //       <Iconify
  //         icon="solar:document-line-duotone"
  //         sx={{ height: 24, width: 24 }}
  //       />
  //       <Iconify
  //         icon="solar:document-add-bold-duotone"
  //         sx={{ height: 24, width: 24 }}
  //       />
  //     </Box>
  //   ),
  // },
  // {
  //   id: "visitada",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "NO VISITADA",
  //   overrideFunc: (row) => (
  //     // <>{!row && <Iconify icon="mdi:close" sx={{ height: 24, width: 24 }} />}</>
  //     <Typography>
  //       {
  //       !row?.noRealizada && row?.fechaRealizada !== null ? (
  //         row?.fechaRealizada
  //       ) : (
  //         )} 
  //         <NoVisitado finca={row} nif={row.nif} />
  //     </Typography>
  //   ),
  // },
];

const TabFilters = [
  /* { value: "all", label: "TODOS" }, */
  {
    value: "unvisited",
    label: "NO VISITADAS",
  },
  {
    value: "visited",
    label: "VISITADAS",
  },
  {
    value: "refreshed_unvisited",
    label: "RENOVADAS NO VISITADAS",
  },
  {
    value: "refreshed_visited",
    label: "RENOVADAS VISITADAS",
  },
  /*   {
    value: '',
    label: '',
  } */
];

const listaFiltros = [
  { value: "all", label: "TODOS" },
  { value: "comunidades", label: "Comunidades" },
  { value: "aaff", label: "Administrador de Fincas" },
];

const DetallesAAFF = ({ params }) => {
  const user = useContext(UsuarioContext);

  const { loading, data, fetchData, formatData } = useComunidades();

  const [info, setInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [additionalComments, setAdditionalComments] = useState("");

  const [recargar, setRecargar] = useState(0);

  const handleOpenNewTab = ({ nif }) => {
    if (!nif) return;
    window.open(window.location.origin + `/comunidades/${nif}`, "_blank");
  };

  const fetchAAFFInfo = async () => {
    try {
      const response = await getAAFF(params?.nif);
      fetchData({
        page: 0,
        size: 5,
        razonSocial: search,
        aaff_id: response?.id,
      });

      const formattedData = {
        ...response,
        properties: [...formatData(response.properties)],
      };
      setInfo(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchData = async ({ page = 0, size = 5 }) => {
    if (!info) return;
    try {
      await fetchData({ page, size, razonSocial: search, aaff_id: info?.id });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAAFFInfo();
  }, []);

  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:`Administrador`}, {path:'/aaff', label:`${info && info.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <Box className="listPage">
        <div className="gridCard">
          <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>
            {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
          </div>
        </div>
        <Box sx={{ mb: 10 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography>Datos de Empresa:</Typography>
            <Box className="lae-flex lae-flex-row">
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Nombre AAFF: {info?.razonSocial}</Typography>
                <Typography>Teléfono: {info?.telefono}</Typography>
                <Typography>Email: {info?.user?.email}</Typography>
                <Typography>Municipio: {cambiarFormatoNombre(info?.municipio)}</Typography>
                <Typography>
                  Tecnico:
                  {` ${info?.technician.nombre} ${info?.technician.apellidos}`}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>NIF: {info?.nif}</Typography>
                <Typography>
                  Persona de contacto: {info?.nombreContacto}
                </Typography>
                <Typography>Provincia: {info?.provincia}</Typography>
                <Typography>
                  Consultor:
                  {` ${info?.consultant?.nombre} ${info?.consultant?.apellidos}`}
                </Typography>
                <Typography>
                  Comercial:
                  {` ${info?.commercial?.nombre} ${info?.commercial?.apellidos}`}-{" "}
                  {info?.commercial?.email}
                </Typography>
              </Box>
              {/* <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <TextField
                  id="outlined-multiline-static"
                  label="Observaciones: "
                  multiline
                  rows={4}
                  placeholder="Escribe algo..."
                  defaultValue=""
                  sx={{ width: "90%", alignSelf: "center", my: 1 }}
                  onChange={({ target }) => setAdditionalComments(target.value)}
                />
                <LaeButton
                  onClick={() => console.log("clicked")}
                  sx={{ width: "90%", alignSelf: "center" }}
                >
                  <Typography>Guardar</Typography>
                </LaeButton>
              </Box> */}
            </Box>
            <hr />
            <div className="datosGrid">
              <ObservacionesTecnico />
            </div>
          </Box>

          <div className="gridCard">
            <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
              <Typography fontWeight="bold">LISTADO DE COMUNIDADES</Typography>
              {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
            </div>
          </div>
          <div className="tableContainer">
            <ListadoComunidades />
          </div>
          {/* <DataTable
            data={info?.properties ?? []}
            isLoading={loading}
            headers={comunidadesRows}
            tabsFilters={TabFilters}
            onClickRow={handleOpenNewTab}
            fetcher={handleFetchData}
            currentPage={0}
            totalItems={info?.properties?.length ?? 0}
            totalPages={1}
          >
            <Box
              m={2}
              display={"flex"}
              flexDirection={"row"}
              width={"100%"}
              gap={2}
            >
               <Select
                value={filter}
                aria-label="controlled"
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: 150 }}
              >
                {listaFiltros.map((filtro) => (
                  <MenuItem value={filtro.value} key={filtro.value}>
                    {filtro.label}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id="outlined-basic"
                label="Razón Social"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
              <LaeButton onClick={handleFetchData} sx={{ width: 100 }}>
                <Typography>Buscar</Typography>
              </LaeButton>
            </Box>
          </DataTable> */}
        </Box>
      </Box>
    </>
  );
};

export default withRouter(DetallesAAFF);
