import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_proveedores.jpg";
import Editar from "../../../icons/editar.svg";
import ModalEditar from "./editarDatosAAFF";
import { getAaffListSuplier, SuppListPropertiAaff, getAAFF } from "../../../services";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { CircularProgress } from "@mui/material";
import { cambiarFormatoNombre } from "../../../functions/funciones";
const AAFF = (props) => {
  const user = useContext(UsuarioContext);
  const [proveedor, setProveedor] = useState();
  const [declResp, setDeclResp] = useState({});
  const [aaff, setAaff] = useState({});
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [modalNotificacionOpened, setModalNotificacionOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // fetchData()
    getAAFF(user.nif).then((res) => {
      setAaff(res);
      console.log(res)
      setIsLoading(false)
    });
  }, [recargar]);

  // const fetchData = async () => {
  //   const t = await SuppListPropertiAaff()
  //   console.log(t)
  //   const r = await getAaffListSuplier()
  //   console.log(r)
  // }

  return (
    <>
      <ModalEditar
        aaff={aaff}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />

      <BreadCrumb rutas={[{path:'/aaff', label:"Administrador"}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">INFORMACIÓN GENERAL</div>
            <div className="gridSubhead editarSubhead">
              <div>DATOS DE ADMINISTRADOR DE FINCAS</div>
              <div
                className="editarBtn"
                onClick={() => setModalEditarOpened(true)}
              >
                <span style={{ marginRight: 10 }}>Editar</span>{" "}
                <img src={Editar} alt="Editar" />
              </div>
            </div>
          </div>
      </div>
        <div className="pageContent tableContainer" style={{minHeight: '110px'}}>
          
          {isLoading ? <CircularProgress style={{ color:'#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
            <div className="datosGrid">
            <p>
              <b>Nombre:</b> {aaff.razonSocial}
            </p>
            <p>
              <b>NIF:</b> {aaff.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(aaff.municipio)}
            </p>
            <p>
              <b>Provincia:</b> {aaff.provincia}
            </p>
            <p>
              <b>Email:</b> {aaff.user?.email}
            </p>
            <p>
              <b>Persona de contacto:</b> {aaff.nombreContacto}
            </p>
            <p>
              <b>Teléfono:</b> {aaff.telefono}
            </p>
            </div>
          }
        </div>
    </>
  );
};

export default withRouter(AAFF);
