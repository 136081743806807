import { Grid } from "@mui/material";
import React/* , { useEffect, useState } */ from "react";
import InicioCard from "../../../components/InicioCard";
import AAFFIcon from "../../../images/aaff.png"

function Inicio() {
  return (<>
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      marginTop: 90
    }}>Bienvenido a tu gestor de fincas</div>
    <div style={{
      textAlign: 'center',
      fontSize: 22,
      color: "#303248",
      marginTop: 10,
      marginBottom: 70
    }}>¿QUÉ QUIERES HACER?</div>
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5}>
      <Grid item><InicioCard icono={AAFFIcon} texto="Gestionar Administradores de Fincas" ruta="/aaff" /></Grid>
    </Grid>
  </>);
}

export default Inicio;
