import React, { useState, useEffect } from "react";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { getFinca, getAAFF, nuevoCentroTrabajo } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

const NuevoCentroTrabajo = (props) => {
  const [nombre, setNombre] = useState("");
  const [finca, setFinca] = useState([]);
  const [aaff, setAaff] = useState([]);
  useEffect(() => {
    console.log(props.params.nifFinca);
    getFinca(props.params.nifFinca).then((res) => {
      console.log(res);
      setFinca(res);
      getAAFF(res.aaff.nif).then(aaff => {
        setAaff(aaff)
      })
    });

  }, []);

  const crearCentroTrabajo = (e) => {
    e.preventDefault();
    let data = {
      razonSocial: nombre,
    };
    nuevoCentroTrabajo(props.params.nifFinca, data)
      .then((res) => {
        console.log(res);
        if (res.message === "WORKCENTER_CREATED") {
          toast.success("centro de trabajo creado");
          // window.location.replace("/aaff/" + aaff.nif + "/comunidades");
        } else {
          toast.error("No se ha podido crear el centro de trabajo");
        }
      })
      .catch((err) => {
        toast.error("Error al crear centro de trabajo");
        console.log(err);
      });
  };

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Nuevo centro de trabajo
      </div> */}
      <BreadCrumb rutas={[{path:'/aaff', label:`Administrador`}, {path:`/aaff/${aaff.nif}/comunidades`, label:`${aaff.razonSocial}`}, {path:`/aaff/${aaff.nif}/comunidades`, label:`${finca.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="titleContent">Crear centro de trabajo</div>
        <form className="nuevoAAFFForm" onSubmit={crearCentroTrabajo}>
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              ></input>
            </Grid>
          </Grid>
          <input type="submit" value="Guardar y publicar"></input>
        </form>
      </div>
    </>
  );
};

export default withRouter(NuevoCentroTrabajo);
