import React, {useState, useEffect, useContext} from 'react'
import { withRouter } from "../../providers/withRouter";
import { Link } from "react-router-dom";
import { getAAFF } from '../../services';
import { createComment, deleteComment, getComments } from '../../services/comment.service';
import { IoSave } from "react-icons/io5";
import Delete from "../../icons/delete.svg";
import Editar from "../../icons/editar.svg";
import { UsuarioContext } from '../../providers/userContext';
import { editarComentario, eliminarComentario, formatoFechasEs } from '../../functions/funciones';
import ModalEditarComentarios from "./../../components/aaff/AllComments";
import { CircularProgress } from '@mui/material';

const Comentarios = (props) => {
    
    const user = useContext(UsuarioContext)
    const [aaff, setAAFF] = useState({});
    const [comentarios, setComentarios] = useState([]);
    const [comentario, setComentario] = useState('');
    const [recargar, setRecargar] = useState(0);
    const [modalEditarOpened, setModalEditarOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    

    useEffect(() => {
        fetchData()
        setRecargar(false);
    }, [recargar]);

    const fetchData = async () => {
        const responseAaffs = await getAAFF(props.params.nif);
        setAAFF(responseAaffs);
        let comment = []
        const responseComments = await getComments()
        
        responseComments?.data.forEach( com => {
          if(com.aaff_id === responseAaffs.id){
            if(com.user.consultant.length > 0){
              com.usuario = com.user.consultant[0]
            }else if(com.user.technician.length > 0){
              com.usuario = com.user.technician[0]
            }else{
              com.usuario = com.user.admin[0]
            }
            if(com.tipo==='comment'){
                comment.push(com)
            }
          }
        })
        comment.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setComentarios(comment)
        setIsLoading(false)
      }
    
    function guardarComentarios() {
        try {
        let infoComentario = {
            prop_id:null,
            supp_id:null,
            aaff_id:aaff.id,
            texto: comentario,
            tipo: "comment",
            status:1,
        }
        createComment(infoComentario).then(res => {
          console.log(res)
            handleSubirDocsCallback(res)
            setComentario('')
        })
        } catch (error) {
        console.log(error)
        }
        
    }

    function cambiarComentarios(evt) {
        const value = evt.target.value;
        setComentario(value);
    }

    
    const handleSubirDocsCallback = () => {
        setRecargar(!recargar);
    };

    const editarComment = async (id, comm) => {
        editarComentario(id, user.id, comm).then( (resultado) => {
        handleSubirDocsCallback(resultado)
        })
    }
    const eliminarComment = async (id) => {
      console.log(id)
        eliminarComentario(id).then( (resultado) => {
        handleSubirDocsCallback(resultado)
        })
    }
    return ( 
        <>
            <ModalEditarComentarios
              comentarios={comentarios}
              opened={modalEditarOpened}
              eliminarComment={eliminarComment}
              editarComment={editarComment}
              handleClose={() => {
                setModalEditarOpened(false);
                setRecargar(!recargar);
              }}
            />
            <div style={{ position: 'relative' }}>
              <p style={{paddingBottom: 10}}>
                <b>Comentarios</b>
              </p>
              {isLoading ? <CircularProgress style={{ height: 20, width: 20, color:'#92004D', position: 'absolute', top: '15%', left: '5%'}} /> : 
              comentarios.length === 0
                ? "No hay comentarios"
                : 
                  <>
                    {comentarios.slice(0, 4).map(comm => {
                      return (
                        <div>
                          <img
                          src={Delete}
                          alt="ELIMINAR"
                          className="tableIcon"
                          onClick={() => eliminarComment(comm.id)}
                          style={{width: 14}}
                        ></img> 
                        <img 
                          src={Editar}
                          alt="Editar"
                          className="tableIcon"
                          onClick={() => editarComment(comm.id, comm)}
                          style={{width: 14}}
                        ></img> 
                          {formatoFechasEs(comm.updatedAt)} - {comm.usuario?.nombre+" "+comm.usuario?.apellidos} - {comm.texto}</div>
                      )
                    })}
                    {comentarios.length > 4 && 
                      <div >
                        <button onClick={() => setModalEditarOpened(true)} style={{margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px'}}>Mostrar más</button>
                      </div>
                    }
                  </>
              }{" "}
                
            </div>
            <div>
              <form
                noValidate
                autoComplete="off"
                style={{ display: "flex", alignItems: "end" }}
              >
                <textarea
                  style={{
                    marginRight: "10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}id="comentarios" name="comentarios" value={comentario} label="Comentarios" onChange={cambiarComentarios} multiline rows={6} columns={100}
                />
                <div>
                  <button type="button" className="bigBtn" onClick={() => guardarComentarios(comentarios)}>
                    <IoSave style={{ fontSize:23 }} />
                  </button>
                </div>
              </form>
            </div>
        </>
     );
}
 
export default withRouter(Comentarios);