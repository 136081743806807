import React, { useState, useEffect, useContext } from "react";

import { getTecnicos, deleteTecnico } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ExportarListaTecnicos from "./ExportarListaTecnicos";
import Excel from "../../../icons/excel.svg";
import { IoMdPersonAdd } from "react-icons/io";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { Box } from "@mui/material";
import { DataTable } from "../../../components";

const Tecnicos = () => {
  const user = useContext(UsuarioContext)
  const [tecnicos, setTecnicos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(true);
  const [show, setShow] = useState(false);
  const [limite, setLimite] = useState(50);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData()
  }, [recargar]);

  const fetchData = async ({offset, limit, filtros}) => {
    
    setLimite(limit)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };
    try {
      setLoading(true);
      const response = await getTecnicos(payload);
      const { meta, data } = response;
      console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const formatData = (data) => {
    return data.map((row, i) => ({
      id: i+1,
      tecnico: row.nombre+" "+row.apellidos,
      nif: row.nif,
      telefono: row.telefono,
      email: row.user.email,
      provincia: row.provincia,
      equipo: row.technicianteams?.nombre
    }));
  };

  const comercialesRows = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "tecnico",
      numeric: false,
      disablePadding: false,
      label: "Técnico",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "telefono",
      numeric: false,
      disablePadding: false,
      label: "TELÉFONO",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "EMAIL",
    },
    {
      id: "equipo",
      numeric: false,
      disablePadding: false,
      label: "EQUIPO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "aaff",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADORES FINCAS",
      overrideFunc: (data, row) => (
        <Link to={"/tecnicos/" + row.nif + "/aaff"}>
          <img
            src={Lupa}
            alt="Administradores de Fincas"
            className="tableIcon"
          ></img>
        </Link>
      )
    },
    user.rol === 'ADMIN' ?
    {
      id: "eliminar",
      numeric: false,
      disablePadding: false,
      label: "ELIMINAR",
      overrideFunc: (data, row) => (
        <img
          src={Delete}
          alt="ELIMINAR"
          className="tableIcon"
          onClick={() => eliminarTecnico(row)}
        ></img>
      )
    }
    : '',
  ]

  const eliminarTecnico = (com) => {
    let nombre = com.nombre + " " + com.apellidos;
    Swal.fire({
      title: "¿Quieres borrar este tecnico?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borrar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          
          deleteTecnico(com.nif)
            .then((res) => {
              
              if (res.message === "TECHNICIAN_DELETED") {
                toast.success("técnico eliminado");
                setRecargar(!recargar);
              } else {
                toast.error("No se ha podido elimiar el técnico");
              }
            })
            .catch((e) => {
              toast.error("No se ha podido elimiar el técnico");
              console.log(e);
            });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error");
        console.log(e);
      });
  };

  return (
    <>
      <BreadCrumb rutas={[{path:`/tecnicos`, label:`Técnicos`}]} />
      <div className="listPage">
        <div className="gridCard">
            <div className="gridHead">
                TÉCNICOS
            </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div style={{ display: 'flex'}}>
            {show ? (
              <ExportarListaTecnicos tecnicos={tecnicos} />
            ) : (
              <button className="bigBtn" onClick={() => setShow(true)}>
                <img src={Excel} alt="Generar listado" width="30px" /> Generar
                listado
              </button>
            )}
            <Link to="/equipos">
              <div className="bigBtn">
                <>Ver equipos</>          
              </div>
            </Link>
          </div>
          {(user.rol === "ADMIN" || user.rol === "CONSUL") && 
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
              <Link to="/tecnicos/nuevo">
                <div className="contentButton mx-2">
                  <img src={Add} alt="Nuevo Técnico" />              
                </div>
              </Link>
            </div>
          }
        </div>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comercialesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                // filtros={busqueda}
                mensaje={"No hay técnicos"}
              />
            </Box>
          </Box>
        </div>
        {/* <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>Técnico</th>
                <th style={{ textAlign: "center" }}>NIF</th>
                <th style={{ textAlign: "center" }}>Teléfono</th>
                <th style={{ textAlign: "center" }}>Email</th>
                <th style={{ textAlign: "center" }}>Zona</th>
                <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                <th style={{ textAlign: "center" }}>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#92004D" }} />{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {tecnicos.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.nombre + " " + e.apellidos}
                    </td>
                    <td style={{ textAlign: "center" }}>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>{e.telefono}</td>
                    <td style={{ textAlign: "center" }}>{e.user.email}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.zona !== null ? e.zona : "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Link to={"/tecnicos/" + e.nif + "/aaff"}>
                        <img
                          src={Lupa}
                          alt="Administradores de Fincas"
                          className="tableIcon"
                        ></img>
                      </Link>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={Delete}
                        alt="ELIMINAR"
                        className="tableIcon"
                        onClick={() => eliminarTecnico(e)}
                      ></img>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default Tecnicos;
