import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { DataTable } from "../../../components";
import { CircularProgress, Tabs, Tab, Box } from "@mui/material";
import { getAAFFs } from "../../../services";
import WelcomeAlert from "../../../components/alerts/welcomeAlert";
import TabPanel from "../../../components/tabs/Tabs";
import ReclamationAlert from "../../../components/alerts/reclamationAlert";

const AlertasAAFF = () => {

  const [aaff, setAAFF] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState(0);

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  },[])

  const fetchData = async ({ offset, limit, filtros}) => {
    const payload = {
      filtros,
      params: { page: offset, limit},
    };
    try {
      const response = await getAAFFs(payload);
      const { meta, data } = response;
      console.log(data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return ( isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
    <BreadCrumb
      rutas={[{ path: "/alertas-aaff", label: `Alertas administradores de fincas` }]}
      style={{ margin: "0 10px" }}
    />
    <div className="listPage">
      <div className="gridCard">
        <div className="gridHead">
          ALERTAS ADMINISTRADORES DE FINCAS
        </div>
        <Box sx={{ padding: '10px 40px', border: 'none' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs example">
            { <Tab style={{ color:"#92004D" }} label="BIENVENIDA" /> }
            { <Tab style={{ color:"#92004D" }} label="RECLAMACIÓN" /> }
          </Tabs>
        </Box>
        <TabPanel value={value}  index={0}>
          <WelcomeAlert />
        </TabPanel>
        <TabPanel value={value}  index={1}>
          <ReclamationAlert />
        </TabPanel>
      </div>
    </div>
    <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
    `}</style>
    </>
   );
}
 
export default AlertasAAFF;