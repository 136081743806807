import React, {useState} from "react";
import "./Login.css"
import Logo from "../images/LAE.png"
import BackgroundImage from "../images/loginFondo.jpg"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signIn } from "../services";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Login = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginDatos, setLoginDatos] = useState({
    email: '',
    password: ''
  })
  function handleChange(evt) {
    const value = evt.target.value;
    setLoginDatos({
      ...loginDatos,
      [evt.target.name]: value
    });
  }


  function handleSubmit(){
    if(loginDatos.email==='' || loginDatos.password===''){
      toast.error("Los campos estan vacios")
    }else{
      setIsLoading(true)
      signIn(loginDatos).then( response => {
        setIsLoading(false)
        if(response.status === 200){
          navigate(location.state?.from?.pathname || "/")
        }
        if(response.status !== 200 || response === undefined){
          toast.error("email o contraseña incorrecto")
        }
      }).catch(e => {
        setIsLoading(false)
        toast.error("email o contraseña incorrecto")
      })
    }
  }

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
      <form className="loginForm">
        <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
        <input type="email" name="email" required onChange={handleChange} onKeyDown={(e) => e.key === 'Enter' && !isLoading ? handleSubmit() : ''} placeholder="Correo electrónico" />
        <input type="password" name="password" required minLength="6" onChange={handleChange} onKeyDown={(e) => e.key === 'Enter' && !isLoading ? handleSubmit() : ''} placeholder="Contraseña" />
        <button type="button" disabled={isLoading} onClick={(e) => handleSubmit(e) } onKeyDown={(e) => e.key === 'Enter' && !isLoading ? handleSubmit() : ''}>{isLoading ? <CircularProgress thickness={5} size={18} style={{color:'#ffffff', position: 'relative', top: 3}} /> : 'Iniciar Sesión'}</button>
        <Link to="/cambiarPassword" target="_blank">
          <div style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      color: "rgb(48, 50, 72)",
                      margin: "10px auto 0",
                      padding: "8px 30px 0",
                      fontWeight: "normal",
                      fontSize: "10px"
                }}>¿Has olvidado tu contraseña?
          </div>
        </Link>
      </form>
    </div>
  )
}


export default Login;
