import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { DataTable, ProgressBarWithColors } from "../../../components";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { getAAFF, getFincasAAFF } from "../../../services";
import { formatoFechasEs } from '../../../functions/funciones';

const ComunidadesRows = [
    {
      id: "comunidad_name",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={`/aaff/${row.aaff_nif}/comunidades/${row.nif}`}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "fechaAlta",
      numeric: false,
      disablePadding: false,
      label: "FECHA ALTA",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
  ]

const GestionComunidades = () => {

    const [busqueda, setBusqueda] = useState({});
    const [loading, setLoading] = useState(true);
    const [recargar, setRecargar] = useState(false);
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });

    useEffect(() => {
        fetchData();
        //setPorcentaje(65)
        //setRecargar(false)
      }, [recargar]);
    
      const fetchData = async ({ offset, limit, filtros }) => {
        console.log(offset, limit, filtros);
        setLimite(limit)
        const payload = {
          filtros,
          params: { page: offset, limit },
        };
    
        try {
          setLoading(true);
          const response = await getFincasAAFF(payload);
          // const response1 = await getAAFF(user.nif);
          // console.log(response1)
          let datos = []
          const { meta, data } = response;
          console.log(response)
          // const filteredData = data.filter((item) => item.properties.some((prop) => prop.aaff.id === 5))
          // const filteredMeta = {
          //   current_page:1,
          //   first_page:1,
          //   has_more_pages: true,
          //   has_pages: true,
          //   is_empty: false,
          //   last_page:4,
          //   next_page:2,
          //   per_page:5,
          //   previous_page:null,
          //   total_pages:20
          // }
          // console.log(filteredData, filteredMeta)
    
          setData({
            meta,
            rows: formatData(data),
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
    
      const formatData = (data) => {
        return data.map((row) => ({
          aaff_nif: row.aaff.nif,
          comunidad_name: row.razonSocial,
          nif: row.nif,
          fechaAlta: formatoFechasEs(row.createdAt),
          codigoPostal: row.codigoPostal,
          municipio: row.municipio,
          provincia: row.provincia,
        }));
      };

    const filtrarDatos = (filtros) => {
        setBusqueda(filtros)
        console.log(filtros)
        fetchData({ offset: 1, limit: limite, filtros: filtros });
      } 
    return ( 
        <>
            <BreadCrumb
                rutas={[{ path: "/comunidades", label: "Gestión comunidades" }]}
                style={{ margin: "0 10px" }}
            />
            <div className="listPage">
                <div className="gridCard">
                    <div className="gridHead">
                       GESTIÓN COMUNIDADES
                    </div>
                </div>
            </div>
            <div className="pageContent tableContainer">
                <FiltrosComunidades onFilter={filtrarDatos} />
                <div className="tableWrapper">
                  <Box className="pageContent">
                    <Box sx={{ mb: 10 }}>
                      <DataTable
                        data={data.rows}
                        isLoading={loading}
                        headers={ComunidadesRows}
                        fetcher={fetchData}
                        currentPage={data.current_page}
                        meta={data.meta}
                        totalItems={data.total}
                        totalPages={data.totalPages}
                        nextPage={data.next_page}
                        filtros={busqueda}
                        mensaje={"No hay comunidades"}
                      />
                    </Box>
                  </Box>
                </div>
            </div>
        </>
     );
}
 
export default withRouter(GestionComunidades);