import React, { useState, useEffect } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { DataTable, ProgressBarWithColors } from "../../../components";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { getAlertas, getAllConsultores, getProveedores } from "../../../services";
import LogoLAE from "../../../images/LogoLAE.png";
import { ordenarUltimaReclamacion } from "../../../functions/ordenarListas";
import {
  cambiarUltimaReclamacion,
  daysDiff,
  eliminaDuplicados,
  formatoFechasEs,
} from "../../../functions/funciones";
import { Tabs, Tab, Box } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import moment from "moment";
import NoDocumentsAlert from "../../../components/alerts/noDocumentsAlert";
import WithDocumentsAlert from "../../../components/alerts/withDocumentsAlert";

const proveedoresRows = [
  {
    id: "proveedor_id",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => <Link to={"/proveedores/" + row.nif}>{data}</Link>
  },
  {
    id: "ultimaReclamacion",
    numeric: false,
    disablePadding: false,
    label: "ÚLTIMA RECLAMACIÓN",
    overrideFunc: (data, row) => <span style={{color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span>
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "planificacionPreventiva",
    numeric: false,
    disablePadding: false,
    label: "PLANIFICACIÓN PREVENTIVA",
    overrideFunc: (data) =>  <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,
      
  },
  {
    id: "evaluacionRiesgos",
    numeric: false,
    disablePadding: false,
    label: "EVALUACIÓN RIESGOS",
    overrideFunc: (data) =>  <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,
  },
  {
    id: "vigilancia",
    numeric: false,
    disablePadding: false,
    label: "Vigilancia salud",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "certificado",
    numeric: false,
    disablePadding: false,
    label: "Certificado Seg. Social",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "justificante",
    numeric: false,
    disablePadding: false,
    label: "Justificante pago SPA",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "seguro",
    numeric: false,
    disablePadding: false,
    label: "Seguro Resp. Civil",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
    // overrideFunc: (row) => {
    //     console.log(row.find( d => d.tipoDoc === 2 )?.fechaCaducidad)

    // },
  },
];

const Alertas = () => {
  const [Consultores, setConsultores] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [value, setValue] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData();
    getAllConsultores().then((res) => {
      setConsultores(res);
    });
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(offset, limit, filtros);
    setLimite(limit);
    const payload = {
      // filtros:{ultimaReclamacion:new Date().setDate(new Date().getDate() - 60)},
      filtros,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      
      const response1 = await getAlertas(payload);
      const response = await getProveedores(payload);
      console.log(response1)
      console.log(response)
      const { meta, data} = response1;
      console.log(data);
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    // data.map((row, i) => (
    //   console.log(row.docssupps?.find((d) => d.tipoDoc === 4)?.validado)
    // ))
    // return data.map((row, i) => ({
    //   proveedor_id: row.id,
    //   proveedor_name: row.razonSocial,
    //   ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
    //   nif: row.nif,
    //   planificacionPreventiva:!row.docssupps?.find((d) => d.tipoDoc === 4)?.validado === true,
    //   evaluacionRiesgos:
    //     !row.docssupps?.find((d) => d.tipoDoc === 5)?.validado === true,
    //   vigilancia: row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad || '',
    //   certificado: row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad || '',
    //   justificante: row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad || '',
    //   seguro: row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad || '',
    // }));
  };
  
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/alertas", label: `Alertas` }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ALERTAS PROVEEDORES
            {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
          </div>
          <Box sx={{ padding: '10px 40px', border: 'none' }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs example">
              { <Tab style={{ color:"#92004D" }} label="SIN DOCUMENTOS" /> }
              { <Tab style={{ color:"#92004D" }} label="CON DOCUMENTOS" /> }
            </Tabs>
          </Box>
          <TabPanel value={value}  index={0}>
            <NoDocumentsAlert />
          </TabPanel>
          <TabPanel value={value}  index={1}>
            <WithDocumentsAlert />
          </TabPanel>
        </div>
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
    `}</style>
    </>
  );
};

export default withRouter(Alertas);
