import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getAAFFs } from '../../services';
import { UsuarioContext } from '../../providers/userContext';
import {
    LinearProgress,
    styled,
    Box,
    linearProgressClasses,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel
  } from "@mui/material";

const FiltrosGestion = ({onFilter}) => {
    const [busqueda, setBusqueda] = useState({})
    const [aaff, setAAFF] = useState([])
    const [aaffFiltrado, setAAFFfiltrado] = useState([])
    const user = useContext(UsuarioContext)

    useEffect(() => {
        getAAFFs({filtros:{tech_id:user.id}, params:{}}).then( res => {
            setAAFF(res.data)
            setAAFFfiltrado(res.data)
        });
        
    },[]);

    function handleChange(evt) {
      let value 
      if(evt.target.name === 'estado'){
        switch (evt.target.value) {
          case 1:
            setBusqueda({
              ...busqueda,
              'pendiente': true,
              'visitada': null,
              'renovada': null,
              'renovadaVisitada':null
            });
            break;
          case 2:
            setBusqueda({
              ...busqueda,
              'pendiente': null,
              'visitada': true,
              'renovada': null,
              'renovadaVisitada':null
            });
            break;
          case 3:
            setBusqueda({
              ...busqueda,
              'pendiente': null,
              'visitada': null,
              'renovada': true,
              'renovadaVisitada':null
            });
            break;
          case 4:
            setBusqueda({
              ...busqueda,
              'pendiente': null,
              'visitada': null,
              'renovada': null,
              'renovadaVisitada':true
            });
            break;
          default:
            setBusqueda({
              ...busqueda,
              'visitada': null,
              'pendiente': null,
              'renovada': null,
              'renovadaVisitada':null
            });
            break;
        }
      }else{
        value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
      }

    const getAaff = (evt) => {
      const arrayDeObjetos = aaff;
      const textoBuscado = evt.target.value;
      let itemsConNombreSimilar = [];
      if(textoBuscado !== ""){
        itemsConNombreSimilar = arrayDeObjetos.filter(objeto =>
          objeto.razonSocial.toLowerCase().includes(textoBuscado.toLowerCase())
        );
        setAAFFfiltrado(itemsConNombreSimilar)
        console.log(itemsConNombreSimilar)
      }else{
        setAAFFfiltrado(aaff)
      }
      console.log(aaff)
    }
    
    const getBusqueda = () => {
      for (let key in busqueda) {
        if (
          busqueda[key] === "" ||
          busqueda[key] === null ||
          busqueda[key] === undefined
        ) {
          delete busqueda[key];
        }
      }
      onFilter(busqueda)
    };

    return ( 
      <>  
        <div className="buscadorFiltros">
          <div style={{ width: "100%" }}>
            <Box
              mx={1}
              display={"block"}
              flexDirection={"row"}
              gap={2}
            >
              <TextField
                id="razonSocial-input"
                label="Razón Social - AAFF"
                variant="outlined"
                name='razonSocialAaff'
                className="filtros altura"
                value={busqueda.aaff}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
              <TextField
                id="razonSocial-input"
                name='razonSocial'
                label="Comunidad"
                variant="outlined"
                inputProps={{
                  "aria-label": "controlled",
                }}
                className="filtros altura"
                value={busqueda.comunidad}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              />
              <TextField
                id="codigoPostal-input"
                label="Código postal"
                variant="outlined"
                name='codigoPostal'
                className="filtros altura"
                value={busqueda.codigoPostal}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
              <TextField
                id="nif-input"
                label="Nif"
                variant="outlined"
                name='nif'
                className="filtros altura"
                value={busqueda.nif}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
              <FormControl variant="outlined" className='filtros-select'>
                <InputLabel id="estado-label">Estado</InputLabel>
                <Select
                  labelId="estado-label"
                  id="estado-select"
                  label="Estado"
                  name="estado"
                  className="filtros"
                  value={busqueda.estado}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                  <MenuItem value="" style={{height: 40}}></MenuItem>
                  <MenuItem value={1} style={{height: 40}}>Pendiente</MenuItem>
                  <MenuItem value={2} style={{height: 40}}>Visitada</MenuItem>
                  <MenuItem value={3} style={{height: 40}}>Renovada pendiente</MenuItem>
                  <MenuItem value={4} style={{height: 40}}>Renovada visitada</MenuItem>
                  
                </Select>
              </FormControl>
            </Box>
          </div>
          <div>
            <Button className="btn-buscador" onClick={() => getBusqueda()}>
              <BsSearch />
            </Button>
          </div>
        </div>
          {/* <div className='buscador' >
            <input
                type="text"
                name="razonSocial"
                value={busqueda.aaff}
                placeholder="Buscar aaff"
                onChange={getAaff}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
            ></input>
            <select
                type="text"
                name="aaff_id"
                value={busqueda.aaff_id}
                placeholder="Administrador de fincas"
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
            >
                <option value="">Administrador de fincas</option>
                {aaffFiltrado.map((e, i) => {
                    return (
                        <option key={i} value={e.id}>
                        {e.razonSocial}
                        </option>
                    );
                })}
            </select>
            <input
                type="text"
                name="razonSocial"
                value={busqueda.comunidad}
                placeholder="Comunidad"
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
            ></input>
            <button onClick={() => getBusqueda()}>LUPA</button>
          </div> */}
          <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
      </>
      
    );
}
 
export default withRouter(FiltrosGestion);