import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { IoSave, IoDocumentText } from "react-icons/io5";
import {
    getAAFFs,
} from "../../../services";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";

const comunidadesRows = [
  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
    overrideFunc: (data, row) => (
      <Link to={"/aaff/" + row.nif + "/comunidades"}>
        {data}
      </Link>
    ),
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "informeComunidades",
    numeric: false,
    disablePadding: false,
    label: "INFORME COMUNIDADES",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-comunidades`} target={"_blank"}>
          <IoDocumentText alt="Informe comunidades" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
  {
    id: "informeProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME PROVEEDORES",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-proveedores`} target={"_blank"}>
          <IoDocumentText alt="Informe proveedores" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
  {
    id: "informeRiesgos",
    numeric: false,
    disablePadding: false,
    label: "INFORME RIESGOS",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-riesgos`} target={"_blank"}>
          <IoDocumentText alt="Informe riesgos" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
];



const AAFFInformes = () => {
  const user = useContext(UsuarioContext);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
    setRecargar(false);
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    const payload = {
      filtros:{...filtros, informe: true},
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      const response = await getAAFFs(payload);

      const { meta, data } = response;
        console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  const formatData = (data) => {
    
    return data.map((row) => ({
        
      aff_name: row.razonSocial,
      nif: row.nif,
      informeComunidades: row.nif,
      informeProveedores: row.nif,
      informeRiesgos: row.nif,
      
    }));
  };

  /* function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    console.log(busqueda);
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    fetchData({ offset: 1, limit: limite, filtros: busqueda });
  }; */
  
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 
  
  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff-informes', label:"Informes"}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  INFORMES
              </div>
          </div>
      </div>
      <div className="pageContent tableContainer">
      <FiltrosAAFF onFilter={filtrarDatos} />  
      
        <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={comunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay administradores de fincas"}
                />
                </Box>
            </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(AAFFInformes);
