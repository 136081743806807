import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  subirDeclaracionResponsable,
  subirDocumentacionProveedor,
} from "../../../providers/documentos";
import orderArray from "../../../functions/ordenarListas";
import CircularProgress from "@mui/material/CircularProgress";
import { getAAFF, getCommercial } from "../../../services";
import {
  comprobarDocumentacion,
  daysDiff,
  eliminaDuplicados,
  formatoFechas,
  formatoFechasEs,
  subirDocumentacion,
  verDocumentacion,
  validarDocumentacion,
} from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import { UsuarioContext } from "../../../providers/userContext";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF5252",
    "&:hover": {
      backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF5252",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#7bc977",
    backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    "&:hover": {
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#7bc977",
  },
}));

const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FFD700",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD700",
    backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
}));

const Proveedores = (props) => {
  const user = useContext(UsuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const [aaff, setAaff] = useState({});
  const [comercial, setComercial] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [recargar, setRecargar] = useState(true);

  useEffect(() => {
    let data = {
      nif: props.params.nifAAFF,
    };
    getAAFF(props.params.nifAAFF).then((a) => {
      console.log(a)
      setAaff(a);
      getCommercial(a.commercial.id).then((res) => {
        setComercial(res);
      });
      let proveedores = [];
      a.properties.map((p) => {
        // console.log(p.suppliers)
        p.suppliers.map((s) => {
          proveedores.push(s);
          /* console.log(s)
                      console.log(new Date(s.ultimaReclamacion).toLocaleDateString(), new Date(s.createdAt).toLocaleDateString()) */
        });
      });
      console.log(eliminaDuplicados(proveedores))
      setProveedores(eliminaDuplicados(proveedores));
      setIsLoading(false);
    });
  }, [recargar]);

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    console.log(validado, status, ruta, id, idDoc, rol, nif)
    if(validado && status){
     verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
       console.log(resultado)
       console.log("resultado")
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        console.log(resultado)
        console.log("resultado")
        handleSubirDocsCallback(resultado)
      })
    }
  };

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comercial / {comercial.nombre + " " + comercial.apellidos} /{" "}
        {aaff.razonSocial} / <b>Proveedores</b>
      </div> */}
      <BreadCrumb rutas={[{path:'/comerciales', label:"Comerciales"}, {path:`/comerciales`, label:`${comercial.nombre+" "+comercial.apellidos}`}, {path:`/aaff/${aaff.nif}/comunidades`, label:`${aaff.razonSocial}`}, {path:'/proveedores', label:'proveedores'}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            LISTADO PROVEEDORES
            <Link to={`/comerciales/${props.params.nifComercial}/aaff`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Administradores de Fincas"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          {console.log(proveedores)}
          <div className="tableContainer">
            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Proveedor</th>
                    <th>NIF</th>
                    <th>Última reclamación</th>
                    <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                    <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                    <th style={{ textAlign: "center" }}>
                      Justificante pago SPA
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Planificación preventiva
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Identificación riesgos
                    </th>
                    <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                    <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                    <th style={{ textAlign: "center" }}>
                      Declaración responsable
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <CircularProgress style={{ color: "#92004D" }} />{" "}
                      </td>
                    </tr>
                  ) : proveedores.length === 0 ? (
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        No tiene proveedores
                      </td>
                    </tr>
                  ) : (
                    proveedores.map((e, i) => {
                      const color =
                        daysDiff(new Date(e.ultimaReclamacion), new Date()) > 30
                          ? "red"
                          : "#303248";
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Link to={"/proveedores/" + e.nif}>
                              {e.razonSocial}
                            </Link>
                          </td>
                          <td>{e.nif}</td>
                          <td>
                            {e.ultimaReclamacion === undefined ||
                            e.ultimaReclamacion === null ? (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(e.createdAt),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "#303248",
                                }}
                              >
                                {formatoFechasEs(e.createdAt)}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(e.ultimaReclamacion),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "#303248",
                                }}
                              >
                                {formatoFechasEs(e.ultimaReclamacion)}
                              </span>
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 1 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 1, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 1 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 1 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 1 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 
                                    1, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 2 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 2, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 2 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 2 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 2 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 
                                    2, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad !==
                              undefined && e?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {new Date(
                                    e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                  ).toLocaleDateString()}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 3 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 3, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 3 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 3 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 3 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 
                                    3, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !==
                              undefined && e?.docssupps?.find( d => d.tipoDoc === 3 )?.status ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {new Date(
                                    e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                  ).toLocaleDateString()}
                                </span>
                              ) : (
                                "-"
                              )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 4 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 4, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 4 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 4 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 4 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 
                                    4, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 5 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 5, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 5 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 5 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 5 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 
                                    5, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 6 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 6, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 6 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 6 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 6 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 
                                    6, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !==
                                undefined && e?.docssupps?.find( d => d.tipoDoc === 6 )?.status ? (
                                  <span
                                    style={{
                                      color:
                                        daysDiff(
                                          new Date(
                                            e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                          ),
                                          new Date()
                                        ) > 30
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {new Date(
                                      e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                    ).toLocaleDateString()}
                                  </span>
                                ) : (
                                  "-"
                                )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 7 )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 7, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.docssupps?.find( d => d.tipoDoc === 7 )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e?.docssupps?.find( d => d.tipoDoc === 7 )?.validado,
                                    e?.docssupps?.find( d => d.tipoDoc === 7 )?.status,
                                    e?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta,
                                    e?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 
                                    7, 
                                    user.rol, 
                                    e.nif
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>
                              {e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !==
                                undefined && e?.docssupps?.find( d => d.tipoDoc === 7 )?.status ? (
                                  <span
                                    style={{
                                      color:
                                        daysDiff(
                                          new Date(
                                            e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                          ),
                                          new Date()
                                        ) > 30
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {new Date(
                                      e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                    ).toLocaleDateString()}
                                  </span>
                                ) : (
                                  "-"
                                )}
                            </small>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {e?.declres?.find( d => d.aaff_id === aaff.id )?.pendiente ? (
                              <CustomPendienteSwitch
                                checked={false}
                                onClick={() => validarDocumentacion(e?.declres?.find( d => d.aaff_id === aaff.id )?.ruta, e?.declres?.find( d => d.aaff_id === aaff.id )?.id, e.nif)}
                              />
                            ) : (
                              <CustomSwitch
                                checked={e?.declres?.find( d => d.aaff_id === aaff.id )?.validado}
                                onClick={
                                  () => comprobarArchivos(
                                    e.nif,
                                    aaff.id,
                                    e?.declres?.find( d => d.aaff_id === aaff.id )?.ruta,
                                    e?.declres?.find( d => d.aaff_id === aaff.id )?.id, 
                                  )
                                }
                              />
                            )}
                            <small style={{ display: "block" }}>-</small>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Proveedores);
