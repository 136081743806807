import React, {useState, useEffect} from 'react'
import { Modal, Box, Button } from "@mui/material";
import { withRouter } from '../../providers/withRouter';
import { FaCheck } from "react-icons/fa";
import { toast } from 'react-toastify';
import { formatoFechasEs } from '../../functions/funciones';
import { validateWithDocSuppAlert } from '../../services';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85ch',
    maxHeight: '500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    overflow: 'auto',

  };

const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const ModalDocsSupp = (props) => {
    console.log(props)

    const [validating, setValidating] = useState(false);
    const proveedor = props.proveedor
    const docssupps = proveedor.docssupps || []
    const [seleccionados, setSeleccionados] = useState([]);
    const [seleccionGeneral, setSeleccionGeneral] = useState(false);

    console.log(docssupps)
    
    const handleSeleccionIndividual = (id) => {
        const nuevosSeleccionados = { ...seleccionados };
        nuevosSeleccionados[id] = !nuevosSeleccionados[id];
        console.log(nuevosSeleccionados)
        setSeleccionados(nuevosSeleccionados);
    };

    const handleSeleccionGeneral = () => {
        setSeleccionGeneral(!seleccionGeneral);
        const nuevosSeleccionados = {};
        docssupps.forEach((d) => {
                nuevosSeleccionados[d.id] = !seleccionGeneral;
        });

        setSeleccionados(nuevosSeleccionados);
    };

    const elementosSeleccionados = docssupps.filter((d) => seleccionados[d.id]);

    const validarSupp = async () => {
        console.log(elementosSeleccionados)
        validateWithDocSuppAlert(proveedor.id, elementosSeleccionados).then( () => {
            setSeleccionGeneral([])
            setSeleccionados([])
            props.handleClose()
        })
    }

    const formatDate = (date) => {
        return date ? formatoFechasEs(date) : '-';
    };

    return ( 
        <Modal 
            open={props.opened}
            onClose={props.handleClose}
            className={modal}
        >
            <Box sx={style}>
                <>
                    <div className='tableContainer'>
                        <div style={{ display:'flex', justifyContent:'end', margin:'20px 0' }}>
                            <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px'}} onClick={ () => validarSupp()} disabled={validating}><FaCheck /></Button>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th><input id="check" className='checkBoxCustomTop' style={{ color: '#ffffff', marginLeft: '5px'}} type="checkbox" checked={seleccionGeneral} onChange={handleSeleccionGeneral} /></th>
                                    <th>Nombre</th>
                                    <th>Fecha caducidad</th>
                                    <th>Última reclamación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log(docssupps)}
                                {docssupps?.map( d => {
                                    return (
                                        <tr key={d.id}>
                                            <td>
                                                <input
                                                    id={`check${d.id}`}
                                                    className='checkBoxCustom'
                                                    type="checkbox"
                                                    checked={seleccionados[d.id] || false}
                                                    onChange={() => handleSeleccionIndividual(d.id)}
                                                />
                                                <label htmlFor={`check${d.id}`} className='labelCustom'></label>
                                            </td>
                                            <td>{d.type.nombre}</td>
                                            <td>{formatDate(d.fechaCaducidad)}</td>
                                            <td>{formatDate(d.ultimaReclamacion) || "-"}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            </Box>
        </Modal>
     );
}
 
export default withRouter(ModalDocsSupp);