import React, { useState, useEffect, useContext } from "react";
import { getCommercials, deleteCommercial } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { DataTable } from "../../../components";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

const Comerciales = () => {
  const user = useContext(UsuarioContext)
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [comerciales, setComerciales] = useState([]);
  const [recargar, setRecargar] = useState(true);
  const [limite, setLimite] = useState(50);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData()
  }, [recargar]);

  const fetchData = async ({offset, limit, filtros}) => {
    
    setLimite(limit)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };
    try {
      setLoading(true);
      const response = await getCommercials(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const formatData = (data) => {
    return data.map((row, i) => ({
      id: i+1,
      comercial: row.nombre+" "+row.apellidos,
      nif: row.nif,
      aaff: row.nif,
    }));
  };

  const comercialesRows = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "comercial",
      numeric: false,
      disablePadding: false,
      label: "COMERCIAL",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "aaff",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADORES FINCAS",
      overrideFunc: (data, row) => (
        <Link to={"/comerciales/" + row.nif + "/aaff"}>
          <img
            src={Lupa}
            alt="Administradores de Fincas"
            className="tableIcon"
          ></img>
        </Link>
      )
    },
    user.rol === 'ADMIN' ?
    {
      id: "eliminar",
      numeric: false,
      disablePadding: false,
      label: "ELIMINAR",
      overrideFunc: (data, row) => (
        <img
          src={Delete}
          alt="ELIMINAR"
          className="tableIcon"
          onClick={() => eliminarComercial(row)}
        ></img>
      )
    }
    : '',
  ]

  const eliminarComercial = (com) => {
    let nombre = com.nombre + " " + com.apellidos;
    Swal.fire({
      title: "¿Quieres borrar este comercial?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borrar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteCommercial(com.nif)
            .then((res) => {
              if (res.message === "COMMERCIAL_DELETED") {
                toast.success("Comercial eliminado");
                setRecargar(!recargar);
              } else {
                toast.error("No se ha podido elimiar el comercial");
              }
            })
            .catch((e) => {
              toast.error("No se ha podido elimiar el comercial");
              console.log(e);
            });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error");
        console.log(e);
      });
  };

  
  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:"Comerciales"}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
            <div className="gridHead">
                COMERCIALES
            </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          {user.rol === "ADMIN" && 
            <>
              <div></div>
              <div style={{display: "flex"}}>
              <Link to="/comerciales/nuevo">
                <div className="contentButton">
                  <img src={Add} alt="Nuevo comercial" /><span>Nuevo Comercial</span>
                </div>
              </Link>
              <Link to="/comerciales/nuevo-combo" style={{marginLeft: 10}}>
                <div className="contentButton">
                  <img src={Add} alt="Nuevo comercial combo" /><span>Nuevo Comercial Combo</span>
                </div>
              </Link>
              </div>
            </>
          }
        </div>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comercialesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay comerciales"}
              />
            </Box>
          </Box>
        </div>
        {/* <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>Comercial</th>
                <th style={{ textAlign: "center" }}>NIF</th>
                <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                {user.rol === "ADMIN" && 
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                }
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#92004D" }} />{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {comerciales.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.nombre + " " + e.apellidos}
                    </td>
                    <td style={{ textAlign: "center" }}>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>
                      <Link to={"/comerciales/" + e.nif + "/aaff"}>
                        <img
                          src={Lupa}
                          alt="Administradores de Fincas"
                          className="tableIcon"
                        ></img>
                      </Link>
                    </td>
                    {user.rol === "ADMIN" && 
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={Delete}
                          alt="ELIMINAR"
                          className="tableIcon"
                          onClick={() => eliminarComercial(e)}
                        ></img>
                      </td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default Comerciales;
