import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { TiArrowBack } from "react-icons/ti";
import Add from "../../../icons/add.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { getAAFF, getProveedor } from "../../../services";
import { cambiarFormatoNombre, daysDiff } from "../../../functions/funciones";
import { withRouter } from "../../../providers/withRouter";
import Editar from "../../../icons/editar.svg";
import {
  abrirArchivo,
  subirDocumentacionProveedor,
  subirDeclaracionResponsable,
  validarDocumentacion,
  verDocumentacion,
} from "./../../../providers/documentos";
import ModalEditar from "./editarDatosProv";
import { DialogoSubirDocumentacion } from "../../../providers/Dialogos";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { UsuarioContext } from "../../../providers/userContext";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF5252",
    "&:hover": {
      backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF5252",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#7bc977",
    backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    "&:hover": {
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#7bc977",
  },
}));

const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FFD700",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD700",
    backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
}));

const Proveedor = (props) => {
  // const delegations = 1;
  const user = useContext(UsuarioContext);
  const [aaff, setAaff] = useState({});
  const [proveedor, setProveedor] = useState({});
  const [fincas, setFincas] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [open, setOpen] = useState(0);
  const [dialog, setDialog] = useState({});

  const hijoAPadre = () => {
    setRecargar(!recargar);
  };

  useEffect(() => {
    getAAFF(props.params.nifAAFF).then((res) => {
      let fincas = [];
      setAaff(res);
      console.log(res)
      setFincas(res.properties);
    });

    getProveedor(props.params.nifProveedor).then((res) => {
      setProveedor(res);
      console.log(res);
    });

    //PENDIENTE SACAR LA DOCUMENTACION LEIDA DE CADA COMUNIDAD POR EL PROVEEDOR
  }, [recargar]);

  const mostrarPanel = (data) => {
    console.log(data.open, data.nombreDoc, data.title, data.tipo);
    setDialog({
      opened: true,
      title: data.title,
      docName: data.nombreDoc,
      fechaCaducidad: undefined,
      fecha: undefined,
    });
    setOpen(data.open);
  };

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    
    if(validado && status){
     verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
       console.log(resultado)
       console.log("resultado")
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        console.log(resultado)
        console.log("resultado")
        handleSubirDocsCallback(resultado)
      })
    }
  };
  
  
  return (
    <>
      <ModalEditar
        proveedor={proveedor}
        opened={modalEditarOpened}
        hijoAPadre={hijoAPadre}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <BreadCrumb rutas={[{path:'/aaff', label: 'Administrador'},{path:`/aaff/${aaff.nif}/comunidades`, label: `${aaff.razonSocial}`}, {path:`/aaff/${aaff.nif}/proveedores`, label: 'proveedores'}, {path:`/aaff/${aaff.nif}/proveedores/${proveedor.nif}`, label: `${proveedor.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to={`/aaff/${aaff.nif}/proveedores`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Proveedores"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre proveedor:</b> {proveedor.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {proveedor.direccion}
            </p>
            <p>
              <b>NIF:</b> {proveedor.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(proveedor.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {proveedor.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {proveedor.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {proveedor.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {proveedor.provincia}
            </p>
            <p>
              <b>Email de login:</b> {proveedor.emailLogin}
            </p>
            <p>
              <b>Telefono:</b> {proveedor.telefonoContacto}
            </p>
            <p>
              <b>Fecha de alta:</b>{" "}
              {new Date(proveedor.createdAt).toLocaleDateString()}
            </p>
            <p>
              <b>Ultima reclamación:</b>{" "}
              {proveedor.ultimaReclamacion === undefined ||
              proveedor.ultimaReclamacion === null
                ? new Date(proveedor.createdAt).toLocaleDateString()
                : new Date(proveedor.ultimaReclamacion).toLocaleDateString()}
            </p>
          </div>
          <div className="datosGridComentarios">
            <p>
              <b>Comentarios:</b>
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              {" "}
              {proveedor.comentarios === "" ||
              proveedor.comentarios === undefined
                ? "-"
                : proveedor.comentarios}{" "}
            </p>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>
                    Planificación preventiva
                  </th>
                  <th style={{ textAlign: "center" }}>
                    Identificación riesgos
                  </th>
                  <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                  <th style={{ textAlign: "center" }}>
                    Declaración responsable
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 1, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 
                            1, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.pendiente && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                        <CustomPendienteSwitch
                          checked={false}
                          onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 2, proveedor.nif)}
                        />
                      ) : (
                        <CustomSwitch
                          checked={proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status}
                          onClick={
                            () => comprobarArchivos(
                              proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 
                              2, 
                              user.rol, 
                              proveedor.nif
                            )
                          }
                        />
                      )}
                      <small style={{ display: "block" }}>
                        {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad !==
                        undefined && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                          <span
                            style={{
                              color:
                                daysDiff(
                                  new Date(
                                    proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                  ),
                                  new Date()
                                ) > 30
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {new Date(
                              proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                            ).toLocaleDateString()}
                          </span>
                        ) : (
                          "-"
                        )}
                      </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 3, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 
                            3, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !==
                      undefined ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 4, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 
                            4, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 5, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 
                            5, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 6, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 
                            6, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !==
                      undefined ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 7, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 
                            7, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !==
                      undefined ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => validarDocumentacion(proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.ruta, proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor.nif,
                            aaff.id,
                            proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.ruta,
                            proveedor?.declres?.find( d => d.aaff_id === aaff.id )?.id, 
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>
          
        </div>
        <div className="gridCard">
          <div className="gridHead">COMUNIDADES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fincas.map((e, i) => {
                /* e.documentos?.prevencionRiesgos?.ultLectura = "Nunca"
                                const ultLecturaprevencionRiesgos = e.documentos?.prevencionRiesgos?.leido?.[this.state.proveedor.nif]?.seconds
                                if (ultLecturaprevencionRiesgos !== undefined) {
                                let fechaLectura = new Date(ultLecturaprevencionRiesgos * 1000)
                                e.documentos?.prevencionRiesgos.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
                                }
                                e.documentos?.planEmergencia.ultLectura = "Nunca"
                                const ultLecturaplanEmergencia = e.documentos?.planEmergencia?.leido?.[this.state.proveedor.nif]?.seconds
                                if (ultLecturaplanEmergencia !== undefined) {
                                let fechaLectura = new Date(ultLecturaplanEmergencia * 1000)
                                e.documentos?.planEmergencia.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
                                } */
                return (
                  <tr key={i}>
                    <td style={{ padding: "10px 0" }}>
                      {e.razonSocial} <br />
                      <small>{e.servicios}</small>
                      <br />
                      <small>
                        <b>Evaluación de riesgos y medidas preventivas:</b>{" "}
                        Nunca
                      </small>
                      <br />
                      <small>
                        <b>Plan de emergencia:</b> Nunca
                      </small>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="gridCard" /* style={delegations > 0 ? { display: 'block' } : { display: 'none' }} */>
          <div className="gridHead">
            DELEGACIONES
            <Link to={`/nueva-delegacion`}>
              <div className="gridHeadRight">
                <img src={Add} alt="Más" width={15} />
              </div>
            </Link>
          </div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              
            </tbody>
          </table>
        </div>
      </div>
      {dialog.open === 1 && (
        <DialogoSubirDocumentacion
          open={dialog.open}
          proveedor={proveedor}
          dialog={dialog}
          hijoAPadre={hijoAPadre}
        />
      )}
      <style jsx="true">{`
          .pageGrid {
            position: relative;
            top: 00px;
            margin: 0 10px;
            display: grid;
            grid-template-columns: 5fr 3fr 3fr;
            grid-gap: 20px;
          }                
      `}</style>
    </>
  );
};

export default withRouter(Proveedor);
