import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ProveedorIcon from "../icons/proveedor.svg";
import AlertaIcon from "../icons/alerta.svg";
import ComunidadesIcon from "../icons/comunidades.svg";
import VentasIcon from "../icons/ventas.svg";

import Inicio from "./pages/aaff/Inicio";
import Comunidades from "./pages/tecnico/Comunidades";
import Comunidad from "./pages/tecnico/Comunidad";
import CrearHojaVisita from "./pages/tecnico/CrearHojaVisita";
import EditarHojaVisita from "./pages/tecnico/EditarHojaVisita";
import Proveedores from "./pages/tecnico/Proveedores";
import Proveedor from "./pages/aaff/Proveedor";
import AAFF from "./pages/tecnico/AAFF";
import AlertasProveedores from "./pages/tecnico/AlertasProveedores";
import DetallesAAFF from "./pages/tecnico/DetallesAAFF";
import Ventas from "./pages/general/Ventas";
import CrearVenta from "./pages/general/CrearVenta";
import DatosPerfil from "../components/perfil/datosPerfil";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="comunidades" element={<Comunidades />} />
      <Route path="comunidades/:nifFinca" element={<Comunidad />} />
      <Route path="aaff/:nif/comunidades/:nifFinca" element={<Comunidad />} />
      <Route path="hojavisita/:nif/create/:id" element={<CrearHojaVisita />} />
      <Route path="hojavisita/:nif/edit/:id" element={<EditarHojaVisita />} />
      <Route path="hojavisita/:nif/:idCentroTrabajo/create/:id" element={<CrearHojaVisita />} />
      <Route path="hojavisita/:nif/:idCentroTrabajo/edit/:id" element={<EditarHojaVisita />} />
      <Route path="proveedores" element={<Proveedores />} />
      <Route path="proveedores/:nif" element={<Proveedor />} />
      <Route path="aaff" element={<AAFF />} />
      <Route path="aaff/:nif/comunidades" element={<DetallesAAFF />} />
      <Route path="/alertas" element={<AlertasProveedores />} />
      <Route path="/servicios/" element={<Ventas />} />
      <Route path="/servicios/create" element={<CrearVenta />} />
      <Route path="/servicios/edit/:ventaId" element={<EditarHojaVisita />} />
      <Route path="/perfil" element={<DatosPerfil />} />
    </Routes>
  );
}

export function MenuLinksClientes(props) {
  return (
    <>
      <li>
        <NavLink
          activeclassname="active"
          to="/aaff"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
            color: props.showSidebar ? "#33383b" : "#fff",
          }}
        >
          <div className="menuBtn">
            <img src={Edificios} alt="Administradores de Fincas" />
            Administradores de Fincas
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/comunidades"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={ComunidadesIcon} alt="Comunidades" />
            Comunidades
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/proveedores"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={ProveedorIcon} alt="Proveedores" />
            Proveedores
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/servicios"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={VentasIcon} alt="Servicios" />
            Servicios
          </div>
        </NavLink>
      </li>
    </>
  );
}

export function MenuLinks(props) {
  return (
    <>
      <li>
        <NavLink
          activeclassname="active"
          to="/alertas"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={AlertaIcon} alt="Alertas" />
            Alertas
          </div>
        </NavLink>
      </li>
    </>
  );
}
