import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link, useNavigate } from "react-router-dom";
import {
  getAAFF,
  deleteAAFF,
  deleteFincasAAFF,
  plantillaFincas,
  plantillaProveedores,
  plantillaMatcheo,
  getConsultores,
  getSalesPropsIds,
  sendEmailBienvenida
} from "../../../services";
import { excelToJson } from "../../../providers/excel-to-json";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import Delete from "../../../icons/delete.svg";
import { Grid, Button } from "@mui/material";
import Editar from "../../../icons/editar.svg";
import ModalEditar from "./editarDatosAAFF";
import { cambiarFormatoNombre, formatoFechasEs} from "./../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import { BsDownload, BsUpload } from "react-icons/bs";
import { IoSave, IoDocumentText } from "react-icons/io5";
import ListadoComunidades from "./ListadoComunidades";
import { subirListado, eliminarFincas } from "../../../functions/documentacion";
import Chart from "../../../components/Charts/apexCharts";
import { UsuarioContext } from "../../../providers/userContext";
import ObservacionesTecnico from "../../../components/aaff/observacionesTecnico";
import Comentarios from "../../../components/aaff/comentarios";
import { CircularProgress } from '@mui/material';

const Comunidades = (props) => {
  const navigate = useNavigate();
  const user = useContext(UsuarioContext)
  const [aaff, setAAFF] = useState({});
  const [comentarios, setComentarios] = useState([]);
  const [observaciones, setObservaciones] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(0);
  const [usuarios, setUsuarios] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [propertiesCae, setPropertiesCae] = useState([])
  


  useEffect(() => {
    fetchData()
    setRecargar(false);
  }, [recargar]);

  const fetchData = async () => {
    console.log(props.params.nif)
    const responseAaffs = await getAAFF(props.params.nif);
    setAAFF(responseAaffs);   
    console.log(responseAaffs)
    responseAaffs.r_consul_servsgroups_aaff?.find( item => item.group_id === 1)
    const nombreCompleto = responseAaffs.r_consul_servsgroups_aaff?.find(item => item.group_id === 1)?.consultant ? `${responseAaffs.r_consul_servsgroups_aaff.find(item => item.group_id === 1).consultant.nombre} ${responseAaffs.r_consul_servsgroups_aaff.find(item => item.group_id === 1).consultant.apellidos}` : '';
    console.log(nombreCompleto)

    setIsLoading(false);
    const ids = await getSalesPropsIds(responseAaffs.id)
    const resultado = responseAaffs.properties.filter(item => ids.includes(item.id) && item.status === true);
    setPropertiesCae(resultado)
    console.log(resultado)
  }

  function eliminarAAFF() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres eliminar el AAFF?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#888",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar"
        });
  
        if (result.isConfirmed) {
          const toastInfo = toast.info("Eliminando AAFF", { autoClose: false });
          await deleteAAFF(aaff.nif).then((res) => {
            console.log(res.data.message)
            if (res.data.message === "AAFF_DELETED") {
              toast.update(toastInfo, {
                render: "Eliminado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
              navigate("/aaff")
            }else{
              toast.update(toastInfo, {
                render: "Error al eliminar",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          }).catch(error => {
              console.log(error);
              toast.update(toastInfo, {
                render: "Error al eliminar",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
              reject(error);
            });
        } else {
          // Si el usuario cancela la eliminación
          resolve(false);
        }
      } catch (error) {
        console.error("Error al eliminar el aaff:", error);
        reject(error);
      }
    });
    
  }

  function deleteFincas(){
    eliminarFincas(aaff).then(() => {
      setRecargar(!recargar)
    })
  }
  
  function subirFincas(evt){
    subirListado(evt).then(() => {
      setRecargar(!recargar)
    })
  }

  const handleSendEmail = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await Swal.fire({
          title: "¿Quieres eliminar el AAFF?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#888",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Eliminar"
        });
  
        if (result.isConfirmed) {
          console.log(user.id, props.params.nif, aaff.razonSocial, aaff.user.email)
          const toastInfo = toast.info("Enviando email de bienvenida a AAFF", { autoClose: false });
          await sendEmailBienvenida(user.id, props.params.nif, aaff.razonSocial, aaff.user.email).then( (res) => {
            console.log(res)
            if (res.status === 201) {
              toast.update(toastInfo, {
                render: "Enviado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            }else{
              toast.update(toastInfo, {
                render: "Error al enviar email",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            }
          }).catch(error => {
              console.log(error);
              toast.update(toastInfo, {
                render: "Error al enviar email",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
              reject(error);
          });
        } else {
          // Si el usuario cancela la eliminación
          resolve(false);
        }
      } catch (error) {
        console.error("Error al enviar email:", error);
        reject(error);
      }
    })
    
  }

  return isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
      <ModalEditar
        aaff={aaff}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <iframe title="pdf" id="iFramePdf" style={{ display: "none" }}></iframe>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Administrador / <b>{aaff.razonSocial}</b>
      </div> */}
      <BreadCrumb rutas={[{path:'/aaff', label:`Administradores de fincas`}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to="/aaff">
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Administradores de Fincas"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div className="btnsSubhead">
              <div className="editarBtn" onClick={() => setModalEditarOpened(true)} >
                <span style={{ marginRight: 10 }}>Editar</span>{" "}
                <img src={Editar} alt="Editar" />
              </div>
              <div className="editarBtn" onClick={eliminarAAFF}>
                <span style={{ marginRight: 10 }}>Eliminar</span>{" "}
                <img src={Delete} alt="Eliminar" />
              </div>
            </div>
          </div>
          <div style={{ display:'flex' }}>
            <div className="datosGrid" style={{ width: '75%' }}>
              <p>
                <b>Nombre AAFF:</b> {aaff.razonSocial}
              </p>
              <p>
                <b>NIF:</b> {aaff.nif}
              </p>
              <p>
                <b>Municipio:</b> {cambiarFormatoNombre(aaff.municipio)}
              </p>
              <p>
                <b>Provincia:</b> {aaff.provincia}
              </p>
              <p>
                <b>Persona de contacto:</b> {aaff.nombreContacto}
              </p>
              <p>
                <b>Teléfono:</b> {aaff.telefono}
              </p>
              <p>
                <b>Email:</b> {aaff.user?.email}
              </p>
              {aaff.gruposServicios.includes(1) &&
                <p>
                  <b>Consultor Cae:</b>{" "}
                  {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1) ? `${aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 1).apellidos}` : ''}
                  {/* {aaff.r_consul_servsgroups_aaff?.consultant} */}
                  {/* {aaff.consultant?.nombre !== undefined
                    ? aaff.consultant?.nombre + " " + aaff.consultant?.apellidos
                    : "-"}{" "} */}
                </p>
              }
              {aaff.gruposServicios.includes(2) &&
                <p>
                  <b>Consultor Lopd:</b>{" "}
                  {aaff.consultants?.find(item => item.r_consul_servsgroups_aaffs.group_id === 2) ? `${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).nombre} ${aaff.consultants.find(item => item.r_consul_servsgroups_aaffs.group_id === 2).apellidos}` : ''}
                  {/* {aaff.r_consul_servsgroups_aaff?.consultant} */}
                  {/* {aaff.consultant?.nombre !== undefined
                    ? aaff.consultant?.nombre + " " + aaff.consultant?.apellidos
                    : "-"}{" "} */}
                </p>
              }
              <p>
                <b>Técnico:</b>{" "}
                {aaff.technician?.nombre !== undefined
                  ? aaff.technician?.nombre + " " + aaff.technician?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Comercial:</b>{" "}
                {aaff.commercial?.nombre !== undefined
                  ? aaff.commercial?.nombre + " " + aaff.commercial?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Fecha de aviso:</b>{" "}
                {aaff.user?.notifiedAt === undefined || aaff.user?.notifiedAt === null
                  ? null
                  : formatoFechasEs(aaff.user?.notifiedAt)}{" "}
              </p>
              <p>
                <b>Fecha de encargo:</b>{" "}
                {aaff.fechaEncargo === undefined || aaff.fechaEncargo === null
                  ? null
                  : formatoFechasEs(aaff.fechaEncargo)}{" "}
              </p>
              <p>
                <b>Fecha de bienvenida:</b>{" "}
                {aaff.fechaBienvenida === undefined ||
                aaff.fechaBienvenida === null
                  ? null
                  : formatoFechasEs(aaff.fechaBienvenida)}{" "}
              </p>
              <p>
                <b>Fecha de Reclamación proveedores:</b>{" "}
                {aaff.fechaReclamacion === undefined ||
                aaff.fechaReclamacion === null
                  ? null
                  : formatoFechasEs(aaff.fechaReclamacion)}{" "}
              </p>
              <p>
                <b>Fecha informe proveedores:</b>{" "}
                {aaff.fechaInforme === undefined || aaff.fechaInforme === null
                  ? null
                  : formatoFechasEs(aaff.fechaInforme)}{" "}
              </p>
            </div>
            {aaff?.properties != undefined && aaff?.gruposServicios.includes(1) ? <Chart datos={propertiesCae}/> : null}
          </div>
          <div class="datosGrid">
            <div></div>
            <div style={{display:'flex', justifyContent:'end'}}>
              <button onClick={() => handleSendEmail()} style={{margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px'}}>
                Enviar email bienvenida
              </button>
            </div>
          </div>
          <div class="datosGrid">
            <Comentarios />
          </div>
          {aaff?.gruposServicios.includes(1) && 
            <>
              <hr />
              <div class="datosGrid">
                
                <ObservacionesTecnico />
              </div>
            </>
          }
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              margin: 10,
            }}
          >
          </div>
          {/* <div className="gridSubhead editarSubhead">
            <div>LISTADOS Y PLANTILLAS</div>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
            style={{ padding: "20px 20px 30px 20px" }}
          >
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoFincas">
                <div className="listadoBtn"><BsUpload alt="subir listado de fincas" style={{ marginRight: 5 }} /> Listado fincas</div>
              </label>
              <input
                type="file"
                id="listadoFincas"
                name="listadoFincas"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirFincas}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaFincas.xlsx"
                  download="plantillaFincas.xlsx"
                >
                  <BsDownload alt="descargar plantilla de fincas" /> Plantilla fincas
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoProveedores">
                <div className="listadoBtn"><BsUpload alt="subir listado de Proveedores" style={{ marginRight: 5 }} />Listado proveedores</div>
              </label>
              <input
                type="file"
                id="listadoProveedores"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirListadoProveedores}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaProveedores.xlsx"
                  download="plantillaProveedores.xlsx"
                >
                  <BsDownload alt="descargar plantilla de proveedores" /> Plantilla proveedores
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoMatcheo">
                <div className="listadoBtn"><BsUpload alt="subir listado de matcheo" style={{ marginRight: 5 }} />Listado matcheo</div>
              </label>
              <input
                type="file"
                id="listadoMatcheo"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirListadoMatcheo}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaMatcheo.xlsx"
                  download="plantillaMatcheo.xlsx"
                >
                  <BsDownload alt="descargar plantilla de Matcheo" /> Plantilla matcheo
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoRenovacion">
                <div className="listadoBtn"><BsUpload alt="subir listado de renovación" style={{ marginRight: 5 }} />Listado Renovación</div>
              </label>
              <input
                type="file"
                id="listadoRenovacion"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(doc) => {
                  excelToJson(doc.target.files[0], (res) =>
                    this.subirListadoRenovacion(res)
                  );
                }}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaRenovacion.xlsx"
                  download="plantillaRenovacion.xlsx"
                >
                  <BsDownload alt="descargar plantilla de renovación" /> Plantilla renovación
                </a>
              </div>
            </Grid>
          </Grid> */}
          <div className="gridSubhead editarSubhead">
            <div>LISTADO COMUNIDADES</div>
            <div className="subheadBtn" onClick={deleteFincas}>
              <span style={{ marginRight: 10 }}>Eliminar fincas</span>{" "}
              <img src={Delete} alt="Eliminar" />
            </div>
          </div>
          <div className="tableContainer">
            <ListadoComunidades />
          </div>
        </div>
      </div>
      {/* {open && (
        <DialogoCambioAAFF
          open={open}
          finca={fincaCambiarAAFF}
          aaff={props.params.nif}
          tipo={"cambiar"}
          hijoAPadre={hijoAPadre}
        />
      )} */}
    </>
  ;
};

export default withRouter(Comunidades);
