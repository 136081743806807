import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getAAFFs, getAllTecnicos } from '../../services';
import {
    LinearProgress,
    styled,
    Box,
    linearProgressClasses,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    FormGroup,
    FormLabel,
    InputLabel
  } from "@mui/material";
import { UsuarioContext } from '../../providers/userContext';
import { ordenarNombreYApellido, orderArray, ordenarProvinciasYMunicipios } from '../../functions/ordenarListas';
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";

const FiltrosGestion = ({onFilter}) => {
    const [listadoMunicipios, setListadoMunicipios] = useState([]);
    const [busqueda, setBusqueda] = useState({})
    const [aaff, setAAFF] = useState([])
    const [tecnicos, setTecnicos] = useState([])
    const user = useContext(UsuarioContext)
    const [typeInicio, setTypeInicio] = useState('text');
    const [typeFin, setTypeFin] = useState('text');

    const handleFocusInicio = () => setTypeInicio('date');
    const handleBlurInicio = () => {
      if (!busqueda.fechaAltaInicio) setTypeInicio('text');
    };
    const handleFocusFin = () => setTypeFin('date');
    const handleBlurFin = () => {
      if (!busqueda.fechaAltaFin) setTypeFin('text');
    };

    useEffect(() => {
      if(user.rol !== "AAFF"){
        getAAFFs({filtros:{}, params:{}}).then( res => {
            setAAFF(orderArray(res.data))
        })
      }
      if( user.rol !== 'SUPP' && user.rol !== 'COMM' && user.rol !== "AAFF"){
        console.log(user.rol)
          getAllTecnicos().then( res => {
            console.log(res)
              setTecnicos(ordenarNombreYApellido(res))
          })
      }
    },[])

    useEffect(() => {
      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, busqueda.provincia)
      );
    }, [busqueda]);

    
    const seleccionarMunicipios = (e) => {
      let codProvincia = "";
      let municipios = [];
      provinces.map((p) => {
        if (p.name === e.target.value) {
          codProvincia = p.code;
        }
      });
      if (e.target.name === 'provincia' || e.target.value === '') {
        // Si el campo provincia cambia a vacío, reiniciar el campo municipio
        setBusqueda({
          ...busqueda,
          [e.target.name]: e.target.value,
          municipio: '', // Establecer el campo municipio en vacío
        });
      } else {
        setBusqueda({
          ...busqueda,
          [e.target.name]: e.target.value,
        });
      }
      municipalities.map((m) => {
        if (m.provCode === codProvincia) {
          municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name });
        }
      });
      setListadoMunicipios(municipios);
    };

    function handleChange(evt) {
        const value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
    
      const getBusqueda = () => {
        
        for (let key in busqueda) {
          if (
            busqueda[key] === "" ||
            busqueda[key] === null ||
            busqueda[key] === undefined
          ) {
            delete busqueda[key];
          }
        }
        console.log(busqueda);
        onFilter(busqueda)
      };

      return ( 
        <>
            <div className="buscadorFiltros">
              <div style={{width:"100%"}}>
                <Box
                  mx={1}
                  display={"block"}
                  flexDirection={"row"}
                  gap={2}
                >
                  <TextField
                    id="nif-input"
                    label="Nif"
                    variant="outlined"
                    name='nif'
                    className="filtros altura"
                    value={busqueda.nif}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                  <TextField
                    id="codigoPostal-input"
                    label="Código postal"
                    variant="outlined"
                    name='codigoPostal'
                    className="filtros altura"
                    value={busqueda.codigoPostal}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                  <TextField
                    type={typeInicio}
                    id="fechaAltaInicio-input"
                    label="Fecha alta (Desde)"
                    variant="outlined"
                    name='fechaAltaInicio'
                    className="filtros altura"
                    value={busqueda.fechaAltaInicio}
                    onChange={handleChange}
                    onFocus={handleFocusInicio}
                    onBlur={handleBlurInicio}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                  <TextField
                    type={typeFin}
                    id="fechaAltaFin-input"
                    label="Fecha alta (Hasta)"
                    variant="outlined"
                    name='fechaAltaFin'
                    className="filtros altura"
                    value={busqueda.fechaAltaFin}
                    onChange={handleChange}
                    onFocus={handleFocusFin}
                    onBlur={handleBlurFin}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                  <FormControl variant="outlined" className='filtros-select'>
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="comunidad-label"
                    id="province-select"
                    label="Provincia"
                    name="provincia"
                    className="filtros"
                    value={busqueda.provincia}
                    onChange={seleccionarMunicipios}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  >
                    <MenuItem value="" style={{height: 40}}></MenuItem>
                    {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.name}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className='filtros-select'>
                  <InputLabel id="municipio-label">Municipios</InputLabel>
                  <Select
                    labelId="municipio-label"
                    id="municipio-select"
                    label="Municipio"
                    name="municipio"
                    className="filtros"
                    value={busqueda?.municipio}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  >
                    <MenuItem value="" style={{height: 40}}></MenuItem>
                    {listadoMunicipios
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                </FormControl>
                {(user.rol !== "AAFF") && 
                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="aaff-label">Administrador de fincas</InputLabel>
                    <Select
                      labelId="aaff-label"
                      id="aaff-select"
                      label="Administrador de fincas"
                      name="aaff_id"
                      className="filtros"
                      value={busqueda.aaffNif}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{height: 40}}></MenuItem>
                      {orderArray(aaff)?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.id}>
                            {e.razonSocial}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                }
                  {/* {
                    (user.rol !== 'SUPP' && user.rol !== 'COMM') && 
                    <FormControl variant="outlined" className='filtros-select'>
                      <InputLabel id="tecnicos-label">Tecnicos</InputLabel>
                      <Select
                        labelId="tecnicos-label"
                        id="tecnicos-select"
                        label="Tecnicos"
                        name="tech_id"
                        className="filtros"
                        value={busqueda.tecnicoNif}
                        onChange={handleChange}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                      >
                        <MenuItem value="" style={{height: 40}}></MenuItem>
                        {tecnicos?.map((e, i) => {
                            return (
                              <MenuItem key={i} value={e.id} style={{height: 40}}>
                                {e.nombre+" "+e.apellidos}
                              </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                  } */}
                </Box>
              </div>
              <div>
                <Button className="btn-buscador" onClick={() => getBusqueda()}>
                    <BsSearch />
                </Button>
              </div>
            </div>
          <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
        </>
     );
}
 
export default withRouter(FiltrosGestion);