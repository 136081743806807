import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import provinces from '@doncicuto/es-provinces';
import municipalities from '@doncicuto/es-municipalities';
import { ordenarProvinciasYMunicipios } from '../../functions/ordenarListas';
import { cambiarFormatoNombre, municipiosIniciales } from "../../functions/funciones";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField
} from "@mui/material";
import { UsuarioContext } from '../../providers/userContext';

const FiltrosProveedores = ({onFilter}) => {
    const user = useContext(UsuarioContext)
    const [busqueda, setBusqueda] = useState({})
    const [listadoMunicipios, setListadoMunicipios] = useState([]);

    function handleChange(evt) {
      const value = evt.target.value;
      setBusqueda({
        ...busqueda,
        [evt.target.name]: value,
      });
    }

    useEffect(() => {
      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, busqueda.provincia)
      );
    }, [busqueda]);
    
    const getBusqueda = () => {
      
      for (let key in busqueda) {
        if (
          busqueda[key] === "" ||
          busqueda[key] === null ||
          busqueda[key] === undefined
        ) {
          delete busqueda[key];
        }
      }
      console.log(busqueda);
      onFilter(busqueda)
    };
  
    const seleccionarMunicipios = (e) => {
      let codProvincia = "";
      let municipios = [];
      provinces.map((p) => {
        if (p.name === e.target.value) {
          codProvincia = p.code;
        }
      });
      if (e.target.name === 'provincia' || e.target.value === '') {
        // Si el campo provincia cambia a vacío, reiniciar el campo municipio
        setBusqueda({
          ...busqueda,
          [e.target.name]: e.target.value,
          municipio: '', // Establecer el campo municipio en vacío
        });
      } else {
        setBusqueda({
          ...busqueda,
          [e.target.name]: e.target.value,
        });
      }
      municipalities.map((m) => {
        if (m.provCode === codProvincia) {
          municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name });
        }
      });
      setListadoMunicipios(municipios);
    };

    return ( 
        <>
          <div className="buscadorFiltros">
            <div style={{width:"100%"}}>
              <Box
                mx={1}
                display={"block"}
                flexDirection={"row"}
                gap={2}
              >
                <TextField
                  id="razonSocial-input"
                  label="Razón Social"
                  variant="outlined"
                  name='razonSocial'
                  className="filtros altura"
                  value={busqueda.razonSocial}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  inputProps={{
                    "aria-label": "controlled",
                  }}
                />
                {user.rol !== 'AAFF' &&
                  <>
                    <TextField
                      id="nif-input"
                      label="Nif"
                      variant="outlined"
                      name='nif'
                      className="filtros altura"
                      value={busqueda.nif}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                    <TextField
                      id="email-input"
                      label="Email"
                      variant="outlined"
                      name='emailContacto'
                      className="filtros altura"
                      value={busqueda.email}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                  </>
                }
                <FormControl variant="outlined" className='filtros-select'>
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="provincia-label"
                    id="province-select"
                    label="Provincia"
                    name="provincia"
                    className="filtros"
                    value={busqueda.provincia}
                    onChange={seleccionarMunicipios}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  >
                    <MenuItem value="" style={{height: 40}}></MenuItem>
                    {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                      return (
                        <MenuItem key={i} value={e.name}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className='filtros-select'>
                  <InputLabel id="municipio-label">Municipios</InputLabel>
                  <Select
                    labelId="municipio-label"
                    id="municipio-select"
                    label="Municipio"
                    name="municipio"
                    className="filtros"
                    value={busqueda?.municipio}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  >
                    <MenuItem value="" style={{height: 40}}></MenuItem>
                    {listadoMunicipios
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                </FormControl>
              </Box>
            </div>
            <div>
              <Button className="btn-buscador" onClick={() => getBusqueda()}>
                <BsSearch />
              </Button>
            </div>
          </div>
            {/* <div className="buscador">
              <div>

                <input
                    type="text"
                    name="razonSocial"
                    value={busqueda.razonSocial}
                    placeholder="Razón Social"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="text"
                    name="nif"
                    value={busqueda.nif}
                    placeholder="Nif"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="text"
                    name="emailContacto"
                    value={busqueda.email}
                    placeholder="Email"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <select
                    type="text"
                    name="provincia"
                    value={busqueda.provincia}
                    placeholder="Provincia"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                    <option value="">Provincia</option>
                    provinces
                    {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                            <option key={i} value={e.name}>
                            {e.name}
                            </option>
                        );
                    })}
                </select>
              </div>
              <div>
                <button
                  onClick={() => getBusqueda()}
                  style={{ marginLeft: "10px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </div> */}
          <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
        </>
     );
}
 
export default withRouter(FiltrosProveedores);