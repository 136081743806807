import React, { useState, useEffect, useContext } from "react";

import { deleteEquipo, getEquipos } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { Box } from "@mui/material";
import { DataTable } from "../../../components";
import Editar from "../../../icons/editar.svg";
import { eliminarEquipo } from "../../../functions/funciones";

const Equipos = () => {
  const user = useContext(UsuarioContext)
  const [equipos, setEquipos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(true);
  const [show, setShow] = useState(false);
  const [limite, setLimite] = useState(50);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData()
  }, [recargar]);

  const fetchData = async ({offset, limit, filtros}) => {
    setLimite(limit)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };
    try {
      setLoading(true);
      const response = await getEquipos(payload);
      const { meta, data } = response;
      console.log(data)
      setEquipos(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const formatData = (data) => {
    return data.map((row, i) => ({
      id: row.id,
      equipo: row.nombre,
      numTec: (row.technician?.length || 0)?.toString()
    }));
  };

  const equiposRows = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    {
      id: "equipo",
      numeric: false,
      disablePadding: false,
      label: "EQUIPO",
    },
    {
      id: "numTec",
      numeric: false,
      disablePadding: false,
      label: "Nº TÉCNICOS",
    },
    user.rol === 'ADMIN' ?
    {
      id: "eliminar",
      numeric: false,
      disablePadding: false,
      label: "",
      style: { width: "fit-content", textAlign: "right" },
      overrideFunc: (data, row) => (
        <>
          <Link to = {`/equipos/editar/${row.id}`}>
            <img
              src={Editar}
              alt="EDITAR"
              className="tableIcon"
            ></img>
          </Link>
          <img
            src={Delete}
            alt="ELIMINAR"
            className="tableIcon"
            onClick={() => eliminarEquipo(row)}
          ></img>
        </>
      )
    }
    : '',
  ]

  const eliminarEquipo = (com) => {
    if(parseInt(com.numTec) > 0){
      toast.error("El equipo no se puede borrar, todavía tiene tecnicos asignados");
    }else{
      Swal.fire({
        title: "¿Quieres borrar este equipo?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, borrar",
      })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("equipo "+com.id)
            deleteEquipo(com.id)
              .then((res) => {
                
                if (res.message === "TEAM_DELETED") {
                  toast.success("Equipo eliminado");
                  setRecargar(!recargar);
                  
                } else {
                  toast.error("No se ha podido elimiar el equipo");
                }
              })
              .catch((e) => {
                toast.error("No se ha podido elimiar el equipo");
                console.log(e);
              });
          }
        })
        .catch((e) => {
          toast.error("Ha ocurrido un error");
          console.log(e);
        });
    }
  };


  return (
    <>
      <BreadCrumb rutas={[{path:`/tecnicos`, label:`Técnicos`}, {path:`/equipos`, label:`Equipos`}]} />
      <div className="listPage">
        <div className="gridCard">
            <div className="gridHead">
                EQUIPOS
            </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div></div>
          {(user.rol === "ADMIN" || user.rol === "CONSUL") && 
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
              <Link to="/equipos/nuevo-equipo">
                <div className="contentButton mx-2">
                  <img src={Add} alt="Nuevo Equipo" />              
                </div>
              </Link>
            </div>
          }
        </div>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={equiposRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                // filtros={busqueda}
                recargar={recargar}
                mensaje={"No hay equipos"}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Equipos;
