import { Box, MenuItem, Select, TextField, Typography, Tooltip} from "@mui/material";
import React, { useState, useContext } from "react";
import { ventas } from "../../../functions/ventasPrueba";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import { useVentas } from "../../../hooks";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DataTable, LaeButton, NoVisitado } from "../../../components";
import { Iconify, convertirAPdf } from "../../../utilities";
import moment from "moment";
import Add from "../../../icons/add.svg";
import { generarPDF, getSales, getFinca } from "../../../services";
import { toast } from "react-toastify";
import FiltrosVentas from "../../../components/filtros/filtrosVentas";
import { formatoFechasEs } from "../../../functions/funciones";
import { UsuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { FaPencilAlt, FaPlus  } from "react-icons/fa";

import { TbCertificate } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";

export default function ComunidadDocs(props) {
  const location = useLocation()
  const user = useContext(UsuarioContext);

  const comunidadesRows = [
    {
      id: "color",
      numeric: false,
      disablePadding: true,
      label: "",
      overrideFunc: (row) => (
        <Typography
          className="servicios"
          style={{ padding: 0, background: row }}
        ></Typography>
      ),
    },
    {
      id: "ano",
      numeric: false,
      disablePadding: false,
      label: "AÑO",
    },
    {
      id: "servicio",
      numeric: false,
      disablePadding: false,
      label: "SERVICIO",
    },
    location.pathname === "/servicios" ?
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    }: '',
    location.pathname === "/servicios" ?
    {
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={user.rol === "AAFF" ?  "/comunidades/"+ row.finca.nif  : "/aaff/" + row.nif_aaff + "/comunidades/"+ row.finca.nif} >{data}</Link>
      )
    }: '',
    location.pathname === "/servicios" ?
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    }: '',
    user.rol !== 'AAFF' ?
    {
      id: "fechaVenta",
      numeric: false,
      disablePadding: false,
      label: "FECHA VENTA",
      overrideFunc: (data) => formatoFechasEs(data),
    }: '',
    {
      id: "documentos",
      numeric: false,
      disablePadding: false,
      label: "DOCUMENTOS",
      colSpan: 4,
      overrideFunc: (row) => (
        <Typography style={{display: 'flex', gap: 5, color: '#fff'}}>
        {(row.serviceprops.id === 1 || row.serviceprops.id === 3) && (
          <>
              {renderDocs("Hoja visita", <HiClipboardDocumentList />, "hoja-visita", convertirAPdf, row, "cae")}
              {renderDocs("Identificación de riesgos", <MdHealthAndSafety />, "identificacion-riesgos", convertirAPdf, row, "cae")}
              {renderDocs("Plan emergencia", <IoIosWarning />, "plan-emergencia", convertirAPdf, row, "cae")}
          </>
        )}
        {(row.serviceprops.id === 2 || row.serviceprops.id === 4) && (
          <>
          {/* <td style={{ width: "25%", paddingRight: '23px' }}>
            {renderDocs('Lopd', 'lopd', convertirAPdf, row, "lopd")}            
          </td> */}
            {renderDocs("Certificado", <TbCertificate  />, row.serviceprops.id === 4 ? 'certificado-auditoria' : 'certificado-implantacion', convertirAPdf, row, "lopd")}
            
            {renderDocs("Implantación", <TiDocumentText />, 'documentacion-implantacion', convertirAPdf, row, "lopd")}

            {row.serviceprops.id === 4 ? 
              renderDocs("Auditoría", <TiDocumentText />, 'documentacion-auditoria', convertirAPdf, row, "lopd")
            : null }
            {row.detailsLopd[0]?.surveillanceVideo ? renderDocs("Video vigilancia", <IoVideocam />, "video-vigilancia", convertirAPdf, row, "lopd") : ''}
          </>
        )}
      </Typography>
      ),
    },
    user.rol !== 'AAFF' ?
    {
      id: "noVisita",
      numeric: false,
      disablePadding: false,
      label: "No visitada",
      overrideFunc: (row) => (
        <Typography>
            <NoVisitado finca={row} nif={row.properties.nif} tipo={"finca"} />
        </Typography>
      ),
    }:'',
    user.rol !== 'AAFF' ?
    {
      id: "fechaRealizada",
      numeric: false,
      disablePadding: false,
      label: "FECHA REALIZADA",
      overrideFunc: (row) => (
        <Typography>
          {!row.detailsCae[0]?.unRealized && row.detailsCae[0]?.saleDate !== null && row.detailsCae[0]?.saleDate !== undefined ? 
            formatoFechasEs(row.detailsCae[0]?.saleDate)
          : null}
        </Typography>
      ),
    }:'',

    
  ].filter(Boolean);

  const realizarVisita = {
    id: "hacerVisita",
    numeric: false,
    disablePadding: false,
    label: "Realizar Visita",
    overrideFunc: (row) => (
      <Typography>
        {row.detailsCae[0]?.visitada ? 
          row.workcenters === null ?
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/edit/${row.id}`} target={"_blank"} >
              <FaPencilAlt/>
            </Link>
            :
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/${row.workcenters.id}/edit/${row.id}`} target={"_blank"} >
              <FaPencilAlt/>
            </Link>
        : 
          row.workcenters === null ?
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/create/${row.id}`} target={"_blank"} >
              <FaPlus/>
            </Link>
          :
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/${row.workcenters.id}/create/${row.id}`} target={"_blank"} >
              <FaPlus/>
            </Link>
        }
        
      </Typography>
    ),
  }

  if(user.rol !== 'COMM' && user.rol !== 'AAFF'){
    comunidadesRows.push(realizarVisita)
  }
  
  const [listaVentas, setListaVentas] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(50);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    console.log(props)
    fetchData();
  }, [recargar]);
  
  const renderDocs = (nombre, icono, enlace, convertirAPdf, row, tipo) => {
    
    let documento;
    let url;
    if(tipo === "lopd"){
      documento = row.detailsLopd?.find(obj => obj.sale_id === row.id);
    }
    if(tipo === 'cae'){
      documento = row.detailsCae?.find(obj => obj.sale_id === row.id);
    }
    if (!documento) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', fontSize: 16}}>
          <Tooltip title={nombre} style={{ fontSize: '16px'}}>
            <small
              className="docsStatus" 
              style={{ background: '#FF5252', height: 'auto' }}
            >
              {icono}
            </small>
        </Tooltip>
        </div>
      );
    }
  
    const { pendiente, visitada } = documento;

    if (tipo === "lopd" || (tipo === 'cae' && (pendiente || visitada))) {
      if (row.workcenters === null) {
        url = `/doc-render/${row.properties?.nif}/${row.id}/${enlace}`
      } else {
        url = `/doc-render/${row.properties?.nif}/${row.workcenters.id}/${row.id}/${enlace}`
      }
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
        <Tooltip title={nombre} style={{ fontSize: '16px'}}>
        {!pendiente && tipo !== 'lopd' && !visitada? 
          <small
            className="docsStatus" 
            style={
              { background: '#FF5252'}
            }
          >
            {icono}
          </small>
        :
          <Link to={url} target={"_blank"}>
            <small
                className="docsStatus" 
                style={
                  tipo === 'lopd' || visitada ?
                    { background: '#7bc977' }
                  : pendiente ?
                    { background: '#FCBA03' }
                  : { background: '#FF5252' }
                }
            >
              {icono}
            </small>
          </Link>
        }
        </Tooltip>
      </div>
    );
  };

  const convertirAPdf = async ({ nombre, nif, workcenter_id, sale_id }) => {
    try {
      console.log(nombre, nif, workcenter_id, sale_id);
      const toastInfo = toast.info("Abriendo archivo");
    //   const response = await generarPDF(`http://localhost:3001/doc-render/${nif}/${nombre}`);
        const datos = {
          url: workcenter_id !== null ? `http://localhost:3001/doc-render/${nif}/${workcenter_id}/${sale_id}/${nombre}` : `http://localhost:3001/doc-render/${nif}/${sale_id}/${nombre}`,    
          nombre: nombre,
          nif: nif
        }
        await generarPDF(datos).then( res => {
        console.log(res)
        const url = URL.createObjectURL(res.data);
        console.log(url)
        // Abre el PDF en una nueva ventana o pestaña
        const newWindow = window.open('', '_blank');
        const pdfWindow = newWindow;
        pdfWindow.document.write('Cargando PDF...');

        // Promesa para cargar la URL
        const loadURL = new Promise((resolve, reject) => {
          const iframe = document.createElement('iframe');
          iframe.src = url;
          iframe.style.display = 'none';

          iframe.onload = () => {
            resolve();
          };
          
          iframe.onerror = (error) => {
            reject(error);
          };

          document.body.appendChild(iframe);
        });

        loadURL
          .then(() => {
            // Espera a que el evento "load" se dispare en el iframe antes de abrir la ventana emergente
            pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco
            pdfWindow.document.write('Cargando PDF...');
            setTimeout(() => {
              pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco nuevamente
              pdfWindow.location.href = url; // Abrir la URL del PDF
            }, 1000); // Espera 1 segundo (ajusta según sea necesario)
          })
          .catch((error) => {
            console.error('Error al cargar la URL:', error);
            pdfWindow.document.write('Error al cargar la URL');
          });
      })
    //   const blob = new Blob([response.data], { type: 'application/pdf' });
      
    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(offset, limit, filtros);
    setLimite(limit);
    let usuario = {}
    let finca = {}
    if(user.rol === "COMM"){
      usuario = {commer_id: user.id}
    } 
    if(user.rol === "AAFF"){
      usuario = {aaff_id: user.id}
    } 
    const payload = {
      // aaff_id: 7,
      filtros:{...filtros, ...usuario},
      params: { page: offset, limit },
    };
    console.log(payload)

    try {
      setLoading(true);
      if (props.nifFinca === undefined) {
        const response = await getSales(payload)
        const { meta, data } = response;
        console.log(data)
        setListaVentas(data);
        setData({
          meta,
          rows: formatData(data),
        });
      }else{
        // const data = ventas.filter(v => v.comunidad.nif === props.nifFinca)
        console.log(props.nifFinca)
        const finca = await getFinca(props.nifFinca)
        console.log('finca.id')
        console.log(finca)
        let grupoServ_id = null
        if(location.pathname !== '/servicios'){
          grupoServ_id = props.grupoServicio
        }
        const payload = {
          filtros:{prop_id:finca.id, grupoServ_id},
          params: { page: offset, limit },
        };

        const response = await getSales(payload)
        const { meta, data } = response;
        console.log(data)
        setListaVentas(data)
        setData({
          meta,
          rows: formatData(data),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      // color: row.color,
      ano: new Date(row.saleDate).getFullYear(),
      servicio: row.serviceprops.nombre,
      nif: row.properties.nif,
      nif_aaff: row.aaff.nif,
      finca: row.properties,
      comunidad: row.workcenters === null ? row.properties.razonSocial : row.workcenters.razonSocial,
      codigoPostal: row.properties.codigoPostal,
      fechaVenta: row.createdAt,
      documentos: row,
      noVisita: row,
      fechaRealizada: row,
      hacerVisita: user.rol !== 'COMM' && user.rol !== 'AAFF' ? row: null
    }));
  };
  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setBusqueda({
  //     ...busqueda,
  //     [evt.target.name]: value,
  //   });
  // }

  // const getBusqueda = () => {
  //   console.log(busqueda);
  //   for (let key in busqueda) {
  //     if (
  //       busqueda[key] === "" ||
  //       busqueda[key] === null ||
  //       busqueda[key] === undefined
  //     ) {
  //       delete busqueda[key];
  //     }
  //   }
  //   fetchData({ offset: 1, limit: limite, filtros: busqueda });
  // };

  const filtrarDatos = (filtros) => {
    console.log(filtros)
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 

  return (
    <>
      {props.nifFinca === undefined && (
        <BreadCrumb rutas={[{path:`/servicios`, label:`Servicios`}]} style={{ margin: '0 10px'}} />
      )}
      <div className="pageContent tableContainer" style={{ margin:0 }}>

        {props.nifFinca === undefined && (
          <>
          {user.rol !== "ADMIN" && user.rol !== "CONSUL" ? null :
            <div className="contentBar" style={{padding: '0 10px'}}>
              <div className="contentBtns"></div>
              <Link to="/servicios/create" target={"_blank"}>
                <div className="contentButton">
                    <img src={Add} alt="Más" />
                </div>
              </Link>
            </div>
          }

            <FiltrosVentas onFilter={filtrarDatos} />
          </>
        )}

        <div className="tableWrapper">
          <Box className="pageContent" style={{ margin:0 }}>
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay ventas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .tableContainer table tbody th.docs {
          text-align: center;
        }
        .tableContainer table tbody td {
          padding: 0;
        }
      `}</style>
    </>
  );
}
