import { useState } from "react";
import { getServices } from "../services";
/**
 * Formatea los datos de los servicios (opcional)
 * @param {Array} data
 * @returns {Array}
 */

const formatData = (data) => {
    return data.map((row) => ({
        id: row.id,
        service_name: row.nombre
    }));
};
/**
 * Custom Hook para obtener los servicios
 * @returns {any}
 */
const useServiceToSale = () => {
    const [servicios, setServicios] = useState({
        meta: {},
        rows: [],
      });
    const [loading, setLoading] = useState(true);
    const fetchData = async (payload) => {
        try {
            setLoading(true);
            const { data } = await getServices();
            setServicios({
                meta:"",
                rows:formatData(data)
            });
        } catch (error) {
            console.log(error);
        }finally {
            setLoading(false);
        }
    }
    return {
        fetchData,
        formatData,
        servicios,
    };
};

export default useServiceToSale;