import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Button, CircularProgress, Grid } from "@mui/material";
import { createCommercialCombo, getAllCommercials } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { filterItems } from "../../../functions/funciones";
import { BsSearch } from "react-icons/bs";

const NuevoComercialCombo = () => {
  const [commercials, setCommercials] = useState([]);

  const [busqueda, setBusqueda] = useState([]);
  const [resultados, setResultados] = useState();

  const [comercial, setComercial] = useState({});
  const [selected, setSelected] = useState([])
  

  useEffect(() => {
    getAllCommercials({onlyPrimary: true}).then((res) => {
      const comerciales = [];
      res.forEach((c) => {
        comerciales.push({
          id: c.id,
          nif: c.nif,
          nombre: c.nombre,
          apellidos: c.apellidos,
          busqueda: c.nombre + " " + c.apellidos,
          userId: c.user[0]?.id
        });
      });
      setResultados(comerciales);
      setCommercials(comerciales);
    });
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const toastInfo = toast.info("Creando comercial");
    if (selected.length == 0) {
      return toast.update(toastInfo, {
        render: "No se han añadido comerciales",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
    }
    createCommercialCombo({...comercial, userIds: selected.map(s => s.userId)})
      .then((res) => {
        if (res.status == 201) {
          toast.update(toastInfo, {
            render: "Comercial creado con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });

          window.location.replace("/comerciales");
        }else if (res.status == 403) {
          toast.update(toastInfo, {
            render: "Error al crear el comercial",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        } else {
          toast.update(toastInfo, {
            render: "Ha ocurrido un error",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
       
        
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al crear el comercial");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setComercial({
      ...comercial,
      [evt.target.name]: value,
    });
  }


  const buscar = (e) => {
    // e.preventDefault();
    
    setResultados(filterItems(busqueda, commercials.filter(c => !selected.some(s => s.id === c.id))))
  };
  useEffect(buscar, [busqueda]);
  const select = (comercial) => {
    setSelected([...selected, comercial]);
    setResultados(resultados.filter(c => c.id !== comercial.id));
  }
  
  const unselect = (comercial) => {
    const newSelected = selected.filter(c => c.id !== comercial.id)
    setSelected(newSelected);
    setResultados(commercials.filter(c => !newSelected.some(s => s.id === c.id)));
  }
  

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comerciales / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{path:`/comerciales`, label:`Comerciales`}, {path:`/comerciales`, label:`Nuevo Combo`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridHead">Crear nuevo Comercial Combo</div>
        <form className="nuevoAAFFForm" onSubmit={handleSubmit}>
          <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={comercial.nombre}
                onChange={handleChange}
              ></input>
              
                <div className="tableContainer" style={{ marginTop: 30, marginBottom: 30, padding: 0 }}>
            <table>
              <thead>
                <tr>
                  <th>Comercial</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {selected.length == 0 ? <tr><td colSpan={3} style={{ textAlign: "center" }}>Selecciona comerciales para añadirlos al combo</td></tr> : selected.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}>
                        <div
                          className="asignarButton"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => unselect(e)}
                        >
                          Eliminar
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <input
            type="submit"
            value="Guardar y crear"
          ></input>
        </form>
        <form className="nuevoAAFFForm" onSubmit={e => e.preventDefault()}>
              <input
                  type="text"
                  placeholder="Buscar comercial..."
                  required
                  value={busqueda}
                  onChange={(e) => setBusqueda(e.target.value)}
                ></input>
                </form>
          <div className="tableContainer" style={{ marginTop: 10 }}>
            <table>
              <thead>
                <tr>
                  <th>Comercial</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Añadir</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(resultados) ? resultados.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}>
                        <div
                          className="asignarButton"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => select(e)}
                        >
                          Añadir
                        </div>
                      </td>
                    </tr>
                  );
                }) : 
                <tr><td colSpan={3} style={{ textAlign: "center" }}><CircularProgress style={{color:'#92004D'}} /></td></tr>
                }
              </tbody>
            </table>
          </div>
          
      </div>
    </>
  );
};

export default NuevoComercialCombo;
