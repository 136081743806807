import React, {useState, useEffect} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";


const ListadoFincas = (props) => {
    const listado = props.fincas
    const [fincas, setFincas] = useState([])   
    const [numFincas, setNumFincas] = useState(0)   

    
    useEffect(() => {
        console.log(listado)
        let num = 0
        listado.forEach( l => {
            num += l?.workcenters?.length >0 ? l?.workcenters?.length : 1
        })
        console.log(num)
        setNumFincas(num)

        let f = [];
        props.fincas?.forEach(e => {
            let data = {
            razonSocial:e.razonSocial,
            nif: e.nif,
            /* activo: e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva",
            servicios: e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE",
            empleados: e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No" */
            }
            if(e.workcenters.length > 0 ){
                console.log("TIENE")
                e.workcenters.map( ct => {
                    let data = {
                        razonSocial:ct.razonSocial,
                        nif: e.nif
                    }
                    f.push(data)
                })
            }else{
                console.log("NO TIENE")
                f.push(data)
            }
            setFincas(f)
        })
        
    },[props]) 
    return ( 
        <>
        {console.log(fincas.length, numFincas)}
            {fincas.length === numFincas ?
                <ExportToExcel apiData={fincas} fileName={"Listado de fincas "+props.aaffNif} />
            :
                <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
            }
        </>
     );
}
 
export default withRouter(ListadoFincas);