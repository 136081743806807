import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import {Fade, Box, Typography, CircularProgress} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import { editarAAFF } from '../../../services'; 
import { formatoFechas, municipiosIniciales, cambiarFormatoNombre } from './../../../functions/funciones'
import provinces from '@doncicuto/es-provinces';
import municipalities from '@doncicuto/es-municipalities';
import { ordenarProvinciasYMunicipios } from '../../../functions/ordenarListas';
import { createComment } from '../../../services/comment.service';

/* const useStyles = styled ((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
})); */
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85ch',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };

 const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const paper= {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    padding: '16px 32px 24px'
  };
  const form= {
      
    margin: '8px',
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  };
export default function TransitionsModal(props) {
    let fechaEncargo = props.aaff.fechaEncargo === null ? null :  formatoFechas(props.aaff.fechaEncargo)
    
    let fechaBienvenida = props.aaff.fechaBienvenida === null ? null : formatoFechas(props.aaff.fechaBienvenida)
    
    let fechaReclamacion = props.aaff.fechaReclamacion === null ? null : formatoFechas(props.aaff.fechaReclamacion)
    
    let fechaInforme = props.aaff.fechaInforme === null ? null : formatoFechas(props.aaff.fechaInforme)
    
  //const classes = useStyles();

  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [activo, setActivo] = useState(false)
  
  useEffect(()=>{
    
    const aaffData = {
      id:props.aaff.id,
      razonSocial: props.aaff.razonSocial,
      email: props.aaff.user?.email,
      nif: props.aaff.nif,
      nombreContacto: props.aaff.nombreContacto,
      telefono: props.aaff.telefono,
      municipio: props.aaff.municipio,
      provincia: props.aaff.provincia,
      fechaEncargo: fechaEncargo,
      fechaBienvenida: fechaBienvenida,
      fechaReclamacion: fechaReclamacion,
      fechaInforme: fechaInforme,
      // comentarios: props.aaff.comentarios
    }
    setEditarDatos(aaffData)
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.aaff.provincia))
  },[props])

  function handleChange(evt) {
      const value = evt.target.value;
      console.log(value)
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }

  
  const editar = (aaff) => {
    setActivo(true)
    console.log(aaff)
    /* if(aaff.fechaEncargo !== "NaN-NaN-NaN" || aaff.fechaEncargo !== null){ d = aaff.fechaEncargo}else{ d = null}
    if(aaff.fechaBienvenida !== "NaN-NaN-NaN" || aaff.fechaBienvenida !== null){ d1 = aaff.fechaBienvenida}else{ d1 = null}
    if(aaff.fechaReclamacion !== "NaN-NaN-NaN" || aaff.fechaReclamacion !== null){ d2 = aaff.fechaReclamacion}else{ d2 = null}
    if(aaff.fechaInforme !== "NaN-NaN-NaN" || aaff.fechaInforme !== null){ d3 = aaff.fechaInforme}else{ d3 = null} */
    // let c= "";
    // if(aaff.comentarios === undefined || aaff.comentarios === null || aaff.comentarios === ''){c= "-"}else{ c= aaff.comentarios}
    let cont = "";
    if(aaff.nombreContacto === undefined || aaff.nombreContacto === null || aaff.nombreContacto === ''){cont= "-"}else{ cont= aaff.nombreContacto}
    let tel = "";
    if(aaff.telefono === undefined || aaff.telefono === null || aaff.telefono === ''){tel= "-"}else{ tel= aaff.telefono}

    let data = {
        razonSocial: aaff.razonSocial,
        nif: aaff.nif,
        email: aaff.email,
        nombreContacto: cont,
        telefono: tel,
        municipio: aaff.municipio,
        provincia: aaff.provincia,
        commer_id: aaff.commer_id,
        fechaEncargo: fechaEncargo,
        fechaBienvenida: fechaBienvenida,
        fechaReclamacion: fechaReclamacion,
        fechaInforme: fechaInforme,
        //comentarios: c
    }

    let toastInfo = toast.info("Guardando datos", { autoClose: false });
    editarAAFF(data).then( res => {
      if(res.status>=200 && res.status<300){
          toast.update(toastInfo, {
            render: "Datos editados con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true
          });
          props.handleClose()
          setActivo(false)
      }else{
          let mensajes = []
          
          res.data.error.map(err =>{
              mensajes = err
              console.log(err.message)
              
              toast.update(toastInfo, {
                render: err.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
          })
          setActivo(false)
      }
      // let infoComentario = {
      //   prop_id:null,
      //   supp_id:null,
      //   aaff_id:aaff.id,
      //   // texto: c,
      //   tipo: "comment"
      // }
      // createComment(infoComentario).then(res => {
      //   console.log(res)
      //   setActivo(false)
      // })
    }).catch(err => {
      console.log(err)
      toast.update(toastInfo, {
        render: "Error al editar los datos",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      setActivo(false)
    })
  }

  const seleccionarMunicipios = (e) => {
    let codProvincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.name === e.target.value){
        codProvincia = p.code
      }
    })
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value
    });   
    municipalities.map( m => {
      if(m.provCode === codProvincia){
        municipios.push({...m, name: cambiarFormatoNombre(m.name), value: m.name})
      }
    })
    setListadoMunicipios(municipios)
  }
  return (
    <div>
       

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div style={{backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
            padding: '16px 32px 24px'}}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div style={{margin: '8px',
                  width: '100ch',
                  position: 'relative',
                  display: 'grid',
                  gridTemplateColumns: ' repeat(2, 1fr)',
                  gridGap: '15px',
                  fontSize: '12px',}}
                >
                  {/* {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    console.log(e.name)
                    return (
                      <span>{e.name}</span>
                    );
                  })} */}
                  <TextField variant="standard" id="razonSocial" name="razonSocial" label="Razón Social" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField variant="standard" id="email" name="email" label="Email" value={editarDatos.email} onChange={handleChange}/>
                  <TextField variant="standard" id="nombreContacto" name="nombreContacto" label="Contacto" minLength="3" value={editarDatos.nombreContacto} onChange={handleChange} />
                  <TextField variant="standard" id="telefono" name="telefono" label="Telefono" value={editarDatos.telefono} onChange={handleChange} />
                  <Select variant="standard" native name="provincia" id="provincia" style={{ textTransform: 'uppercase'}} onChange={seleccionarMunicipios} defaultValue={editarDatos?.provincia}>
                  <option value=""></option>
                  {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                  {/* <option value="A CORUÑA">A CORUÑA</option>
                  <option value="ÁLAVA/ARABA">ÁLAVA/ARABA</option>
                  <option value="ALBACETE">ALBACETE</option>
                  <option value="ALICANTE">ALICANTE</option>
                  <option value="ALMERÍA">ALMERÍA</option>
                  <option value="ASTURIAS">ASTURIAS</option>
                  <option value="ÁVILA">ÁVILA</option>
                  <option value="BADAJOZ">BADAJOZ</option>
                  <option value="BALEARES">BALEARES</option>
                  <option value="BARCELONA">BARCELONA</option>
                  <option value="BIZKAIA">BIZKAIA</option>
                  <option value="BURGOS">BURGOS</option>
                  <option value="CÁCERES">CÁCERES</option>
                  <option value="CÁDIZ">CÁDIZ</option>
                  <option value="CANTABRIA">CANTABRIA</option>
                  <option value="CASTELLÓN">CASTELLÓN</option>
                  <option value="CEUTA">CEUTA</option>
                  <option value="CIUDAD REAL">CIUDAD REAL</option>
                  <option value="CÓRDOBA">CÓRDOBA</option>
                  <option value="CUENCA">CUENCA</option>
                  <option value="GIRONA">GIRONA</option>
                  <option value="GRANADA">GRANADA</option>
                  <option value="GUADALAJARA">GUADALAJARA</option>
                  <option value="GIPUZKOA">GIPUZKOA</option>
                  <option value="HUELVA">HUELVA</option>
                  <option value="HUESCA">HUESCA</option>
                  <option value="JAÉN">JAÉN</option>
                  <option value="LA RIOJA">LA RIOJA</option>
                  <option value="LAS PALMAS">LAS PALMAS</option>
                  <option value="LEÓN">LEÓN</option>
                  <option value="LLEIDA">LLEIDA</option>
                  <option value="LUGO">LUGO</option>
                  <option value="MADRID">MADRID</option>
                  <option value="MÁLAGA">MÁLAGA</option>
                  <option value="MELILLA">MELILLA</option>
                  <option value="MURCIA">MURCIA</option>
                  <option value="NAVARRA">NAVARRA</option>
                  <option value="OURENSE">OURENSE</option>
                  <option value="PALENCIA">PALENCIA</option>
                  <option value="PONTEVEDRA">PONTEVEDRA</option>
                  <option value="SALAMANCA">SALAMANCA</option>
                  <option value="SEGOVIA">SEGOVIA</option>
                  <option value="SEVILLA">SEVILLA</option>
                  <option value="SORIA">SORIA</option>
                  <option value="TARRAGONA">TARRAGONA</option>
                  <option value="TENERIFE">TENERIFE</option>
                  <option value="TERUEL">TERUEL</option>
                  <option value="TOLEDO">TOLEDO</option>
                  <option value="VALENCIA">VALENCIA</option>
                  <option value="VALLADOLID">VALLADOLID</option>
                  <option value="ZAMORA">ZAMORA</option>
                  <option value="ZARAGOZA">ZARAGOZA</option> */}
                  </Select>
                  {/* <TextField variant="standard" id="municipio" name="municipio" label="Municipio" value={editarDatos.municipio} onChange={handleChange} /> */}
                  <Select variant="standard" native name="municipio" id="municipio" style={{ textTransform: 'uppercase'}} onChange={handleChange} defaultValue={editarDatos?.municipio}>
                  <option value=""></option>
                  {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.value}>
                        {cambiarFormatoNombre(e.name)}
                      </option>
                    );
                  })}
                  </Select>
                  <TextField variant="standard" id="fechaEncargo" type="date" name="fechaEncargo" InputLabelProps={{ shrink: true }}  label="Fecha Encargo" defaultValue={editarDatos.fechaEncargo} onChange={handleChange} />
                  <TextField variant="standard" id="fechaBienvenida" type="date" name="fechaBienvenida" InputLabelProps={{ shrink: true }}  label="Fecha Bienvenida" defaultValue={editarDatos.fechaBienvenida} onChange={handleChange} />
                  <TextField variant="standard" id="fechaReclamacion" type="date" name="fechaReclamacion" InputLabelProps={{ shrink: true }}  label="Fecha Reclamacion" defaultValue={editarDatos.fechaReclamacion} onChange={handleChange} />
                  <TextField variant="standard" id="fechaInforme" type="date" name="fechaInforme" InputLabelProps={{ shrink: true }}  label="Fecha Informe" defaultValue={editarDatos.fechaInforme} onChange={handleChange} />
                  <div></div>
                </div>
                {/* <div>
                  <TextField style={{display:'flex'}} id="comentarios" name="comentarios" label="Comentarios" value={editarDatos.comentarios} onChange={handleChange} multiline rows={6} columns={100}/>
                </div> */}
                <div style={{margin: '8px',
                  width: '100ch',
                  position: 'relative',
                  display: 'grid',
                  gridTemplateColumns: ' repeat(2, 1fr)',
                  gridGap: '15px',
                  fontSize: '12px',}}
                >
                  <Button className="btn-Default" variant="contained" onClick={props.handleClose} disabled={activo} style={{opacity: activo ? .6 : 1}}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)} disabled={activo} style={{opacity: activo ? .6 : 1}}>{activo ? <CircularProgress style={{ height: 20, width: 20, color:'#fff'}} /> : "Editar"}</Button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}