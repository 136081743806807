import React, { useState, useEffect, useContext } from "react";
import {
  LinearProgress,
  styled,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import ReactPaginate from "react-paginate";
import { getAAFF, getFincasAAFF } from "../../../services";
import { DataTable, LaeButton } from "../../../components";
import { Iconify } from "../../../utilities";
import { useComunidades } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import FiltrosComunidadesTech from '../../../components/filtros/filtrosComunidadesTech';

const comunidadesRows = [
  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR DE FINCAS",
  },
  {
    id: "community",
    numeric: false,
    disablePadding: false,
    label: "COMUNIDAD",
    overrideFunc: (data, row) => (
      <Link to= {`/comunidades/${row.nif}`}>
        {data}
      </Link>
    )
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  /* {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "FECHA ALTA",
  }, */
  {
    id: "direction",
    numeric: false,
    disablePadding: false,
    label: "DIRECCION",
  },
  {
    id: "cp",
    numeric: false,
    disablePadding: false,
    label: "C.P",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  /*  {
    id: "servicios",
    numeric: false,
    disablePadding: false,
    label: "SERVICIOS",
  },
  {
    id: "empleados",
    numeric: false,
    disablePadding: false,
    label: "EMPLEADOS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
  },
  {
    id: "location2",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE EMERGENCIA",
  },
  {
    id: "location3",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE PREVENCIÓN",
  },
  {
    id: "hojasVisita",
    numeric: false,
    disablePadding: false,
    label: "HOJA DE VISITA",
    overrideFunc: (row) => (
      <Box className="lae-flex lae-flex-row">
        <Iconify
          icon="solar:document-line-duotone"
          sx={{ height: 24, width: 24 }}
        />
        <Iconify
          icon="solar:document-add-bold-duotone"
          sx={{ height: 24, width: 24 }}
        />
      </Box>
    ),
  },
  {
    id: "visitada",
    numeric: false,
    disablePadding: false,
    label: "NO VISITADA",
    overrideFunc: (row) => (
      <>{!row && <Iconify icon="mdi:close" sx={{ height: 24, width: 24 }} />}</>
    ),
  }, */
];

const TabFilters = [
  /* { value: "all", label: "TODOS" }, */
  {
    value: "unvisited",
    label: "NO VISITADAS",
  },
  {
    value: "visited",
    label: "VISITADAS",
  },
  {
    value: "refreshed_unvisited",
    label: "RENOVADAS NO VISITADAS",
  },
  {
    value: "refreshed_visited",
    label: "RENOVADAS VISITADAS",
  },
  /*   {
    value: '',
    label: '',
  } */
];

const listaFiltros = [
  { value: "all", label: "TODOS" },
  { value: "comunidades", label: "Comunidades" },
  { value: "aaff", label: "Administrador de Fincas" },
];

const Comunidades = () => {
  const user = useContext(UsuarioContext);
  const { loading, data, fetchData, limite } = useComunidades();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(0);

  const handleOpenNewTab = ({ nif }) => {
    if (!nif) return;
    window.open(`comunidades/${nif}`, "_self");
  };

  const handleFetchData = async ({ offset, limit }) => {
    try {
      await fetchData({ offset, limit, filtros: busqueda });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    console.log(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Comunidades
        </Typography>
      </div> */}
      <BreadCrumb
        rutas={[{ path: "/comunidades", label: "Comunidades" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
          </div>
          <div className="pageContent tableContainer">
            <FiltrosComunidadesTech onFilter={filtrarDatos} />
            <Box>
              <Box sx={{ mb: 10 }}>
                <DataTable
                  data={data?.rows}
                  meta={data?.meta}
                  isLoading={loading}
                  headers={comunidadesRows}
                  tabsFilters={TabFilters}
                  onClickRow={handleOpenNewTab}
                  fetcher={handleFetchData}
                  currentPage={data.currentPage}
                  mensaje={"No hay fincas"}
                >
                  {/* <Box
                    m={2}
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    gap={2}
                  >
                    <Select
                      value={filter}
                      aria-label="controlled"
                      onChange={(e) => setFilter(e.target.value)}
                      sx={{ width: 150 }}
                    >
                      {listaFiltros.map((filtro) => (
                        <MenuItem value={filtro.value} key={filtro.value}>
                          {filtro.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Razón Social"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                    <LaeButton onClick={handleFetchData} sx={{ width: 100 }}>
                      <Typography>Buscar</Typography>
                    </LaeButton>
                  </Box> */}
                </DataTable>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
