import React, {useState, useEffect} from 'react';
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { getAAFF, deleteFinca, deleteCentroTrabajo, renovarFinca} from '../../../services';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CircularProgress, Tabs, Tab} from "@mui/material";
// import { alpha, styled } from "@mui/material/styles";
// import Switch from "@mui/material/Switch";
import { formatoFechasEs } from "../../../functions/funciones";
import Delete from "../../../icons/delete.svg";
// import Tick from "../../../icons/tick.svg";
import Change from "../../../icons/change.svg";
import Add from "../../../icons/addBlack.svg";
// import RenovarComunidades from "../../../icons/renovarComunidades.svg";
import Excel from "../../../icons/excel.svg";
import ReactPaginate from "react-paginate";
import ExportarListaFincasAAFF from "./ExportarListaFincasAAFF";
import { DialogoCambioAAFF } from "../../../providers/Dialogos";
// import {
//     Grid,
//     Select,
//     FormControl,
//     TextField,
//     Collapse,
//     Button,
//     Dialog,
//     DialogActions,
//     DialogContent,
//     DialogContentText,
//     DialogTitle,
//   } from "@mui/material";

// const CustomSwitch = styled(Switch)(({ theme }) => ({
//     "& .MuiSwitch-switchBase": {
//       color: "#FF5252",
//       "&:hover": {
//         backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
//       },
//     },
//     "& .MuiSwitch-switchBase + .MuiSwitch-track": {
//       backgroundColor: "#FF5252",
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       color: "#7bc977",
//       backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
//       "&:hover": {
//         backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
//       },
//     },
//     "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: "#7bc977",
//     },
// }));
  
// const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
//     "& .MuiSwitch-switchBase": {
//       color: "#FFD700",
//     },
//     "& .MuiSwitch-switchBase + .MuiSwitch-track": {
//       backgroundColor: "#FFD700",
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       color: "#FFD700",
//       backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
//     },
//     "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//       backgroundColor: "#FFD700",
//     },
// }));

const ServicioCae = (props) => {

    const perPage = 30;
    const [offset, setOffset] = useState(0);
    const [selectedPage, setCurrentPage] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [isLoading, setIsloading] = useState(true);
    const [recargar, setRecargar] = useState(0);
    const [aaff, setAAFF] = useState({});
    const [fincas, setFincas] = useState([]);
    // const [fincasSeleccionadas, setFincasSeleccionadas] = useState([]);
    const [fincaCambiarAAFF, setFincaCambiarAAFF] = useState({});
    const [open, setOpen] = useState(false);
    // const [tab, setTab] = useState(0);
    const [show, setShow] = useState(false);

    const hijoAPadre = (datosHijo) => {
        setRecargar(datosHijo);
    };

    function changePage(e) {
        
        const d = e.selected * perPage;
        
        setCurrentPage(e.selected);
        setOffset(d);
        receivedData(d, perPage);
    }

    function receivedData(o, p) {
        
        getAAFF(props.params.nif).then((res) => {
          let fin = [];
          res.properties.forEach((f) => {
            if(f.status){
                fin.push(f);
            }
          });
          setAAFF(res);
          setFincas(fin);
          setIsloading(false);
          setpageCount(Math.ceil(fin.length / perPage));
        });
    }

    useEffect(() => {
        fetchData()
        //setPorcentaje(65)
        
    }, [recargar]);

    const fetchData = async () => {
        const res = await getAAFF(props.params.nif)
        let fin = [];
        res.properties.forEach((f) => {
        if(f.status){
            fin.push(f);
        }
        });
        setAAFF(res);
        setFincas(fin);
        setIsloading(false);
        setpageCount(Math.ceil(fin.length / perPage));
        setRecargar(false);
    }

    const mostrarPanel = (opened, finca) => {
        setOpen(opened);
        setFincaCambiarAAFF(finca);
      };
    function bajaFinca(f) {
        Swal.fire({
          title: "Dar de baja " + f.razonSocial + "?",
          showCancelButton: true,
          confirmButtonText: "Dar de baja",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            deleteFinca(f.nif)
              .then((res) => {
                if (res.message === "PROPERTIE_DELETED") {
                  toast.success("finca dada de baja");
                  setRecargar(!recargar);
                } else {
                  toast.error("Error al dar de baja la finca");
                }
              })
              .catch((error) => {
                console.log(error);
                toast.error("Error al dar de baja la finca");
              });
          }
        });
    }
    function eliminarCentroTrabajo(ct, f) {
        console.log("eliminarCentroTrabajo", ct, f);
        deleteCentroTrabajo(f.id)
          .then((res) => {
            console.log(res);
            toast.success("Centro de trabajo eliminado con éxito");
            setRecargar(!recargar);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error al eliminar el centro de trabajo");
        });
    }
    
    // const seleccionarComunidad = (evt, nifFinca) => {
    //     if (evt.target.checked) {
    //         setFincasSeleccionadas([...fincasSeleccionadas, nifFinca]);
    //     } else {
    //         if (fincasSeleccionadas.includes(nifFinca)) {
    //         let res = fincasSeleccionadas.filter((item) => item !== nifFinca);
    //         setFincasSeleccionadas(res);
    //         }
    //     }
    // };
    // const renovarComunidadSeleccionada = () => {
    //     let fecha = undefined;
    //     console.log(fincasSeleccionadas);
    //     fincasSeleccionadas.forEach((f) => {
    //       console.log(f);
    //       renovarFinca(f).then((res) => {
    //         console.log(res);
    //         setRecargar(!recargar);
    //       });
    //     });
    // };
    
    return ( 
        <>
            <div
                style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 0,
                paddingRight: 0,
                margin: "20px 0",
                }}
            >
                <div>
                {show ? (
                    <ExportarListaFincasAAFF aaffNif={aaff.nif} fincas={fincas} />
                ) : (
                    <button className="bigBtn" style={{ margin: 0 }} onClick={() => setShow(true)}>
                    <img src={Excel} alt="Generar listado" width="30px" /> Generar
                    listado
                    </button>
                )}
                </div>
                {/* <div>
                    <button className="bigBtn"
                        onClick={() => renovarComunidadSeleccionada()}
                    >
                        <img src={RenovarComunidades} alt="Renovar Comunidades" width="30px" />
                        Renovar seleccionadas
                    </button>
                </div> */}
            </div>
            <div className='tableWrapper'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Comunidad</th>
                            <th>NIF</th>
                            <th>Fecha alta</th>
                            <th style={{ textAlign: "center" }}>Proveedores</th>
                            <th style={{ textAlign: "center" }}>
                                Añadir centro trabajo
                            </th>
                            <th style={{ textAlign: "center" }}>Cambiar AAFF</th>
                            {/* <th style={{ textAlign: "center" }}>Renovar</th> */}
                            <th style={{ textAlign: "center" }}>Baja</th>
                        </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan="15" style={{ textAlign: "center" }}>
                                <CircularProgress style={{ color: "#92004D" }} />{" "}
                            </td>
                        </tr>
                    ): fincas.length === 0 ? (
                        <tr>
                            <td colSpan="17" style={{ textAlign: "center" }}>
                            No hay fincas
                            </td>
                        </tr>
                    ): fincas?.slice(offset, offset + perPage).map((e, i) => {
                        return (
                            <React.Fragment key={i}>
                            <tr>
                                <td>{i + 1}</td>
                                <td>
                                <Link
                                    to={
                                    "/aaff/" +
                                    props.params.nif +
                                    "/comunidades/" +
                                    e.nif
                                    }
                                >
                                    {e.razonSocial}
                                </Link>
                                </td>
                                <td>{e.nif}</td>
                                <td>{formatoFechasEs(e.createdAt)}</td>
                                
                                <td style={{ textAlign: "center" }}>
                                    <Link
                                        to={`/aaff/${props.params.nif}/comunidades/${e.nif}/proveedores`}
                                    >
                                        {e.suppliers.filter(objeto => objeto.status === true).length}
                                    </Link>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <Link
                                        to={"/comunidades/" + e.nif + "/nuevoCentro"}
                                    >
                                        <img
                                        src={Add}
                                        alt="Más"
                                        className="tableIcon"
                                        />
                                    </Link>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                <img
                                    src={Change}
                                    alt="CAMBIAR AAFF"
                                    className="tableIcon"
                                    onClick={() => mostrarPanel(true, e)}
                                ></img>
                                </td>
                                {/* <td
                                style={{ cursor: "pointer", textAlign: "center" }}
                                >
                                {e.renovada ? (
                                    <img
                                    src={Tick}
                                    width="30px"
                                    alt="todas renovadas"
                                    />
                                ) : (
                                    <input
                                    type="checkbox"
                                    name={`${i}`}
                                    onChange={(evt) =>
                                        seleccionarComunidad(
                                        evt,
                                        e.nif,
                                        e.razonSocial
                                        )
                                    }
                                    />
                                )}
                                </td> */}
                                <td style={{ textAlign: "center" }}>
                                <img
                                    src={Delete}
                                    alt="BAJA"
                                    className="tableIcon"
                                    onClick={() => bajaFinca(e)}
                                ></img>
                                </td>
                            </tr>
                            {e.workcenters === undefined ||
                            e.workcenters.length === 0 ? null : (
                                <>
                                {e.workcenters.map((c, j) => {
                                    return (
                                    <tr
                                        key={j}
                                        style={{
                                        backgroundColor:
                                            j % 2 === 0 ? "#fffbf4" : "#fff9ea",
                                        }}
                                    >
                                        <td></td>
                                        <td colSpan={3} width={"100px"}>
                                        {c.razonSocial}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                        style={{
                                            textAlign: "center",
                                            width: "50px",
                                        }}
                                        >
                                        <img
                                            src={Delete}
                                            alt="ELIMINAR"
                                            className="tableIcon"
                                            onClick={() =>
                                            eliminarCentroTrabajo(true, c, e)
                                            }
                                        ></img>
                                        </td>
                                    </tr>
                                    );
                                })}
                                </>
                            )}
                            </React.Fragment>
                        );
                        })}
                    </tbody>
                </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
            <div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={changePage}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            {open && (
                <DialogoCambioAAFF
                    open={open}
                    finca={fincaCambiarAAFF}
                    aaff={props.params.nif}
                    tipo={"cambiar"}
                    hijoAPadre={hijoAPadre}
                />
            )}
        </>
     );
}
 
export default withRouter(ServicioCae);