import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DataTable } from "../../components";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import Pending from "../../icons/pending.svg";
import { getAlertas, getAllConsultores, getProveedores, suppsNoDocs, suppsWithDocs, validateNoDocSuppAlert } from "../../services";
import {
  daysDiff,
  formatoFechasEs,
} from "../../functions/funciones";
import {
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import FiltrosAlertasProv from "../../components/filtros/filtrosAlertasProv";
import { FaCheck } from "react-icons/fa";
import ModalDocsSupp from "./../../components/alerts/modalDocsSupp";

const WithDocumentsAlert = () => {
    const [Consultores, setConsultores] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [proveedor, setProveedor] = useState({});
    const [busqueda, setBusqueda] = useState({});
    const [loading, setLoading] = useState(true);
    const [recargar, setRecargar] = useState(false);
    const [limite, setLimite] = useState(0);
    const [validating, setValidating] = useState(false);
    const [modalEditarOpened, setModalEditarOpened] = useState(false);
    const [data, setData] = useState({
      meta: {},
      rows: [],
    });

  
    useEffect(() => {
      fetchData();
      getAllConsultores().then((res) => {
        setConsultores(res);
      });
    }, [recargar, validating]);
  
    const fetchData = async ({ offset = 1, limit = limite, filtros = busqueda } = {}) => {
      console.log(offset, limit, filtros);
      setLimite(limit);
      const payload = {
        // filtros:{ultimaReclamacion:new Date().setDate(new Date().getDate() - 60)},
        filtros,
        params: { page: offset, limit },
      };
  
      try {
        setLoading(true);
        
        const response1 = await suppsWithDocs(payload);
        const response = await getProveedores(payload);
        console.log(response1)
        console.log(response)
        const { meta, data} = response1;
        console.log(data);
        setProveedores(data);
        setData({
          meta,
          rows: formatData(data),
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setRecargar(false);
      }
    };
  
    const formatData = (data) => {
      data.map((row, i) => (
        console.log(row.docssupps?.find((d) => d.tipoDoc === 4)?.validado)
      ))
      return data.map((row, i) => ({
        proveedor_id: row.id,
        proveedor_name: row.razonSocial,
        // ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
        nif: row.nif,
        planificacionPreventiva: row.noProcede ? 'No procede' : {validado: row.docssupps?.find((d) => d.tipoDoc === 4)?.validado, ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 4)?.ultimaReclamacion},
        evaluacionRiesgos: {validado: row.docssupps?.find((d) => d.tipoDoc === 5)?.validado, ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 5)?.ultimaReclamacion},
        vigilancia: row.noProcede ? 'No procede' : {fechaCaducidad:row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad || '', ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 7)?.ultimaReclamacion},
        certificado: {fechaCaducidad:row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad || '', ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 6)?.ultimaReclamacion},
        justificante: row.noProcede ? 'No procede' : {fechaCaducidad:row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad || '', ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 3)?.ultimaReclamacion},
        seguro: {fechaCaducidad:row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad || '', ultimaReclamacion: row.docssupps?.find((d) => d.tipoDoc === 2)?.ultimaReclamacion},
        validar: row.id
      }));
    };

    const proveedoresRows = [
        {
          id: "proveedor_id",
          numeric: false,
          disablePadding: false,
          label: "",
        },
        {
          id: "proveedor_name",
          numeric: false,
          disablePadding: false,
          label: "PROVEEDOR",
          overrideFunc: (data, row) => <Link to={"/proveedores/" + row.nif}>{data}</Link>
        },
        // {
        //   id: "ultimaReclamacion",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "ÚLTIMA RECLAMACIÓN",
        //   overrideFunc: (data, row) => <span style={{color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span>
        // },
        {
          id: "nif",
          numeric: false,
          disablePadding: false,
          label: "NIF",
        },
        {
          id: "planificacionPreventiva",
          numeric: false,
          disablePadding: false,
          label: "PLANIFICACIÓN PREVENTIVA",
          overrideFunc: (data, row) =>  (
            row.planificacionPreventiva === "No procede" ? row.planificacionPreventiva :
            <Tooltip title={row.planificacionPreventiva.ultimaReclamacion == null || row.planificacionPreventiva.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.planificacionPreventiva.ultimaReclamacion)}>
              <img style={{ width: "20px" }} src={daysDiff(new Date(row.planificacionPreventiva.ultimaReclamacion), new Date()) < 60 ? Pending : !row.planificacionPreventiva.validado ? Cross : Tick} alt="vacio" />
            </Tooltip>
          )
            
        },
        {
          id: "evaluacionRiesgos",
          numeric: false,
          disablePadding: false,
          label: "EVALUACIÓN RIESGOS",
          overrideFunc: (data, row) =>  (
            row.evaluacionRiesgos === "No procede" ? row.evaluacionRiesgos :
            <Tooltip title={row.evaluacionRiesgos.ultimaReclamacion == null || row.evaluacionRiesgos.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.evaluacionRiesgos.ultimaReclamacion)}>
              <img style={{ width: "20px" }} src={daysDiff(new Date(row.evaluacionRiesgos.ultimaReclamacion), new Date()) < 60 ? Pending : !row.evaluacionRiesgos.validado ? Cross : Tick} alt="vacio" />
            </Tooltip>
          )
        },
        {
          id: "vigilancia",
          numeric: false,
          disablePadding: false,
          label: "Vigilancia salud",
          overrideFunc: (data, row) => (
            row.vigilancia === "No procede" ? row.vigilancia :
            row.vigilancia.fechaCaducidad ?
            <Tooltip title={row.vigilancia.ultimaReclamacion == null || row.vigilancia.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.vigilancia.ultimaReclamacion)}>
              <span style={{color: daysDiff(new Date(row.vigilancia.ultimaReclamacion), new Date()) < 60 ? 'orange' : daysDiff(new Date(row.vigilancia.fechaCaducidad), new Date()) >= 0 ? 'red' : 'black'}}>
                {formatoFechasEs(row.vigilancia.fechaCaducidad)}
              </span>
            </Tooltip> : '-'
          )
        },
        {
          id: "certificado",
          numeric: false,
          disablePadding: false,
          label: "Certificado Seg. Social",
          overrideFunc: (data, row) => (
          row.certificado.fechaCaducidad ?
            <Tooltip title={row.certificado.ultimaReclamacion == null || row.certificado.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.certificado.ultimaReclamacion)}>
              <span style={{color: daysDiff(new Date(row.certificado.ultimaReclamacion), new Date()) < 60 ? 'orange' : daysDiff(new Date(row.certificado.fechaCaducidad), new Date()) >= 0 ? 'red' : 'black'}}>
                {formatoFechasEs(row.certificado.fechaCaducidad)}
              </span>
            </Tooltip> : '-'
          )
        },
        {
          id: "justificante",
          numeric: false,
          disablePadding: false,
          label: "Justificante pago SPA",
          overrideFunc: (data, row) =>  (
            // console.log(row)
            row.justificante === "No procede" ? row.justificante :
            row.justificante.fechaCaducidad ?
            <Tooltip title={row.justificante.ultimaReclamacion == null || row.justificante.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.justificante.ultimaReclamacion)}>
              <span style={{color: daysDiff(new Date(row.justificante.ultimaReclamacion), new Date()) < 60 ? 'orange' : daysDiff(new Date(row.justificante.fechaCaducidad), new Date()) >= 0 ? 'red' : 'black'}}>
                {formatoFechasEs(row.justificante.fechaCaducidad)}
              </span>
            </Tooltip> : '-'
            )
        },
        {
          id: "seguro",
          numeric: false,
          disablePadding: false,
          label: "Seguro Resp. Civil",
          overrideFunc: (data, row) => (
            row.seguro.fechaCaducidad ?
              <Tooltip title={row.seguro.ultimaReclamacion == null || row.seguro.ultimaReclamacion == undefined ? 'última reclamación: -' : "última reclamación: "+ formatoFechasEs(row.seguro.ultimaReclamacion)}>
                <span style={{color: daysDiff(new Date(row.seguro.ultimaReclamacion), new Date()) < 60 ? 'orange' : daysDiff(new Date(row.seguro.fechaCaducidad), new Date()) >= 0 ? 'red' : 'black'}}>
                  {formatoFechasEs(row.seguro.fechaCaducidad)}
                </span>
              </Tooltip> : '-'
            )
        },
        {
            id: "validar",
            numeric: false,
            disablePadding: false,
            label: "Validar",
            overrideFunc: (data, row) => (
              <>
                <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px'}} onClick={ () => validarSupp(data)} disabled={validating}><FaCheck /></Button>
              </>
            )
          },
    ];


    const validarSupp = async (id) => {
      console.log(id)
      let proveedor = proveedores.find( p => p.id === id)
      setProveedor(proveedor)
      setModalEditarOpened(true)
    }
    const validarAlerta = async (id) => {
        setValidating(true);
        try {
            const toastInfo = toast.info("Validando...", { autoClose: false });
            const response = await validateNoDocSuppAlert(id)
            console.log(response)
            if(response.status === 201){
                toast.update(toastInfo, {
                render: "Alerta validada",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
                });
                setRecargar(true)
            }
            if(response.status === 403){
                toast.update(toastInfo, {
                render: "No se ha podido validar la alerta",
                type: toast.TYPE.ERROR,
                autoClose: true,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Error al validar la alerta");
        }finally {
            setValidating(false);
        }
    }

    const filtrarDatos = (filtros) => {
        setBusqueda(filtros)
        fetchData({ offset: 1, limit: limite, filtros: filtros });
    } 

    return ( 
        <>
          <ModalDocsSupp
              proveedor= {proveedor}
              opened={modalEditarOpened}
              handleClose={() => {
                setModalEditarOpened(false);
                setRecargar(!recargar);
              }}
            />
          <div className="listPage">
              <div className="gridCard">
                  <div className="tableContainer">
                      <FiltrosAlertasProv onFilter={filtrarDatos} />
                      <div className="tableWrapper">
                      <Box className="pageContent">
                          <Box sx={{ mb: 10 }}>
                          <DataTable
                              data={data.rows}
                              isLoading={loading}
                              headers={proveedoresRows}
                              fetcher={fetchData}
                              meta={data.meta}
                              filtros={busqueda}
                              mensaje={"No hay proveedores"}
                          />
                          </Box>
                      </Box>
                      </div>
                  </div>
              </div>
          </div>
        </>
     );
}
 
export default WithDocumentsAlert;