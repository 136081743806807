import React, { useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Typography } from "@mui/material";
/* import { useEffect } from "react";
import { getVisitSheet } from "../../../services"; */
import {
  AAFFSelector,
  CommercialSelector,
  LaeButton,
  PropertieSelector,
  WorkcenterSelector,
  ServiceToSaleSelector
} from "../../../components";
import { createSale } from "../../../services"; 
import { toast } from "react-toastify";
import { property } from "underscore";
import { formatoFechas } from "../../../functions/funciones";
import Swal from "sweetalert2";

const CrearVenta = (props) => {
  const navigate = useNavigate();
  const [aaffDrawer, setAaffDrawer] = useState(false);
  const [propertieDrawer, setPropertieDrawer] = useState(false);
  const [commDrawer, setCommDrawer] = useState(false);
  const [serviceDrawer, setServDrawer] = useState(false);
  const [workcenterDrawer, setWorkcenterDrawer] = useState(false);
  const [saleDate, setSaleDate] = useState('');
  const [workCenterFormDisabled, setWorcenterFormDisabled] = useState(true);
  const [fecha, setFecha] = useState('');
  const [aaffInfo, setAaffInfo] = useState({
    id: "",
    name: "",
  });

  const [propertieInfo, setPropertieInfo] = useState({
    id: "",
    name: "",
  });

  const [workCenterInfo, setWorkCenterInfo] = useState({
    id: "",
    name: "",
  });

  const [commInfo, setCommInfo] = useState({
    id: "",
    name: "",
  });
  const [serviceInfo, setServiceInfo] = useState({
    id: "",
    name: "", 
  });//AÑADIR INFORMACIÓN 
  /*   const handleGet = async () => {
    const { data } = await getVisitSheet(19);
    console.log(data);
  }; */

  const openAaffDrawer = () => setAaffDrawer(true);
  const openPropertieDrawer = () => setPropertieDrawer(true);
  const openCommercialDrawer = () => setCommDrawer(true);
  const openServiceDrawer = () => setServDrawer(true);
  const openWorkcenterDrawer = () => setWorkcenterDrawer(true);
  const getFecha = (e) => {
    console.log("A")
    console.log(e.target.value)
    setFecha(e.target.value)
  };

  const closeAaffDrawer = (row) => {
    if (row) {
      const { aff_name, id } = row;
      setAaffInfo((prev) => ({
        id,
        name: aff_name,
      }));
    }
    setAaffDrawer(false);
  };

  const closePropertieDrawer = (row) => {
    if (row) {
      const { community_name, id, aaff_id, commer_id, centrosTrabajo, tech_id } = row;
      setPropertieInfo((prev) => ({
        id,
        name: community_name,
        aaff_id: aaff_id,
        commer_id: commer_id,
        tech_id: tech_id,
        centrosTrabajo: centrosTrabajo
      }));
      if(centrosTrabajo.length > 0){
        setWorcenterFormDisabled(false);
      }else{
        setWorcenterFormDisabled(true);
      }
    }
   
    setPropertieDrawer(false);
    
  };
  const closeWorkcenterDrawer = (row) => {
    if (row) {
      const { name, id, aaff_id, commer_id, prop_id, tech_id } = row;
      // if(propertieInfo.id === prop_id){
        setWorkCenterInfo((prev) => ({
          id,
          name,
          aaff_id: aaff_id,
          commer_id: commer_id,
          prop_id: prop_id,
          tech_id: tech_id
        }));
      // }
    }
    setWorkcenterDrawer(false);
  };
  const closeCommercialDrawer = (row) => {
    if (row) {
      const { nombre, apellidos, id } = row;
      setCommInfo((prev) => ({
        id,
        name: `${nombre} ${apellidos}`,
      }));
    }
    setCommDrawer(false);
  };

  const closeServiceDrawer = (row) => {
    if (row) {
      const { service_name, id } = row;
      setServiceInfo((prev) => ({
        id,
        name: `${service_name}`,
      }));
    }
    
    setServDrawer(false);
  };

  const saveSale = async () => {
    let toastInfo = toast.info("Creando venta...", { autoClose: false });
    let data = {}
    if(propertieInfo.id!==''){
      data = {
        aaff_id: propertieInfo.aaff_id,
        prop_id: propertieInfo.id,
        commer_id: propertieInfo.commer_id,
        tech_id: propertieInfo.tech_id,
        servp_id: serviceInfo.id,
        saleDate: fecha
      }
    }else if (workCenterInfo.id!==''){
      data = {
        aaff_id: propertieInfo.aaff_id,
        workcenter_id: propertieInfo.id,
        prop_id: propertieInfo.id,
        commer_id: propertieInfo.commer_id,
        tech_id: propertieInfo.tech_id,
        servp_id: serviceInfo.id,
        saleDate: fecha
      }
    }
    // data.saleDate = saleDate;
    let itemsErr = {}
    const response = await createSale(data);
    if(response.status == 200){
      toast.success(response.msg);
      setTimeout(()=>{
        navigate('/servicios')
      },2000);
    }
    if(response.status == 400){
      // toast.error(response.msg);
      itemsErr = response.msg
      toast.dismiss(toastInfo);
    }
    else{
      itemsErr = response.error
      toast.dismiss(toastInfo);
    }
    const html = `
      <div style="display: flex;">
        <div style="flex: 1;">
          <ul style="font-size:13px; margin-top:10px; text-align:left;">
            ${`<li style="margin:5px 0;">${itemsErr}</li>`}
          </ul>
        </div>
      </div>
    `;
    console.log(itemsErr)
    if(itemsErr){
      Swal.fire({
        title: 'Error al crear la venta',
        html: html,
        confirmButtonText: 'Cerrar',
        icon: 'error'
      });
    }
  };
  useEffect(()=>{
    const getCurrentDate = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Añade un cero al mes si es necesario
      const day = currentDate.getDate().toString().padStart(2, '0'); // Añade un cero al día si es necesario
      const formattedDate = `${year}-${month}-${day}`;
      setSaleDate(formattedDate);
    };

    getCurrentDate();
  },[]);
  return (
    <>
      <PropertieSelector
        open={propertieDrawer}
        onClose={closePropertieDrawer}
      />

      <WorkcenterSelector
        propertieInfo= {propertieInfo}
        open={workcenterDrawer}
        onClose={closeWorkcenterDrawer}
      />
      <ServiceToSaleSelector 
        open={serviceDrawer}
        onClose={closeServiceDrawer}
      />
      <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Crear Venta
        </Typography>
      </div>
      <Box
        className="pageContent"
        sx={{
          maxWidth: 900,
          margin: "0 auto",
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mb: 10,
            maxWidth: 700,
            margin: "0 auto",
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Detalles de la Venta:
          </Typography>
          <Box display={"flex"} flexDirection={"row"} flex={1} gap={2}>
            {/* JUANK */}
            {/* <TextField
              id="aaff-selector"
              placeholder="Administrador de Finca"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={aaffInfo.name}
              onClick={openAaffDrawer}
              fullWidth
            /> */}
            <TextField
              id="propertie-selector"
              label="Comunidad"
              placeholder="Comunidad"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={propertieInfo.name}
              onClick={openPropertieDrawer}
              fullWidth
            />
          </Box>
          <Box display={"flex"} flexDirection={"row"} flex={1} gap={2}>
            {/* <TextField
              id="commercial-selector"
              placeholder="Comercial"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={commInfo.name}
              onClick={openCommercialDrawer}
              fullWidth
            /> */}
            {/* IF la propiedad tiene worcenters... */}
            <TextField
              id="workcenter-selector"
              placeholder="Centro de Trabajo"
              label="Centro de Trabajo"
              disabled={workCenterFormDisabled}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={workCenterInfo.name}
              onClick={openWorkcenterDrawer}
              fullWidth
            />
          </Box>
          <Box display={"flex"} flexDirection={"row"} flex={1} gap={2}>
            <TextField
              id="service-selector"
              placeholder="Servicio a contratar"
              label="Servicio"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={serviceInfo.name}
              onClick={openServiceDrawer}
              fullWidth
            />
            {/*  <TextField
              id="workcenter-selector"
              placeholder="Centro de Trabajo"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={workCenterInfo.name}
              onClick={openWorkcenterDrawer}
              fullWidth
            /> */}
          </Box>
          <Box display={"flex"} flexDirection={"row"} flex={1} gap={2}>
            <TextField
                type="date"
                label="Fecha venta"
                variant="outlined"
                name="fecha"
                value={formatoFechas(new Date(fecha))}
                InputLabelProps={{ shrink: true }}
                onChange={getFecha}
                fullWidth
            />
          </Box>
          <LaeButton
            onClick={() => saveSale()}
            sx={{ width: 700, height: 40, alignSelf: "center" }}
          >
            <Typography>Guardar</Typography>
          </LaeButton>
        </Box>
      </Box>
    </>
  );
};

export default withRouter(CrearVenta);
