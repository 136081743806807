import React, { useState, useEffect, useContext } from "react";
import {
  LinearProgress,
  styled,
  Box,
  linearProgressClasses,
} from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { AaffListPropertie, getAAFF, getFincasAAFF } from "../../../services";
import DataTable from "../../../components/Tables/DataTable";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import CircularProgress from "@mui/material/CircularProgress";
import { formatoFechasEs } from "../../../functions/funciones";
import { Link } from "react-router-dom";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';

const BorderLinearProgress = styled(LinearProgress)((props) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    backgroundColor: "#eee",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: props.value,
    backgroundColor:
      props.value < 25 ? "#FF5252" : props.value < 75 ? "#fcba03" : "#7bc977",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress {...props} variant="determinate" />
      </Box>
      <Box minWidth={35}>{props.label}</Box>
    </Box>
  );
}

const Comunidades = (props) => {
  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [fincas, setFincas] = useState({});
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const fetchData = async ({ offset, limit, filtros }) => {
    
    const payload = {
      filtros: {...filtros, aaff_id:user.id},
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      // const response = await getFincasAAFF(payload);
      const response = await AaffListPropertie(payload);
      const { meta, data } = response;
        console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const formatData = (data) => {
    
    return data.map((row) => ({
      comunidad: row.razonSocial,
      nif: row.nif,
      codigoPostal: row.codigoPostal,
      direccion: row.direccion,
      municipio: row.municipio,
      provincia: row.provincia,
    }));
  };

  const comunidadesRows = [
    {
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={`/comunidades/${row.nif}`}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCION",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
  ];

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    console.log(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      <>
        <BreadCrumb rutas={[{path:`/aaff/${props.params.nif}/comunidades`, label:"Comunidades"}]} style={{ margin: '0 10px'}} />
        <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  COMUNIDADES
              </div>
          </div>
        </div>
        <div className="pageContent tableContainer">
          <FiltrosComunidades onFilter={filtrarDatos} />
          <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={comunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay fincas"}
                />
                </Box>
            </Box>
          </div>
        </div>
      </>
    </>
  );
};

export default withRouter(Comunidades);
