import React from "react";
import { withRouter } from "../../providers/withRouter";

export default function Tabs(props){
    const { children, value, index, ...other } = props;
      
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
        >
        {value === index && (
            <>
            {children}
            </>
        )}
        </div>
    );
}
 