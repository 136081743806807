import axios from "axios";

const formHeaders = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

const urlPath = "declRes";

export const getDeclResList = async () => {
  try {
    const response = await axios.get(urlPath);
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
/**
 * Enpoint para subir un archivo de declaraciones responsables
 * @param {any} file - Archivo a subir
 * @param {any} nif - NIF del proveedor
 * @param {any} data - JSON con el ID del AAFF ej: { aaff_id: 1 }
 * @returns {any}
 */

export const createDeclRes = async (file, nif, data) => {
  console.log(file, nif, data);
  const json = JSON.stringify(data);
  const formData = new FormData();
  formData.append("data", json);
  formData.append("file", file);

  try {
    const response = await axios.post(`${urlPath}/${nif}`, formData, data, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const updateDeclRes = async (file, idDeclRes) => {
  let data = {validado:true};
  const json = JSON.stringify(data);
  const formData = new FormData();
  formData.append("data", json);
  formData.append("file", file);
  try {
    const response = await axios.put(
      `${urlPath}/${idDeclRes}`,
      formData,
      {
        ...formHeaders,
      }
    );
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteDeclRes = async (nif, idDeclRes) => {
  try {
    const response = await axios.delete(`${urlPath}/${idDeclRes}`, {
      params: idDeclRes,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const validateDeclRes = async (nif, idDoc, data) => {
  try {
      const response = await axios.put(`${urlPath}/validate/${nif}/doc/${idDoc}`, data);
      console.log(response)
      return response;
  } catch (err) {
      console.log(err.response);
      return err.response;
  }
};
