import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Fade, Box, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import { cambiarFinca } from "../../../services";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { municipiosIniciales, cambiarFormatoNombre } from "../../../functions/funciones";

/* const useStyles = styled(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "#fff",
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    padding: '16px 32px 24px'
  },
  form: {
    margin:  '8px',
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
})); */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85ch",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const modal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const paper = {
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow:
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
  padding: "16px 32px 24px",
};
const form = {
  margin: "8px",
  width: "100ch",
  position: "relative",
  display: "grid",
  gridTemplateColumns: " repeat(2, 1fr)",
  gridGap: "15px",
  fontSize: "12px",
};

export default function TransitionsModal(props) {
  //const classes = useStyles();

  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [activo, setActivo] = useState(false)

  useEffect(() => {
    const fincaData = {
      razonSocial:
        props.finca.razonSocial === null ? "" : props.finca.razonSocial,
      email:
        props.finca.emailContacto === null ? "" : props.finca.emailContacto,
      nombre:
        props.finca.nombreContacto === null ? "" : props.finca.nombreContacto,
      telefono:
        props.finca.telefonoContacto === null
          ? ""
          : props.finca.telefonoContacto,
      municipio: props.finca.municipio === null ? "" : props.finca.municipio,
      direccion: props.finca.direccion === null ? "" : props.finca.direccion,
      codigoPostal:
        props.finca.codigoPostal === null ? "" : props.finca.codigoPostal,
      provincia: props.finca.provincia === null ? "" : props.finca.provincia,
      nifPresidente: props.finca.nifPresidente === null ? "" : props.finca.nifPresidente,
      nombrePresidente: props.finca.nombrePresidente === null ? "" : props.finca.nombrePresidente,

    };
    setEditarDatos(fincaData);
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.finca.provincia))
  }, [props]);

  function handleChange(evt) {
    const value = evt.target.value === undefined ? "" : evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value,
    });
  }

  const editar = (finca) => {
    setActivo(true)
    let data = {};
    const toastInfo = toast.info("Guardando datos", { autoClose: false });
    cambiarFinca(
      props.finca.nif,
      (data = {
        razonSocial: finca.razonSocial,
        emailContacto: finca.email,
        nombreContacto: finca.nombre,
        telefonoContacto: finca.telefono,
        municipio: finca.municipio,
        direccion: finca.direccion,
        codigoPostal: finca.codigoPostal,
        provincia: finca.provincia,
        nifPresidente: finca.nifPresidente,
        nombrePresidente: finca.nombrePresidente,
      })
    )
      .then((res) => {
        console.log(res.error)
        if(res.error){
          console.log(res.error)
          res.error.forEach(e=> {
              console.log(e.message)
              if(e.message.includes('nifPresidente')){
                toast.update(toastInfo, {
                  render: "El nif del presidente esta mal escrito",
                  type: toast.TYPE.ERROR,
                  autoClose: true,
                });
              }
          })
          return
        }
        if (res.message === "PROPERTIE_UPDATED") {
          toast.update(toastInfo, {
            render: "Datos guardados con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          // props.handleClose();
          setActivo(false)
        } else {
          toast.update(toastInfo, {
            render: "no se ha podido editar los datos de la comunidad",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          setActivo(false)
        }
      })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
    /* firestore.collection("fincas").doc(props.finca.nif).update({
      razonSocial: finca.razonSocial,
      contacto:{
        nombre: finca.nombre,
        email: finca.email,
        telefono: finca.telefono,

      },
      codigoPostal: finca.codigoPostal,
      direccion: finca.direccion,
      municipio: finca.municipio,
      provincia: finca.provincia,
      
    }).then(() => {
      toast.update(toastInfo, {
        render: "Datos guardados con éxito",
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
      props.handleClose()
    }).catch(err => {
      console.log(err)
      toast.error("Error al editar los datos")
    }) */
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.name === e.target.value){
        codProvincia = p.code
      }
    })
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value
    });   
    municipalities.map( m => {
      if(m.provCode === codProvincia){
        municipios.push({...m, name: cambiarFormatoNombre(m.name), value: m.name})
      }
    })
    setListadoMunicipios(municipios)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        //className={modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            /* className={paper} */ style={{
              backgroundColor: "#fff",
              border: "2px solid #000",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              padding: "16px 32px 24px",
            }}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div
                  /* className={form} */ style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <TextField
                    id="razonSocial"
                    name="razonSocial"
                    label="Razón Social"
                    value={editarDatos.razonSocial}
                    onChange={handleChange}
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={editarDatos.email}
                    onChange={handleChange}
                  />
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={editarDatos.nombre}
                    onChange={handleChange}
                  />
                  <TextField
                    id="telefono"
                    name="telefono"
                    label="Teléfono"
                    value={editarDatos.telefono}
                    onChange={handleChange}
                  />
                  <TextField
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    value={editarDatos.direccion}
                    onChange={handleChange}
                  />
                  <TextField
                    id="codigoPostal"
                    name="codigoPostal"
                    label="Codigo Postal"
                    value={editarDatos.codigoPostal}
                    onChange={handleChange}
                  />
                  <Select native name="provincia" id="provincia" style={{ textTransform: 'uppercase'}} onChange={seleccionarMunicipios} defaultValue={editarDatos?.provincia}>
                  <option value="">Provincia</option>
                  {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                  </Select>
                  <Select native name="municipio" id="municipio" style={{ textTransform: 'uppercase'}} onChange={handleChange} defaultValue={editarDatos?.municipio}>
                  <option value="">Municipio</option>
                  {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.value}>
                        {e.name}
                      </option>
                    );
                  })}
                  </Select>                
                  <TextField
                    id="nifPresidente"
                    name="nifPresidente"
                    label="Nif presidente"
                    value={editarDatos.nifPresidente}
                    onChange={handleChange}
                  />
                  <TextField
                    id="nombrePresidente"
                    name="nombrePresidente"
                    label="Nombre presidente"
                    value={editarDatos.nombrePresidente}
                    onChange={handleChange}
                  />  
                </div>
                <div
                  /* className={form} */ style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <Button 
                    variant="contained" 
                    onClick={props.handleClose}
                    disabled={activo} 
                    style={{opacity: activo ? .6 : 1}}>
                    Cancelar
                  </Button>
                  <Button
                    className="btn-Secondary"
                    variant="contained"
                    color="secondary"
                    onClick={() => editar(editarDatos)}
                    disabled={activo} 
                    style={{opacity: activo ? .6 : 1}}>
                      {activo ? <CircularProgress style={{ height: 20, width: 20, color:'#fff'}} /> : "Editar"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
