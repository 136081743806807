import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createAAFF, getAllCommercials } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { useNavigate } from "react-router-dom";
const NuevoAAFF = () => {
  
  const navigate = useNavigate();
  const [aaff, setAaff]= useState({})
  const [comerciales, setComerciales]= useState([])
  const [listadoMunicipios, setListadoMunicipios]= useState([])
  const [provincia, setProvincia]= useState('')

  useEffect(() => {
    fetchData()
  },[])
  
  const fetchData = async () => {
    const response = await getAllCommercials()
    console.log(response)
    setComerciales(response)
  }

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAaff({
      ...aaff,
      [evt.target.name]: value,
    });
    
  }

  const seleccionarMunicipios = (e) => {
    let provincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.code === e.target.value){
        provincia = p.name
      }
    })
    // setProvincia(provincia)
    setAaff({
      ...aaff,
      provincia,
    });
      
    municipalities.map( m => {
      if(m.provCode === e.target.value){
        
        municipios.push(m)
      }
    })
    setListadoMunicipios(municipios)
  }

  const crearAAFF = async (e) => {
    e.preventDefault();
    
    if (aaff.commer_id === undefined) {
      toast.error("Por favor selecciona un comercial.");
      return;
    }

    let toastInfo = toast.info("Guardando datos...")
    
    const response = await createAAFF(aaff);
    
    if(response.data === "ERROR_CREATE_AAFF"){
      toast.update( toastInfo, {
          render: "Error al crear el administrador",
          type: toast.TYPE.ERROR,
          autoClose: true
      })
    }
    if( response.data === "ERROR_AAFF_EXIST"){
      toast.update( toastInfo, {
          render: "El administrador ya existe",
          type: toast.TYPE.ERROR,
          autoClose: true
      })
    }

    if( response.data === "AAFF_CREATED"){
        toast.update( toastInfo, {
            render: "El aaff creado correctamente",
            type: toast.TYPE.SUCCESS,
            autoClose: true
        })
        setTimeout(()=>{
          navigate('/aaff')
        },2000);
    }
  }
  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:"Administradores de fincas"}, {path:'/aaff', label:"Nuevo"}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridHead">
          Crear nuevo Administrador de Fincas
        </div>
        <form className="nuevoAAFFForm" onSubmit={crearAAFF}>
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="NIF"
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
                name="nif"
                required
                value={aaff.nif}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Razón social"
                name="razonSocial"
                required
                minLength={5}
                value={aaff.razonSocial}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                placeholder="Email"
                name="email"
                required
                value={aaff.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="tel"
                placeholder="Telefono"
                name="telefono"
                required
                value={aaff.telefono}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="provincia"
                onChange={(e) =>
                  seleccionarMunicipios(e)
                }
              >
                <option value={aaff.provincia}>Provincia</option>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.code}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
            <select
                name="municipio"
                onChange={handleChange}
              >
                <option value={aaff.municipio}>Municipio</option>
                {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Persona de contacto"
                name="nombreContacto"
                value={aaff.contacto}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="commer_id"
                required
                onChange={handleChange}
              >
                <option value={0}>Comercial</option>
                {comerciales?.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
          <input type="submit" value="Guardar y publicar"></input>
        </form>
      </div>
    </>
  );
}
 
export default NuevoAAFF;