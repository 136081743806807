import React, { useState, useEffect, useContext } from "react"
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { ventas } from "../../../functions/ventasPrueba";
import { Link } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import ComunidadDocs from "../../../components/Comunidad/ComunidadDocs";
// import { generarPDF } from "../../../services";

const Ventas = () => {
//   const [pdfUrl, setPdfUrl] = useState('');

//   const convertirAPdf = async () => {
//     try {
//       const response = await generarPDF();
//       const url = URL.createObjectURL(response.data);
//       setPdfUrl(url);
//       // Abre el PDF en una nueva ventana o pestaña
//       const newWindow = window.open(url, '_blank');
//       if (newWindow) {
//           console.log(url)
//         newWindow.focus();
//       } else {
//         alert('La ventana emergente está bloqueada. Habilita las ventanas emergentes en tu navegador para ver el PDF.');
//       }
//     } catch (error) {
//       console.error('Error al generar el PDF:', error);
//     }
//   };

//   return (
//     <div>
//       <button onClick={convertirAPdf}>Generar PDF</button>
//       {pdfUrl && <embed src={pdfUrl} width="100%" height="500" type="application/pdf" />}
//     </div>
//   );
    return ( 
        <>
            <BreadCrumb rutas={[{path:'/servicios', label:`Servicios`}]} style={{ margin: '0 10px'}} />
            <div className="listPage">
                <div className="gridCard">
                    <div className="gridHead">
                        SERVICIOS
                    </div>
                </div>
            </div>
            <ComunidadDocs />
        </>
     );
}
 
export default Ventas;