import React from "react";
import SmoothModal from "../Modals/SmoothModal";
import { Box, Button, Select, TextField, Typography } from "@mui/material";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";

import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../functions/funciones";

import { ordenarProvinciasYMunicipios } from "../../functions/ordenarListas";

import { useState } from "react";
import { useEffect } from "react";
import LaeButton from "../Buttons/LaeButton";

const EditarProveedor = ({
  data,
  showModal,
  onClose,
  handleChange,
  onSubmit,
}) => {
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, data?.provincia)
    );
  }, [data]);
  return (
    <SmoothModal open={showModal} onClose={onClose} sx={{ width: 800 }}>
      <Box className="lae-flex lae-flex-column">
        <Typography variant="h4">Editar Datos</Typography>
        <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
          <TextField
            id="razonSocial"
            name="razonSocial"
            label="Nombre del Proveedor"
            fullWidth
            size="small"
            defaultValue={data?.razonSocial}
            onChange={handleChange}
          ></TextField>
          <TextField
            id="direccion"
            name="direccion"
            label="Dirección"
            fullWidth
            size="small"
            defaultValue={data?.direccion}
            onChange={handleChange}
          ></TextField>
        </Box>
        <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
          <Select
            native
            name="municipio"
            id="municipio"
            style={{ textTransform: "uppercase" }}
            onChange={handleChange}
            defaultValue={data?.municipio}
            fullWidth
            size="small"
          >
            <option value="">Municipio</option>
            {listadoMunicipios
              .sort(ordenarProvinciasYMunicipios)
              ?.map((e, i) => {
                return (
                  <option key={i} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
          </Select>
          <TextField
            id="nombreContacto"
            name="nombreContacto"
            label="Persona de Contacto"
            fullWidth
            size="small"
            defaultValue={data?.nombreContacto}
            onChange={handleChange}
          ></TextField>
        </Box>
        <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
          <TextField
            id="codigoPostal"
            name="codigoPostal"
            label="C.P"
            fullWidth
            size="small"
            defaultValue={data?.codigoPostal}
            onChange={handleChange}
          ></TextField>
          <TextField
            id="emailContacto"
            name="emailContacto"
            label="Email de Contacto"
            fullWidth
            size="small"
            defaultValue={data?.emailContacto}
            onChange={handleChange}
          ></TextField>
        </Box>
        <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
          <Select
            native
            name="provincia"
            id="provincia"
            style={{ textTransform: "uppercase" }}
            onChange={handleChange}
            defaultValue={data?.provincia}
            size="small"
            fullWidth
          >
            <option value="">Provincia</option>
            {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
              return (
                <option key={i} value={e.name}>
                  {e.name}
                </option>
              );
            })}
          </Select>
          <TextField
            type="tel"
            id="telefonoContacto"
            name="telefonoContacto"
            label="Teléfono"
            fullWidth
            size="small"
            defaultValue={data?.telefonoContacto}
            onChange={handleChange}
          ></TextField>
        </Box>
        <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
          <Button
            variant="contained"
            sx={{
              flex: 1,
              backgroundColor: "whitesmoke",
              color: "#92004D",
              ":hover": { backgroundColor: "#92004D", color: "white" },
              margin: 0,
              p: 1,
            }}
            disableElevation
            onClick={onClose}
          >
            Cancelar
          </Button>
          <LaeButton sx={{ flex: 1 }} onClick={onSubmit}>
            Guardar
          </LaeButton>
        </Box>
      </Box>
    </SmoothModal>
  );
};

export default React.memo(EditarProveedor);
