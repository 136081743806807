import axios from "axios";

const urlPath = "propertie";

export const getServiciosFincas = async () => {
  try {
    const response = await axios.get(`${urlPath}/services`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getFincas = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getFinca = async (nif) => {
  console.log(nif);
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getFincasAAFF = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });
    // console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getFincasTech = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/PropertiesTech`, data.filtros, {
      params: data.params,
    });
    // console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getSuppListPropertiAaff = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/SuppListPropertiAaff`, data.filtros, {
      params: data.params,
    });
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const AaffListPropertie = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/AaffListPropertie`, data.filtros, {
      params: data.params,
    });
    // console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteFinca = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const deleteFincasAAFF = async (nif) => {
  console.log(nif);
  /*  const data = {
    nifAAFF: nif,
  }; */
  try {
    const response = await axios.delete(`${urlPath}/aaff/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const cambiarFinca = async (nif, data) => {
  try {
    const response = await axios.put(`${urlPath}/${nif}`, data);
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const renovarFinca = async (nif) => {
  console.log(nif);
  const data = {
    renovada: true,
    pendiente: false,
    fechaRenovacion: new Date(),
  };
  try {
    const response = await axios.put(`${urlPath}/${nif}`, data);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteMatchFincaProv = async (idFinca, idProveedor) => {
  console.log(idFinca, idProveedor);

  let datos = {
    prop_id: idFinca,
    supp_id: idProveedor,
  };
  // let datos = {
  //   prop_id: 6,
  //   supp_id: 12
  // }
  console.log(datos)
  try {
    const response = await axios.post(`${urlPath}/delete/matchSupp`, datos);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const getListarDocsFincaProv = async (sale_id) => {
  try {
      const response = await axios.get(`docsProps/listDocs/${sale_id}`);
      return response.data;
  } catch (err) {
      console.log(err.response);
      return err.response;
  }
};

export const guardarFechaDocLeido = async (doc_id, supp_id) => {
  console.log(doc_id, supp_id)
  try {
    const response = await axios.post(`docsProps/read-doc/${doc_id}`, supp_id);
    console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const renovacionFincasAAFF = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`sale/renovate-sales-aaff`, data);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};