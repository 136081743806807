import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../functions/ordenarListas";
import { getAllCommercials, getAllConsultores, getAllTecnicos } from "../../services";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { UsuarioContext } from "../../providers/userContext";

const FiltrosAlertasProv = ({ onFilter }) => {

  const user = useContext(UsuarioContext)
  const [busqueda, setBusqueda] = useState({});
  const [comercial, setComercial] = useState([]);
  const [consultor, setConsultor] = useState([]);
  const [tecnico, setTecnico] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, busqueda.provincia)
    );
  }, [busqueda]);

  const getDatas = async () => {
    const consul = user.rol === "TECH" || user.rol === "SUPP"  ? '' : await getAllConsultores();
    setConsultor(consul);
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    setBusqueda({
      ...busqueda,
      [e.target.name]: e.target.value,
    });
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name });
      }
    });
    setListadoMunicipios(municipios);
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    
    // console.log(busqueda);
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{width:"100%"}}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <FormControl variant="outlined" className='filtros-select'>
              <InputLabel id="consultor-label">Consultor</InputLabel>
              <Select
                labelId="consultor-label"
                id="consultor-select"
                label="Consultor"
                name="consultor"
                className="filtros"
                value={busqueda.consultor}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {consultor?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={Number(e.id)}>
                      {e.nombre + " " + e.apellidos}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>       
      </div>
      {/* <div className="buscador">
        <div>
            <select name="consultor" onChange={handleChange}>
                <option value=""></option>
                {consultor?.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </option>
                  );
                })}
            </select>
        </div>
        <div>
          <button onClick={() => getBusqueda()}>
            <BsSearch />
          </button>
        </div>
      </div> */}
      <style jsx="true">
        {`
          .btn-buscador {
            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004D;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width:40px;
            height:40px;
            margin: 5px 0;
          }
          .btn-buscador:hover{
            color: black;
            background-color: #dee2e6;
          }    
        `}
      </style>
    </>
  );
};

export default FiltrosAlertasProv;
