import React, {useState, useEffect, useContext} from 'react'
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withRouter } from "../../providers/withRouter";
import { Box, Button } from "@mui/material";
import { DataTable } from "../../components";
import {
    getReclamationAlert,
    validateReclamationAlert
  } from "../../services";
import { formatoFechasEs } from '../../functions/funciones';
import FiltrosAlertasBienvenidaAAFF from '../filtros/filtrosAlertasBienvenidaAAFF';
import { FaCheck } from "react-icons/fa";
import { UsuarioContext } from '../../providers/userContext';

const ReclamationAlert = () => {

    const user = useContext(UsuarioContext)
    const [loading, setLoading] = useState(true);
    const [busqueda, setBusqueda] = useState({});
    const [recargar, setRecargar] = useState(false);
    const [limite, setLimite] = useState(0);
    const [validating, setValidating] = useState(false);
    const [primeraConsulta, setPrimeraConsulta] = useState(true);
    const [data, setData] = useState({
      meta: {},
      rows: [],
    });



    const fetchData = async ({ offset = 1, limit = limite, filtros = busqueda } = {}) => {
        setLimite(limit)
        if (filtros.consultor === undefined && user.rol ==='CONSUL') {
            filtros.consultor = user.id
        } else if (filtros.consultor === 0) {
            delete filtros.consultor
        }
        const payload = {
          filtros,
          params: { page: offset, limit },
        };
        try {
          setLoading(true);
          const response = await getReclamationAlert(payload);
          const { meta, data } = response;
    
          setData({
            meta,
            rows: formatData(data),
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          setRecargar(false);
        }
    };

    useEffect(() => {
        fetchData()
    },[recargar])

    const formatData = (data) => {
        return data.map((row) => ({
          aff_name:
            row.razonSocial || row.propertie?.aaff?.nombreContacto,
          nif: row.nif,
          fechaAlta: row.createdAt,
          consultorCae: row.consultants?.find(objeto => objeto?.r_consul_servsgroups_aaffs.group_id === 1),
          validar: row.id
        }));
    };

    const comunidadesRows = [
        {
          id: "aff_name",
          numeric: false,
          disablePadding: false,
          label: "ADMINISTRADOR",
          overrideFunc: (data, row) => (
            <Link to={"/aaff/" + row.nif + "/comunidades"}>
              {data}
            </Link>
          )
        },
        {
          id: "nif",
          numeric: false,
          disablePadding: false,
          label: "NIF",
        },
        {
            id: "fechaAlta",
            numeric: false,
            disablePadding: false,
            label: "FECHA ALTA",
            overrideFunc: (data) => data ? formatoFechasEs(data) : '',
          },
        {
          id: "consultorCae",
          numeric: false,
          disablePadding: false,
          label: "CONSULTOR CAE",
          overrideFunc: (data, row) => (
            <span>
              {data ? data?.nombre+" "+data?.apellidos : ''}
            </span>
          )
        },
        {
          id: "validar",
          numeric: false,
          disablePadding: false,
          label: "Validar",
          overrideFunc: (data, row) => (
            <>
              <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px'}} onClick={ () => {validarAlerta(data)}} disabled={validating}><FaCheck /></Button>
            </>
          )
        },
    ];
    
    const validarAlerta = async (id) => {
        setValidating(true);
        try {
            console.log("id"+ id)
            const toastInfo = toast.info("Validando...", { autoClose: false });
            const response = await validateReclamationAlert(id)
            if(response.status === 201){
                toast.update(toastInfo, {
                render: "Alerta validada",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
                });
                setRecargar(true)
            }
            if(response.status === 403){
                toast.update(toastInfo, {
                render: "No se ha podido validar la alerta",
                type: toast.TYPE.ERROR,
                autoClose: true,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error("Error al validar la alerta");
        }finally {
            setValidating(false);
        }
    }

    const filtrarDatos = (filtros) => {
        setBusqueda(filtros)
        fetchData({ offset: 1, limit: limite, filtros: filtros });
    } 

    return ( 
        <>
        {console.log(recargar)}
        <div className="pageContent tableContainer">
        
        <FiltrosAlertasBienvenidaAAFF onFilter={filtrarDatos} />  
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay tareas pendientes"}
              />
            </Box>
          </Box>
        </div>
      </div>
        </>
     );
}
 
export default withRouter(ReclamationAlert);