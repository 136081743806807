import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { docsPendientesProv, formatoFechasEs } from "../../../functions/funciones";
import Add from "../../../icons/add.svg";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { deleteProveedor, getProveedores } from "../../../services";
import { daysDiff } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { BsSearch } from "react-icons/bs";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import { UsuarioContext } from "../../../providers/userContext";
import Warning from "../../../icons/warning.svg";
import Delete from "../../../icons/delete.svg";

const proveedoresRows = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: ""
  },
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => 
    (
      <Link to={"/proveedores/" +row.nif}>
        {data}
      </Link>
    )
  },
  {
    id: "ultimaReclamacion",
    numeric: false,
    disablePadding: false,
    label: "ÚLTIMA RECLAMACIÓN",
    overrideFunc: (data) => data ? formatoFechasEs(data) : '',
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "telefono",
    numeric: false,
    disablePadding: false,
    label: "TELÉFONO",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "EMAIL",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    overrideFunc: (row) => (
      <ProgressBarWithColors
        value={row}
        label={
          <Typography>
            {row}
          </Typography>
        }
      />
    ),
  },
  {
    id: "docsPendientes",
    numeric: false,
    disablePadding: false,
    label: "",
    overrideFunc: (row) => (
      <>
        {row > 0 ? 
          <img src={Warning} width={15} alt="pendientes"/>
        : null}
      </>
    ),
  },
  {
    id: "eliminarProv",
    numeric: false,
    disablePadding: false,
    label: "",
  },
]


const ProveedoresLista = () => {
  
  const user = useContext(UsuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData();
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    setLimite(limit)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      const response = await getProveedores(payload);
      const { meta, data } = response;
      console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      id:row.id,
      proveedor_name: row.razonSocial,
      ultimaReclamacion: row.ultimaReclamacion === null ? row.createdAt : row.ultimaReclamacion,
      nif: row.nif, 
      
      telefono: row.telefonoContacto,
      email: row.emailContacto,
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs,
      docsPendientes: docsPendientesProv(row),
      eliminarProv: <img src={Delete} onClick={() => eliminarProv(row.nif).then( res => handleSubirDocsCallback(res))} width={15} alt="eliminar"/>
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const eliminarProv = async (nif) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(nif)
        await deleteProveedor(nif).then(res =>{
          resolve(true);
        }).catch(reject)
        
      } catch (error) {
        reject(error);
      }
    })
  }

  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:'Administrador'}, {path:`/proveedores`, label: 'Proveedores'}]} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
          <div className="contentBar">
          <div className="contentBtns">
              {/* <div className="listadoBtn" style={{ marginLeft: 0}} >
                <BsUpload alt="subir listado" style={{ marginRight: 5 }} />
                listado
              </div>
              <div className="descargarBtn">
                <a href="files/plantillaAAFF.xlsx" download="plantillaAAFF.xlsx">
                  <BsDownload alt="Descargar plantilla" style={{ marginRight: 5 }} />
                  plantilla
                </a>
              </div> */}
              
          </div>
            <Link to="/proveedores/nuevo" target={"_blank"}>
              <div className="contentButton">
                  <img src={Add} alt="Más" />
              </div>
            </Link>
        </div>
            <div style={{ display: "flex" }}>
              <FiltrosProveedores onFilter={filtrarDatos} /> 
              {/* <div className="exportar">
                <ExportarProveedoresLista busqueda={Resbusqueda} /> 
              </div> */}
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
