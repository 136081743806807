import React, {useState, useEffect} from "react";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoLAE from "../../../images/LogoLAE.png"
import planEmerg_1 from "../../../images/planEmerg_1.png"
import planEmerg_2 from "../../../images/planEmerg_2.png"
import planEmerg_3 from "../../../images/planEmerg_3.png"
import planEmerg_4 from "../../../images/planEmerg_4.png"
import planEmerg_5 from "../../../images/planEmerg_5.png"
import CircularProgress from '@mui/material/CircularProgress';
import { getFinca, getSale, getVisitSheet } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";

const PlanEmergenciaRender = (props) => {
    
    //proveedores: [{servicio: 1, nombre:"b", telefono:"123456789"}, {servicio: 2, nombre:"c", telefono:"444555666"}],
    
    const [isLoading, setIsLoading] = useState(false)
    const [proveedores, setProveedores] = useState([])
    const [finca, setFincas] = useState({})
    
    const fetchData = async () => {
        const response = await getFinca(props.params.nif)
        let finca = response
        const respSale = await getSale(props.params.id);
        const visitSheet_id = respSale.detailsCae[0].visitSheet_id;
        const respVisit = await getVisitSheet(visitSheet_id)
        finca.imgURL = `${process.env.REACT_APP_STORAGE_URL}${respVisit.data.img}`
        finca.fechaVisita = formatoFechasEs(finca.visitSheets[0].createdAt)
        setFincas(finca);
        
    }

    useEffect(() =>{
        fetchData();
    },[])

    return ( 
        <>
            {isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> :
                <>
                    <table className="tablePage">
                    <thead><tr><td>
                        <div className="header-space">&nbsp;</div>
                    </td></tr></thead>
                    <tbody>
                        <h1>PLAN DE EMERGENCIA</h1>
                        <h2><b>Comunidad de propietarios:</b> {finca?.razonSocial}</h2>
                        <h2><b>Centro de Trabajo:</b> {finca?.direccion}</h2>
                        <h2><b>Fecha:</b> {finca?.fechaVisita}</h2>
                        <div id="imgFinca" style={{ width: '100%', paddingTop: '66.66%', backgroundImage: `url("${finca?.imgURL}")`, backgroundPosition: 'center', backgroundSize: 'cover', marginTop: 100 }}></div>
                        <div className="pagebreak"></div>
                        <h3>1. Introducción</h3>
                        <p>
                            Según el artículo 20 de la Ley de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003: “<i>el empresario deberá analizar las posibles situaciones de emergencia y adoptar las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores</i>”<br />
                            Se entiende por emergencia, aquellas situaciones derivadas de sucesos que ocurren de una forma anómala, repentina y no deseada, que liberan energía muy rápidamente y en tal cantidad que pueden poner en peligro a muchas personas, afectar a varias instalaciones, e incluso al entorno de la comunidad de propietarios, si no se logran controlar en los primeros momentos.<br />
                            Las comunidades de propietarios, que cuentan con un plan de emergencia, son capaces de reanudar la actividad productiva con mayor rapidez y menor coste que las que no lo tienen realizado, en caso de emergencia. Por otra parte, las compañías de seguros valoran la existencia y puesta en práctica de los ensayos de los referidos planes.<br />
                            En muchas ocasiones, más de las deseadas, no existe una planificación de la emergencia, porque:
                            <ul>
                                <li>Es difícil imaginar que a uno le suceda.</li>
                                <li>Requiere invertir tiempo y esfuerzo.</li>
                            </ul>
                            <p>Existen diferentes factores que pueden producir una situación de emergencia:</p>
                            <ul>
                                <li>Fallos humanos en la actividad de la comunidad de propietarios.</li>
                                <li>Fallos técnicos.</li>
                                <li>Defectos de diseño de instalaciones y organización.</li>
                                <li>Causas de la naturaleza: catástrofes naturales (riadas, terremotos, etc.)</li>
                                <li>Causas del entorno: por instalaciones externas, etc.</li>
                            </ul>
                        </p>
                        <div className="pagebreak"></div>
                        <h3>2. Plan de emergencia</h3>
                        <h4>2.1. Objeto</h4>
                        <p>
                            El plan de emergencia pretende conseguir que cualquier incidente, que pueda afectar a las personas, las propias instalaciones y el edificio, así como la continuidad de las instalaciones, tenga una incidencia mínima o nula. Para hacerlo posible, se debe conseguir delante de una emergencia, una buena coordinación, en el tiempo y el espacio, tanto de las personas afectadas como de los medios de protección existentes, de manera que sean utilizados eficazmente para obtener, según la emergencia:
                        </p>
                        <ul>
                            <li>Una rápida evacuación de los locales</li>
                            <li>La extinción del incendio</li>
                            <li>La limitación de los daños materiales</li>
                        </ul>
                        <h4>2.2. Factores de riesgo. Clasificación de la emergencia</h4>
                        <p>Según la gravedad, las emergencias se clasifican en:</p>
                        <ul>
                            <li><b>Conato de emergencia: </b>Accidente que puede ser controlado y dominado de forma sencilla y rápida por el personal y los medios de protección del local, dependencia o sector.</li>
                            <li><b>Emergencia parcial: </b>Accidente que para ser dominado requiere la actuación de los equipos especiales de emergencia del sector. Los efectos de la emergencia parcial quedarán limitados a un sector y no afectarán a otros sectores colindantes ni a terceras personas.</li>
                            <li><b>Emergencia general: </b>Accidente que precisa la actuación de todos los equipos y medios de protección del establecimiento y la ayuda de medios de socorro y salvamento exteriores. La emergencia general comportará la evacuación de las personas de determinados sectores.</li>
                        </ul>
                        <h4>2.3. Acciones en caso de emergencia</h4>
                        <p>Las diversas emergencias requerirán la intervención de personas y medios para garantizar en todo momento:</p>
                        <ul>
                            <li>La alerta, que de la forma más rápida posible, pondrá en acción a los equipos del personal de primera intervención interior e informará a los restantes equipos del personal interior y a las ayudas exteriores.</li>
                            <li>La alarma para la evacuación de los ocupantes.</li>
                            <li>La intención para el control de las emergencias.</li>
                            <li>El apoyo para la recepción e información a los servicios de ayuda exterior.</li>
                            <li>Primeros auxilios.</li>
                        </ul>
                        <h4>2.4. Equipos de emergencia</h4>
                        <p>
                            Son el conjunto de personas especialmente entrenadas y organizadas para actuar en caso de accidente/incidente dentro del ámbito del establecimiento. La misión fundamental de estos equipos es tomar las precauciones pertinentes para que las condiciones que puedan originar un accidente no se vuelvan a dar.<br />
                            <div className="pagebreak"></div>
                            Cada uno de los componentes de los equipos de emergencia deberá de:
                        </p>
                        <ol type="a">
                            <li>Estar informado del riesgo general y particular que presentan los diferentes procesos dentro de la actividad.</li>
                            <li>Señalar las anomalías que se detectan y verificar que han estado subsanadas. </li>
                            <li>Tener conocimiento de la existencia y uso de los medios materiales de que se disponga.</li>
                            <li>Hacerse cargo del mantenimiento de medios.</li>
                            <li>Estar capacitado para suprimir las causas que puedan provocar cualquier anomalía.</li>
                            <li>Combatir el fuego desde su descubrimiento.</li>
                            <li>Prestar los primeros auxilios a las personas accidentadas.</li>
                            <li>
                                Coordinarse con los miembros de otros equipos para anular los efectos de los accidentes o reducirlos al mínimo.
                                <p>En función de las acciones que desarrollan los miembros de los equipos se denominan:</p>
                                <ul>
                                    <li>Equipos de alarma y evacuación (EAE). Sus componentes realizarán acciones dirigidas a asegurar una evacuación total y ordenada del sector y garantizar que hayan dado la alarma.</li>
                                    <li>Equipos de primeros auxilios (EPA). Sus componentes prestarán los primeros auxilios a los lesionados por la emergencia. Estarán formados por personas que dispongan de conocimientos sobre socorrismo y primeros auxilios.</li>
                                    <li>Equipos de primera intervención (EPI). Sus componentes acudirán al lugar donde se haya producido la emergencia con el objetivo de intentar controlarlo. Cualquier trabajador que descubra el principio de un incendio dará la alarma y si tiene conocimientos de extinción utilizará los extintores portátiles más cercanos hasta la llegada del Equipo de Primera Intervención de la planta, zona o sector.</li>
                                    <li>Equipos de segunda intervención (ESI). Los componentes actuaran cuando la emergencia no pueda ser controlada por los equipos de primera intervención, apoyando a los servicios de ayuda exterior cuando su actuación sea necesaria.</li>
                                    <li>Jefe de Intervención. Valorará la emergencia y asumirá la dirección y coordinación de los equipos de intervención.</li>
                                    <li>Jefe de Emergencia.  Es el máximo responsable en caso de emergencia. Ha de ser una persona responsable, con capacidad de decisión y conocimientos del edificio. Desde el Centro de comunicaciones del establecimiento y en función de la información que facilite el Jefe de Intervención sobre la evolución de la emergencia, enviará al área siniestrada las ayudas internas disponibles y solicitará las externas que sean necesarias para control de la misma.  A la llegada de los bomberos, se les informará de la situación y estado de la emergencia.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>
                            La Orden Ministerial de 29 de noviembre de 1984 hace una serie de recomendaciones sobre los equipos de emergencia que ha de tener los edificios, en función de la clasificación de su riesgo. A nivel orientativo, la constitución de los equipos de emergencia se ha de adaptar a las características y al número de personas que haya en el edificio. Se recomendará constituir los equipos siguientes:<br />
                        </p>
                        <table className="tableRiesgos">
                            <tr>
                                <th>RIESGO ALTO</th>
                                <th>RIESGO MEDIO</th>
                                <th>RIESGO BAJO</th>
                            </tr>
                            <tr>
                                <td>Equipos de Alarma y Evacuación</td>
                                <td>Equipos de Alarma y Evacuación</td>
                                <td>Equipos de Alarma y Evacuación</td>
                            </tr>
                            <tr>
                                <td>Equipo de Primeros Auxilios</td>
                                <td>Equipos de Primera Intervención</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Equipos de Primera Intervención</td>
                                <td>Equipo de Segunda Intervención</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Equipo de Segunda Intervención</td>
                                <td>Jefe de Intervención</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Jefe de Intervención</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Jefe de Emergencia</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </table>
                        <div className="pagebreak"></div>
                        <h3>3. Implantación</h3>
                        <h4>3.1. Responsabilidad</h4>
                        <p>De conformidad con lo previsto en la legislación vigente, el personal directivo, técnico, mandos intermedios y trabajadores de los establecimientos estarán obligados a practicar en los planos de autoprotección.</p>
                        <h4>3.2. Organización</h4>
                        <p>El titular de la actividad podrá delegar la coordinación de las acciones necesarias para la implantación y mantenimiento del plan de autoprotección en un Jefe de Seguridad, que en caso de emergencia podrá asumir las funciones del Jefe de Emergencia.</p>
                        <h4>3.3. Medios técnicos</h4>
                        <p>
                            Las instalaciones, tanto las de protección contra incendios como las que son susceptibles de ocasionarlos, serán sometidas a las condiciones generales de mantenimiento y uso establecidas en la legislación vigente y en la Norma Básica de Edificación-Condiciones de Protección contra incendios.<br />
                            Cuando así lo exija la Reglamentación vigente o el plan tipo para cada uso, se dotará al establecimiento de todas las instalaciones de prevención precisas.
                        </p>
                        <h4>3.4. Medios humanos</h4>
                        <p>Se programarán, al menos, una vez al año, cursos de formación y adiestramiento para equipos de emergencia y sus responsables.</p>
                        <h4>3.5. Programa de implantación</h4>
                        <p>Se programará, ateniendo a las prioridades y con el calendario correspondiente, la siguiente actividad: inventario de los medios técnicos de autoprotección. </p>
                        <h4>3.6. Programa de mantenimiento</h4>
                        <p>
                            Se preparará un programa anual con el correspondiente calendario,  que comprenderá las siguientes actividades:
                            <ol type="a">
                                <li>Mantenimiento de las instalaciones susceptibles de provocar un incendio</li>
                                <li>Mantenimiento de las instalaciones de detección, alarma y extinción de incendios según lo establecido en la Norma Básica de la Edificación</li>
                                <li>Inspecciones de Seguridad</li>
                            </ol>
                        </p>
                        <h4>3.7. Investigación de siniestros</h4>
                        <p>Si se produjera una emergencia en el establecimiento, se investigarán las causas que posibilitaron su origen, propagación y consecuencias, se analizará el comportamiento de las personas y los equipos de emergencia y se adoptarán las medidas correctoras precisas.</p>
                        <div className="pagebreak"></div>
                        <h4>3.8. Comportamiento en caso de emergencia</h4>
                        {/* eslint-disable-next-line */}
                        <img src={planEmerg_1} style={{ width: '100%', marginTop: 24 }} />
                        <div className="pagebreak"></div>
                        {/* eslint-disable-next-line */}
                        <img src={planEmerg_2} style={{ width: '100%' }} />
                        <div className="pagebreak"></div>
                        {/* eslint-disable-next-line */}
                        <img src={planEmerg_3} style={{ width: '100%' }} />
                        <div className="pagebreak"></div>
                        {/* eslint-disable-next-line */}
                        <img src={planEmerg_4} style={{ width: '100%' }} />
                        <div className="pagebreak"></div>
                        {/* eslint-disable-next-line */}
                        <img src={planEmerg_5} style={{ width: '100%' }} />
                        <div className="pagebreak"></div>
                        <div className="pageTitle">PLAN DE ACTUACIÓN EN CASO DE ACCIDENTE</div>
                        <ol type="a">
                            <li>En caso de accidente leve de un trabajador que requiera atención médica: Realizar la primera cura con el material sanitario disponible en el botiquín de la comunidad de propietarios y trasladar al accidentado al Centro Asistencial de la Mutua o a un Centro de urgencias.</li>
                            <li>En caso de accidente grave de un trabajador: Trasladar urgentemente al trabajador al Hospital señalado en el apartado 1.3 del presente informe, o al Centro de Urgencias más cercano.</li>
                        </ol>
                        <p>En los dos casos de accidente se tiene que activar el sistema de emergencia (PAS: Proteger, Avisar, Socorrer). Al comunicarse, se tiene que dar un mensaje preciso sobre:</p>
                        <ul>
                            <li>Lugar donde ha sucedido el accidente.</li>
                            <li>Tipo de accidente (intoxicación, quemada térmica o química, herida, etc.).</li>
                            <li>Número de víctimas.</li>
                            <li>Estado aparente de las víctimas (conciencia, sangran, respiran, etc.).</li>
                            <li>Si el aviso se realiza por teléfono, no colgar antes de que el interlocutor lo haya autorizado, ya que puede necesitar otras informaciones complementarias.</li>
                            <li>Se dispondrá de una persona que reciba y acompañe a los servicios de socorrismo con la finalidad de guiarlos rápidamente hasta el lugar del accidente.</li>
                        </ul>
                        <h4>Como actuar si se tiene que socorrer a un herido</h4>
                        <h5>1. Salpicaduras en los ojos y sobre la piel</h5>
                        <p>Sin perder un instante lavarse con agua durante 10 o 15 minutos, empleando si es necesario la ducha de los vestuarios (o de seguridad si existiesen); quitarse la ropa y objetos previsiblemente mojados del producto.</p>
                        <h5>2. Mareo o pérdida de conocimiento debido a una fuga tóxica que persista.</h5>
                        <p>Nos tenemos que proteger del medio con un aparato respiratorio ante de aproximarse a la víctima. Trasladar al accidentado a un lugar seguro i dejarlo recostado sobre el lado izquierdo. Aflojar la ropa y todo lo que pueda oprimirlo, verificando si ha perdido el conocimiento y si respira; tomar el pulso. Activar el PAS, y practicar si fuese necesario, la reanimación cardiorrespiratoria. No subministrar alimentos, bebidas ni productos para activar la respiración.</p>
                        <h5>3. Electrocución</h5>
                        <p>La electrocución o choque eléctrico tiene lugar cuando, por un contacto eléctrico directo o indirecto, una persona pasa a formar parte de un circuito eléctrico, pasando por su organismo una determinada intensidad eléctrica durante un tiempo. La intensidad depende del voltaje y de la resistencia del organismo, que  a su vez, depende del camino recorrido y de factores fisiológicos. Las acciones para llevar a cabo cuando alguno queda atrapado por la corriente son las siguientes,</p>
                        <ul>
                            <li>Cortar la alimentación eléctrica del aparato causante del accidente antes de acercarse a la víctima para evitar otro accidente y retirar al accidentado.</li>
                            <li>Activar el PAS y practicar, si es necesario, la reanimación cardiorrespiratoria.</li>
                            <li>No suministrar alimentos, bebidas ni productos para activar la respiración.</li>
                        </ul>
                        <div className="pagebreak"></div>
                        <h5>4. Quemadas térmicas</h5>
                        <p>Las instrucciones básicas por el tratamiento de quemadas térmicas son: lavar abundantemente con agua fría para enfriar la zona quemada, no quitarse la ropa pegada en la piel, tapar la parte quemada con la ropa limpia. Se tiene que acudir siempre al médico, aunque la superficie afectada y la profundidad sean pequeñas. Son recomendaciones específicas en estos casos;</p>
                        <ul>
                            <li>No aplicar nada en la piel (ni pomada, ni grasa, ni desinfectante)</li>
                            <li>No enfriar demasiado al accidentado.</li>
                            <li>No dar bebidas ni alimentos.</li>
                            <li>No romper las ampollas.</li>
                            <li>No dejar solo al accidentado.</li>
                        </ul>
                        <h5>5. Intoxicación digestiva</h5>
                        <p>Se tiene que tratar en función del contenido del tóxico ingerido, por lo cual se tiene que disponer de información a partir de la etiqueta y de la ficha de seguridad. La actuación inicial está encaminada a evitar la acción directa del tóxico mediante su neutralización o evitar su absorción por el organismo. Posteriormente, o en paralelo, se tratan los síntomas causados por el tóxico. Es muy importante la atención médica rápida, lo cual requerirá el traslado del accidentado, que debe llevarse a cabo en condiciones adecuadas. No se tiene que provocar el vómito cuando el accidentado presente convulsiones o esté inconsciente, o bien se trata de un producto corrosivo o volátil. Para evitar la absorción del tóxico se emplea carbón activo o agua albuminosa. Existe una lista de antídotos recomendados por la UE (Anexo III de la Resolución 90/329/03). En el caso de pequeñas ingestas de ácidos, beber soluciones de bicarbonato, mientras que se recomienda tomar bebidas ácidas. Servicio de Toxicología: <b>91 562 04 02</b>.</p>
                        <div className="pagebreak"></div>
                        <div className="pageTitle">PLAN DE ACTUACIÓN EN CASO DE INCENDIO</div>
                        <h5>Clasificación de las emergencias</h5>
                        <p>
                            En función de la magnitud del siniestro y de la gravedad de sus posibles consecuencias, las emergencias las clasificamos en:
                        </p>
                        <ul>
                            <li>Conato de emergencia: Accidente que puede ser controlado y dominado de forma sencilla y rápida por el personal de la comunidad de propietarios, con los medios de protección de la misma.</li>
                            <li>Emergencia general: Accidente que, además de necesitar la actuación de todos los equipos y medios de protección de la comunidad de propietarios, necesita la ayuda de medios de socorro y salvamente externo.</li>
                        </ul>
                        <h5><i>Acciones a tomar</i></h5>
                        <p>
                            En caso de incendio, la intervención de personas y medios de la comunidad de propietarios garantizarán:<br />
                            <b>Alerta: </b>Cualquier persona que se encuentre presente en el área de un siniestro dará alerta al resto de personal, ya sea conato de emergencia o emergencia general. La alerta de las ayudas externas, únicamente se dará en el caso de necesidad (ambulancia) o Emergencia General.<br />
                            <b>Evacuación: </b>Todo el personal de la comunidad de propietarios está capacitado para realizar la evacuación de las personas que ocupan la zona del siniestro. Únicamente se procederá a la evacuación que en algún momento pueden haber varias personas en las instalaciones de la comunidad de propietarios.<br />
                            <b>Intervención: </b>La persona designada intervendrá, ya sea en caso de Conato de Emergencia o de Emergencia General, utilizando los medios existentes (extintores) y suspendiendo el fluido eléctrico si procede.<br />
                            <b>Recepción de ayuda exterior: </b>Cualquier persona de la comunidad de propietarios puede recibir y ser la interlocutora con los mandos de los Servicios Exteriores de Emergencia, para coordinar con las actuaciones y ganar en eficacia.<br />
                        </p>
                        <h5><u>Como evacuar un edificio en llamas</u></h5>
                        <ul>
                            <li>El último en salir de la habitación cerrará la perta (nunca con llave), comprobando que no quede nadie dentro. El cerrar las puertas dificulta el esfuerzo de rescate y de búsqueda por parte del departamento de Bomberos.</li>
                            <li>Dirigirse hacia la salida más cercana.</li>
                            <li>No utilizar los ascensores bajo ningún concepto.</li>
                            <li>Manténgase cerca del suelo para evitar humos y gases tóxicos. La mejor atmosfera respirable se encuentra a nivel del suelo, así que circule a cuatro patas.</li>
                            <li>Si es posible cubrirse la boca y la nariz con un trapo o pañuelo húmedo para facilitar la respiración.</li>
                            <li>Si trabaja en un edificio con diferentes pisos, las escaleras serán su primera ruta de escapada. Una vez en la escalera, proceda hacia el primer piso, y nunca se dirija hacia un piso más alto.</li>
                            <li>Una vez fuera del edificio, diríjase al área preestablecida por tal de facilitar el recuento de personal.</li>
                        </ul>
                        <div className="pagebreak"></div>
                        <h5>¿Qué hacer si se está atrapado en un edificio en llamas?</h5>
                        <p>
                            Si está probando de escapar de un fuego, nunca debe abrir ninguna puerta cerrada sin palparla. Utilice la parte posteriormente de la mano para evitar quemadas. Si la puerta está caliente, busque otra salida. Si no existe otra salida, selle las grietas alrededor de las puertas y ventanas con lo que tenga a mano.<br />
                            Si respira con dificultad, pruebe de ventilar la habitación y utilice un pañuelo mojado.<br />
                        </p>
                        <h5>¿Qué hacer si una persona está envuelta en llamas?</h5>
                        <p>Si usted se encuentra envuelta en llamas:</p>
                        <ul>
                            <li>Deténgase</li>
                            <li>Tírese al suelo</li>
                            <li>Ruede sobre sí mismo</li>
                        </ul>
                        <p>
                            Esto le apagará las llamas y le puede salvar la vida. <b>RECUERDE LOS PASOS ESTABLECIDOS.</b><br />
                            Si un compañero está envuelto en llamas, se tendrá que apagar envolviéndolo con una manta, alfombra, abrigo, esto puede salvarlo de graves quemadas e incluso de la muerte.<br />
                        </p>
                        <div className="pagebreak"></div>
                        <h3>4. Recomendaciones</h3>
                        <ul>
                            <li>Los aparatos eléctricos no indispensables se deben apagar y desconectar al final de la jornada.</li>
                            <li>No sobrecargar los enchufes.</li>
                            <li>Evitar la acumulación de materias y productos inflamables y alejarlos de toda fuente de calor.</li>
                            <li>Colocar los productos de limpieza inflamables en los armarios o locales cerrados con llave y ventilados.</li>
                            <li>Mantener cerrados los cubos de basura.</li>
                            <li>Asegurarse de cerrar las llaves de paso generales una vez acabados los servicios, la desconexión de los aparatos eléctricos, especialmente por la noche.</li>
                            <li>Es necesario saber distinguir los diferentes tipos de extintores y la forma de utilizarlos.</li>
                            <li>Si se han utilizado, deberán volverse a recargar de inmediato.</li>
                            <li>En general, si se produce un incendio no se debe intentar apagar sólo por una persona, salvo que sea un conato y se esté seguro de que no ofrece ningún tipo de riesgo.</li>
                            <li>Los dispositivos de extinción de incendios (extintores) deben ser fácilmente visibles. No colocar obstáculos delante de ellos que dificulten la accesibilidad.</li>
                            <li>En caso de incendio, si el humo es abundante, camine agachado. El humo es más ligero que el aire y tenderá a concentrarse en la parte superior.</li>
                            <li>No abrir las puertas de golpe, ni de frente.</li>
                            <li>Si la puerta de un local está caliente, significa que el fuego está próximo. Se evitará acercarse a ella.</li>
                            <li>No se debe intentar recoger objetos valiosos o efectos personales.</li>
                            <li>No se deberá realizar ninguna operación que entrañe peligro de incendio. </li>
                            <li>Para coordinar las acciones relativas por cada trabajador ante una situación de emergencia, se deberá elaborar un Plan de Emergencia.</li>
                            <li>El centro de trabajo debe estar equipado de sistema de detección de incendios (como mínimo de sistema manual de alarma de incendios) y equipos de protección contra incendios adecuados para poder llevar a cabo una actuación eficaz.</li>
                            <li>Los equipos de protección contra incendios que se emplearán serán extintores. Éstos se dispondrán de manera que puedan ser utilizados de manera rápida y fácil, que sean visibles, accesibles y próximos a puntos de riesgo de incendio y a las salidas; siempre que sea posible, se situarán en los paramentos de forma tal que el extremo superior del extintor se encuentre a una altura sobre el suelo menor que 1,70m. Se comprobará cada tres meses el estado aparente de conservación, de carga y del botellín de gas impulsor. Se verificará su carga con una periodicidad anual y cada 5 años se retimbrará el extintor de acuerdo con el Reglamento de Aparatos a Presión sobre Extintores de Incendios.</li>
                            <li>Todos los equipos de protección contra incendios estarán señalizados, así como las salidas de emergencia y su dirección. Se indicará teléfono de emergencia en lugar bien visible, además de formar e informar a los trabajadores de la situación de los sistemas de alarma y de las actuaciones a seguir en caso de incendio, designando a una serie de personal para realizar determinadas funciones para estos casos, especificados en el Plan de Emergencia.</li>
                        </ul>
                        <div className="pagebreak"></div>
                        <h3>5. Teléfonos de utilidad</h3>
                        <table className="simpleTable">
                            <tr>
                                <th></th>
                                <th>Teléfono</th>
                            </tr>
                            <tr style={{ color: 'red', fontWeight: 'bold' }}>
                                <td style={{ fontSize: 16 }}>COORDINACIÓN DE EMERGENCIAS</td>
                                <td style={{ fontSize: 24, textAlign: 'center' }}>112</td>
                            </tr>
                        </table>
                        <h3>6. Directorio del personal del centro de trabajo</h3>
                        <p>
                            <b>CENTRO DE TRABAJO: </b>{finca.direccion}<br />
                        </p>
                        <div className="pageTitle">EMPRESAS DE MANTENIMIENTO</div>
                        <table className="simpleTable">
                            <tr>
                                <th>Servicio</th>
                                <th>Nombre</th>
                                <th>Teléfono</th>
                            </tr>
                            {proveedores.map((proveedor) => {
                                return (
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )
                            })}
                        </table>
                        <h3>7. Técnico asociado</h3>
                        <p>
                            El informe ha sido realizado por <b>{finca?.tecnico?.nombre}</b>, técnico en prevención de riesgos laborales.<br />
                        </p>
                    </tbody>
                    </table>
                    <div className="pdf-header">
                        <img src={LogoLAE} alt="Logo LAE" />
                        {/* <img src={LogoMC} alt="Logo MC" /> */}
                    </div>
                    <style jsx>{`
                    .tablePage{
                        width: 100%;
                    }
                    .pdf-header {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        position: fixed;
                        top: 0;
                        background-color: #ffffff
                    }
                    .header-space, .pdf-header {
                        height: 90px;
                        
                    }
                    .pdf-header img {
                        height: 60px
                    }
                    div {
                        font-size: 10px
                    }
                    h1 {
                        margin-top: 100px;
                        margin-bottom: 60px;
                        font-size: 20px;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 400;
                    }
                    h3 {
                        width: 100%;
                        padding: 5px 10px;
                        background-color: #f8f8f8;
                        text-transform: uppercase;
                        border: 1px solid #e6e6e6;
                        margin-top: 25px;
                        margin-bottom: 15px;
                    }
                    h4 {
                        color: #ab2a3e;
                        margin-bottom: 10px;
                        margin-top: 20px;
                        font-size: 13px;
                    }
                    h5 {
                        font-size: 12px;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                    .textBox {
                        border: 2px solid #e6e6e6;
                        padding: 15px;
                        margin-top: 15px;
                    }
                    .tableRiesgos {
                        border-collapse: collapse;
                        width: 100%;
                        table-layout: fixed;
                        margin-bottom: 24px;
                    }
                    .simpleTable th, .simpleTable td, .tableRiesgos th, .tableRiesgos td {
                        border: 1px solid #000000;
                        padding: 5px;
                        line-height: 1.1
                    }
                    .tableRiesgos th {
                        background-color: #375491;
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: bold;
                    }
                    .simpleTable {
                        border-collapse: collapse;
                        width: 80%;
                        margin: 0 10% 24px 10%;
                    }
                    .simpleTable th {
                        text-transform: uppercase
                    }
                    ul, ol {
                        line-height: 1.8
                    }
                    p {
                        line-height: 1.8;
                        text-align: justify;
                    }
                    br {
                        display: block;
                        margin: 18px 0 20px 0;
                        line-height: 22px;
                        content: " ";
                    }
                    .pageTitle {
                        text-align: center;
                        text-decoration: underline;
                        font-weight: bold;
                        font-size: 12px;
                        margin-bottom: 20px;
                    }
                    @page {
                        size: A4 portrait;
                        margin: 2cm
                    }
                    `}</style>
                </>
            }
        </>
    );
}
 

export default withRouter(PlanEmergenciaRender);
