import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../functions/ordenarListas";
import { getAllCommercials, getAllConsultores, getAllTecnicos } from "../../services";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import { UsuarioContext } from "../../providers/userContext";
import {
  LinearProgress,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";


const formContainerStyles = css`
  height: 19px !important; 
`;

const FormContainer = styled("div")`
  ${formContainerStyles}
`;
const FiltrosAAFFInformes = ({ onFilter }) => {

  const user = useContext(UsuarioContext)
  const [busqueda, setBusqueda] = useState({});

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
    console.log(busqueda);
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    
    console.log(busqueda);
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{width:"100%"}}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <TextField
              id="razonSocial-input"
              label="Razón Social"
              variant="outlined"
              name='razonSocial'
              className="filtros altura"
              // style={{ width: '223px', height:'20px !important'}}
              value={busqueda.aaff}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
              "aria-label": "controlled",
              }}
            />
            <TextField
              id="nif-input"
              label="NIF"
              variant="outlined"
              name='nif'
              className="filtros altura"
              // style={{ width: '223px' }}
              value={busqueda.nif}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
              "aria-label": "controlled",
              }}
            />
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
              <BsSearch />
          </Button>
        </div>
      </div>
    <style jsx="true">
      {`
        .btn-buscador {
          padding: 5px 10px;
          color: #ffffff;
          background-color: #92004D;
          border-radius: 4px;
          font-weight: bold;
          width: -moz-fit-content;
          width: fit-content;
          align-self: flex-end;
          border: none;
          min-width:40px;
          height:40px;
          margin: 5px 0;
        }
        .btn-buscador:hover{
          color: black;
          background-color: #dee2e6;
        }    
      `}
    </style>
    </>
  );
};

export default FiltrosAAFFInformes;
