import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { DataTable, ProgressBarWithColors } from "../../../components";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { getAlertas, getConsultores, getProveedores } from "../../../services";
import LogoLAE from "../../../images/LogoLAE.png";
import { ordenarUltimaReclamacion } from "../../../functions/ordenarListas";
import {
  cambiarUltimaReclamacion,
  daysDiff,
  eliminaDuplicados,
  formatoFechasEs,
} from "../../../functions/funciones";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  linearProgressClasses,
  styled,
} from "@mui/material";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import moment from "moment";
import { UsuarioContext } from "../../../providers/userContext";

const proveedoresRows = [
  {
    id: "proveedor_id",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
  },
  {
    id: "ultimaReclamacion",
    numeric: false,
    disablePadding: false,
    label: "ÚLTIMA RECLAMACIÓN",
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "planificacionPreventiva",
    numeric: false,
    disablePadding: false,
    label: "PLANIFICACIÓN PREVENTIVA",
  },
  {
    id: "evaluacionRiesgos",
    numeric: false,
    disablePadding: false,
    label: "EVALUACIÓN RIESGOS",
  },
  {
    id: "vigilancia",
    numeric: false,
    disablePadding: false,
    label: "Vigilancia salud",
  },
  {
    id: "certificado",
    numeric: false,
    disablePadding: false,
    label: "Certificado Seg. Social",
  },
  {
    id: "justificante",
    numeric: false,
    disablePadding: false,
    label: "Justificante pago SPA",
  },
  {
    id: "seguro",
    numeric: false,
    disablePadding: false,
    label: "Seguro Resp. Civil",
    // overrideFunc: (row) => {
    //     console.log(row.find( d => d.tipoDoc === 2 )?.fechaCaducidad)

    // },
  },
];

const Alertas = () => {
  const user = useContext(UsuarioContext)
  const [Consultores, setConsultores] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    fetchData();
    getConsultores().then((res) => {
      setConsultores(res);
    });
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    // console.log(offset, limit, filtros);
    setLimite(limit);
    const payload = {
      // filtros:{ultimaReclamacion:new Date().setDate(new Date().getDate() - 60)},
      filtros: {tech_id: user.id},
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      
      const response1 = await getAlertas(payload);
      const response = await getProveedores(payload);
      // console.log(response1)
      // console.log(response)
      const { meta, data} = response1;
      // console.log(data);
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    // data.map((row, i) => (
    //   console.log(row.docssupps?.find((d) => d.tipoDoc === 4)?.validado)
    // ))
    return data.map((row, i) => ({
      proveedor_id: row.id,
      proveedor_name: (
        <Link to={"/proveedores/" + row.nif}>{row.razonSocial}</Link>
      ),
      ultimaReclamacion:
        row.ultimaReclamacion !== undefined &&
        row.ultimaReclamacion !== null ? (
          daysDiff(new Date(row.ultimaReclamacion), new Date()) > 30 ? (
            <span style={{ color: "red" }}>
              {formatoFechasEs(row.ultimaReclamacion)}
            </span>
          ) : (
            <span style={{ color: "black" }}>
              {formatoFechasEs(row.ultimaReclamacion)}
            </span>
          )
        ) : daysDiff(new Date(row.createdAt), new Date()) > 30 ? (
          <span style={{ color: "red" }}>{formatoFechasEs(row.createdAt)}</span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(row.createdAt)}
          </span>
        ),
      nif: row.nif,

      planificacionPreventiva:
        !row.docssupps?.find((d) => d.tipoDoc === 4)?.validado ? (
          <img style={{ width: "20px" }} src={Cross} alt="vacio" />
          ) : (
            <img style={{ width: "20px" }} src={Tick} alt="correcto" />
        ),
      evaluacionRiesgos:
        !row.docssupps?.find((d) => d.tipoDoc === 5)?.validado ? (
          <img style={{ width: "20px" }} src={Cross} alt="vacio" />
        ) : (
          <img style={{ width: "20px" }} src={Tick} alt="correcto" />
        ),
      vigilancia:
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            )}
          </span>
        ),
      certificado:
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            )}
          </span>
        ),
      justificante:
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            )}
          </span>
        ),
      seguro:
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            )}
          </span>
        ),
    }));
  };

  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setBusqueda({
  //     ...busqueda,
  //     [evt.target.name]: value,
  //   });
  // }

  // const getBusqueda = () => {
  //   setLoading(true);
  //   for (let key in busqueda) {
  //     if (
  //       busqueda[key] === "" ||
  //       busqueda[key] === null ||
  //       busqueda[key] === undefined
  //     ) {
  //       delete busqueda[key];
  //     }
  //   }

  //   fetchData({ offset: 1, limit: limite, filtros: busqueda });
  // };
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/alertas", label: `Alertas` }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ALERTAS
            {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
          </div>
          <div className="tableContainer">
            
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    meta={data.meta}
                    filtros={busqueda}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Alertas);
