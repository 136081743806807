import { Grid } from "@mui/material";
import React/* , { useEffect, useState } */ from "react";
import InicioCard from "../../../components/InicioCard";
import ComunidadesIcon from "../../../images/comunidades.png"

/* import {auth, firestore} from "../../../providers/firebase"
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify"; */

function Inicio() {
  return (<>
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      paddingTop: 90
    }}>BIENVENIDO A TU GESTOR DE GRUPOLAE FINCAS</div>
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5} >
      <Grid style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px'}}>
      <div style={{
        backgroundColor: "#fff",
        borderRadius: 15,
        paddingLeft: 80,
        paddingRight: 80,
        textAlign: 'center',
        width: '80%',
        marginTop: 50,
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.15)'
      }}>
        <div style={{
            paddingTop: 30,
            paddingBottom: 30,
            fontSize: 18,
            color: '#303248',
            width: '100%'
        }}>En estos momentos solo esta disponible el servicio LOPD, próximamente estará disponible tambien el servicio CAE.</div>
                
      </div>
      </Grid>
    </Grid>
  </>);
}

export default Inicio;
