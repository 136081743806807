import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import { renderToString } from "react-dom/server";
import jsPDF from "jspdf";
import InformeSituacionComunidades from "../routes/pages/admin/InformeSituacionComunidades";
import { withRouter } from "./withRouter";
import Portada from "../images/PortadaFinca.png";
import Encabezado from "../images/Encabezado.jpg";
import Logo from "../images/LogoLAE.png";
import Tick from "../icons/tick.svg";
import Cross from "../icons/cross.svg";
import { getFincasAAFF } from "../services";

const PDF = (props) => {
  console.log(props);
  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center",
  };
  const colstyle = {
    width: "30%",
  };
  const tableStyle = {
    width: "100%",
  };
  const portada = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
  };

  const [fincas, setFincas] = useState();

  useEffect(() => {
    console.log(props);
    getFincasAAFF(props.params.nif).then((res) => {
      console.log(res);
      setFincas(res, () => {
        var src = document.getElementById("portada").style.backgroundImage;
        var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, "");

        var img = new Image();
        img.onload = function () {
          window.print();
        };
        img.src = url;
        if (img.complete) img.onload();
      });
    });
  }, []);

  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    console.log("A");
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  if (fincas === undefined) return null;
  return (
    <div>
      <button className="button" onClick={handleGeneratePdf}>
        Generate PDF
      </button>
      <div ref={reportTemplateRef}>
        <InformeSituacionComunidades />
      </div>
    </div>
  );
};

export default withRouter(PDF);
