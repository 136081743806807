import { Box, Drawer } from "@mui/material";
import React from "react";

const ModalSelector = ({ open, children, onClose, sx }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ minHeight: "100vh", minWidth: 800, ...sx }}>
        {children ?? "load some assets here"}
      </Box>
    </Drawer>
  );
};

export default ModalSelector;
