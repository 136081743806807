import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";

import {
  Button,
  Checkbox,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  TableHead,
  TableSortLabel,
  Tabs,
  Typography,
} from "@mui/material";
import Iconify from "../../utilities/iconify";

const headCells = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR DE FINCAS",
  },
  {
    id: "department",
    numeric: false,
    disablePadding: false,
    label: "COMUNIDAD",
  },
  {
    id: "medSeg",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "FECHA ALTA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "DIRECCION",
  },
  {
    id: "cp",
    numeric: false,
    disablePadding: false,
    label: "C.P",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "SERVICIOS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "EMPLEADOS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE EMERGENCIA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE PREVENCIÓN",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "HOJA DE VISITA",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "NO VISITADA",
  },
];

const TAB_FILTERS_DATA = [
  { value: "all", label: "TODOS" },
  {
    value: "unvisited",
    label: "NO VISITADAS",
  },
  {
    value: "visited",
    label: "VISITADAS",
  },
  {
    value: "refreshed_unvisited",
    label: "RENOVADAS NO VISITADAS",
  },
  {
    value: "refreshed_visited",
    label: "RENOVADAS VISITADAS",
  },
  /*   {
    value: '',
    label: '',
  } */
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array = [], comparator) {
  if (!array.length) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = ({
  onSelectAllClick,
  selected,
  data,
  headersData,
  order,
  orderBy,
  onRequestSort,
  enableSelection,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const setHeaders = (headers) => {
    return headers.map((headCell) => (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        padding={headCell.disablePadding ? "none" : "normal"}
        sortDirection={orderBy === headCell.id ? order : false}
        size="small"
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={createSortHandler(headCell.id)}
          sx={{
            color: "white !important",
            "&:hover": { color: "white" },
            "&:active": { color: "white" },
            svg: { filter: "invert(1) brightness(1)" },
            fontSize: "0.8rem",
          }}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    ));
  };

  return (
    <TableHead sx={{ backgroundColor: "#92004D" }}>
      <TableRow>
        {enableSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                selected?.length > 0 && selected?.length < data?.length
              }
              checked={data?.length > 0 && selected?.length === data?.length}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all names",
              }}
            />
          </TableCell>
        )}
        {headersData ? setHeaders(headersData) : setHeaders(headCells)}
      </TableRow>
    </TableHead>
  );
};

const DataTable = ({
  data,
  meta,
  headers,
  isLoading,
  onClickRow,
  fetcher,
  enableSection,
  children,
  filtros,
  recargar,
  mensaje,
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(data?.current_page ?? 1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const handleTabFilterValue = (event, newValue) => {
    const newData = data.filter(
      (row) => row[newValue] !== null || row[newValue] !== "false"
    );
    setFilteredData(newData);
  };

  const handleStatusClick = () => {
    setIsArrowDown((prevIsArrowDown) => !prevIsArrowDown);

    const newData = isArrowDown
      ? data?.filter((row) => row.status === "0")
      : data?.filter((row) => row.status === "1");

    setFilteredData(newData);
    console.log(newData);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(data.map((n) => n.userIdentifier));
      return;
    }
    setSelected([]);
  };

  const handleNext = () => {
    setPage((page) => page + 1);
  };

  const handleBack = () => setPage((page) => (page === 1 ? page : page - 1));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data]
  );

  useEffect(() => {
    fetcher({ offset: page, limit: rowsPerPage, filtros: filtros });
  }, [page, rowsPerPage, recargar]);

  const setTabs = (tabs) =>
    tabs.map((tab) => <Tab value={tab.value} label={tab.label} />);

  const isLastPage = !meta?.has_more_pages;

  return (
    <Box sx={{ width: "100%", minHeight: "100vh" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          {children}
          {isLoading && <LinearProgress sx={{ width: "100%" }} />}
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              selected={selected}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              data={filteredData}
              isArrowDown={isArrowDown}
              onStatusClick={handleStatusClick}
              headersData={headers}
              enableSelection={enableSection}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {isLoading ? (
                <TableRow hover sx={{ cursor: "default" }}>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#92004D" }} />
                  </TableCell>
                </TableRow>
              ) : visibleRows?.length === 0 ? (
                <TableRow hover sx={{ cursor: "default" }}>
                  <TableCell colSpan={13} style={{ textAlign: "center" }}>
                    <span>{mensaje}</span>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows?.length &&
                visibleRows.map((row) => {
                  return (
                    <TableRow hover key={row.id} sx={{ cursor: "default" }}>
                      {enableSection && (
                        <TableCell padding="checkbox" colSpan={row.colSpan}>
                          <Checkbox
                            color="primary"
                            checked={row.userIdentifier ?? false}
                            /*  inputProps={{
                            "aria-labelledby": labelId,
                          }} */
                          />
                        </TableCell>
                      )}
                      {headers?.map((header) => {
                        // console.log("header", header);
                        return (
                          <TableCell
                            align={header?.numeric ? "right" : "left"}
                            style={header?.style}
                            key={header.id}
                            onClick={(e) => {
                              if (typeof header.overrideFunc !== "function") {
                                e.stopPropagation();
                                onClickRow(row);
                              }
                            }}
                          >
                            {typeof header?.overrideFunc === "function"
                              ? header?.overrideFunc(row[header.id], row)
                              : row[header.id] || ""}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
            gap: 3,
            alignItems: "center",
          }}
        >
          <Box className="lae-flex lae-flex-row" sx={{ alignItems: "center" }}>
            <Typography>Tamaño de página:</Typography>
            <Select
              value={rowsPerPage}
              label="Age"
              size="small"
              variant="standard"
              onChange={({ target }) => {
                setPage(1);
                setRowsPerPage(target.value);
              }}
            >
              {[50, 100, 150, 200, 250, 300].map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="lae-flex lae-flex-row">
            <Typography>
              Nº Registros: {isLastPage ? meta?.total : data.length * page} de{" "}
              {meta?.total ?? 0}
            </Typography>
          </Box>

          <Box className="lae-flex lae-flex-row" sx={{ gap: 0 }}>
            <Button variant="text" onClick={handleBack} disabled={page === 1}>
              <Iconify
                icon="material-symbols:chevron-left"
                sx={{
                  color: page === 1 ? "gray" : "black",
                  height: 24,
                  width: 24,
                }}
              />
            </Button>
            <Button variant="text" onClick={handleNext} disabled={isLastPage}>
              <Iconify
                icon="material-symbols:chevron-right"
                sx={{
                  color: isLastPage ? "gray" : "black",
                  height: 24,
                  width: 24,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DataTable;
