export const orderArray = (array) => {
  array.sort((a, b) => {
    const razonSocialA = a.razonSocial.toLowerCase().trim();
    const razonSocialB = b.razonSocial.toLowerCase().trim();

    if (razonSocialA > razonSocialB) {
      return 1;
    }
    if (razonSocialA < razonSocialB) {
      return -1;
    }
    return 0;
  })
    return array;
}

export const ordenarNombreYApellido = (array) => {
  array.sort((a, b) => {
      const nombreA = a.nombre.toLowerCase().trim();
      const nombreB = b.nombre.toLowerCase().trim();
      const apellidosA = a.apellidos.toLowerCase().trim();
      const apellidosB = b.apellidos.toLowerCase().trim();

      if (nombreA !== nombreB) {
          return nombreA.localeCompare(nombreB); // Ordenar por nombre
      } else {
          return apellidosA.localeCompare(apellidosB); // Si los nombres son iguales, ordenar por apellido
      }
  });

  return array;
};

export const ordenarUltimaReclamacion = () => {

}

export const ordenarProvinciasYMunicipios = (a, b) => {
  //comparar nombreA y nombreB sin tener en cuenta los acentos
  const nombreA = a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  const nombreB = b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

  return nombreA.localeCompare(nombreB)
}