import { toast } from "react-toastify";
import { plantillaProveedores, plantillaFincas, deleteFincasAAFF, plantillaMatcheo, plantillaAAFF, plantillaRenovaciones, plantillaFincasLOPD } from "../services";
import Swal from "sweetalert2";

export const subirListadoProveedores = async (evt) => {
    let toastInfo = toast.info("Subiendo proveedores...", { autoClose: false });
    const value = evt.target.files[0];
    try {
      if(value){
        let itemsOk = []
        let itemsErr = []
        const res = await plantillaProveedores(value);
        console.log(res)
        res.forEach( item => {
          if (item.status === 200) {
            itemsOk.push(item.msg)
            toast.dismiss(toastInfo);
            toast.success(item.msg);
          }
          else if(item.status === 400){
            itemsErr.push(item.msg)
            toast.dismiss(toastInfo);
            // toast.error(item.msg);
          }
          else {
              toast.error("error al subir proveedores");
          }
        })
        console.log(itemsOk)
        console.log(itemsErr)
        const html = `
          <div style="display: flex;">
            <div style="flex: 1;">
              <ul style="font-size:13px; margin-top:10px; text-align:left;">
                ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
              </ul>
            </div>
          </div>
        `;
        if(itemsErr.length >0){
          Swal.fire({
            title: 'Error al subir estos proveedores',
            html: html,
            confirmButtonText: 'Cerrar',
            icon: 'error'
          });
        }
      }
    } catch (error) {
      toast.update(toastInfo, {
        render: "ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    }
  //   if(value) {
  //   plantillaProveedores(value)
  //     .then((res) => {
  //       res.forEach( item => {
  //         console.log(item)
  //         if (item.status === 200) {
  //           toast.dismiss(toastInfo);
  //           toast.success(item.msg);
  //         }
  //         else if(item.status === 400){
  //           toast.dismiss(toastInfo);
  //           toast.error(item.msg);
  //         }
  //         else {
  //           toast.update(toastInfo, {
  //             render: "error al subir proveedores",
  //             type: toast.TYPE.ERROR,
  //             autoClose: true
  //           });
  //         }
  //       })
  //       // console.log(res);
  //       // if (res.status === 200 || res.message === "SUPPLIERS_CREATED" ) {
  //       //   toast.success("Proveedores subidos con éxito");
  //       //   return true
  //       // }
  //       // if (res.status === 201) {
  //       //   toast.error("el proveedor existe");
  //       // }
  //       // if (res.status === 403) {
  //       //   toast.error("error al subir proveedores");
  //       // }
  //       // if (res.message === "ERROR_SUPPIER_EXIST") {
  //       //   toast.error("El proveedor ya existe");
  //       // }
  //       // if (res.error === "ERROR_USER_EXIST") {
  //       //   toast.error("El proveedor ya existe");
  //       // }
  //     })
  //     .catch((err) => {
  //       toast.update(toastInfo, {
  //         render: "ha ocurrido un error",
  //         type: toast.TYPE.ERROR,
  //         autoClose: true
  //       });
  //       console.error(err);
  //     });
  // }
}

export const subirListadoRenovaciones = (evt) => {
  let toastInfo = toast.info("Subiendo Renovaciones...");
  const value = evt.target.files[0];
  if(value) {
    plantillaRenovaciones(value).then((res) => {
      if (res.status >= 200 && res.status < 300) {
          toast.update(toastInfo, {
            render: "Fincas renovadas con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true
          });
      } else {
          toast.update(toastInfo, {
            render: "error al renovar fincas",
            type: toast.TYPE.ERROR,
            autoClose: true
          });
      }
      })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "Ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        console.error(err);
      });
  }
}

export const subirListadoLOPD = async (evt) => {
  let toastInfo = toast.info("Subiendo fincas LOPD...", { autoClose: false });
  const value = evt.target.files[0];
  if(value) {
  try {
    let itemsOk = []
    let itemsErr = []
    const res = await plantillaFincasLOPD(value);
    
    res.data.forEach( item => {
      if (item.status === 200) {
        itemsOk.push(item.msg)
        toast.dismiss(toastInfo);
        toast.success(item.msg);
      }
      else if(item.status === 400){
        itemsErr.push(item.msg)
        toast.dismiss(toastInfo);
        // toast.error(item.msg);
      }
      else {
          toast.error("error al subir fincas");
      }
    })

    const html = `
      <div style="display: flex;">
        <div style="flex: 1;">
          <ul style="font-size:13px; margin-top:10px; text-align:left;">
            ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
          </ul>
        </div>
      </div>
    `;
    if(itemsErr.length >0){
      Swal.fire({
        title: 'Error al subir estas Fincas',
        html: html,
        confirmButtonText: 'Cerrar',
        icon: 'error'
      });
    }
  } catch (error) {
    toast.update(toastInfo, {
      render: "Ha ocurrido un error",
      type: toast.TYPE.ERROR,
      autoClose: true
    });
  }
  // plantillaFincasLOPD(value).then((res) => {
  //   res.data.forEach( item => {
  //     if (item.status === 200) {
  //       toast.dismiss(toastInfo);
  //       toast.success(item.msg);
  //     }
  //     else if(item.status === 400){
  //       toast.dismiss(toastInfo);
  //       toast.error(item.msg);
  //     }
  //     else {
  //       // toast.error("error al subir fincas");
  //       toast.update(toastInfo, {
  //         render: "error al subir fincas",
  //         type: toast.TYPE.ERROR,
  //         autoClose: true
  //       });
  //     }
  //   })
  // })
  // .catch((err) => {
  //   toast.update(toastInfo, {
  //     render: "Ha ocurrido un error",
  //     type: toast.TYPE.ERROR,
  //     autoClose: true
  //   });
  //   console.error(err);
  // });

}}
export const subirListadoAAFF = async (evt) => {
let toastInfo = toast.info("Subiendo administradores de fincas...", { autoClose: false });
const value = evt.target.files[0];
if (value) {
  
try {
  let itemsOk = []
  let itemsErr = []
  const res = await plantillaAAFF(value);
  console.log(res.data)
  res.data.forEach( item => {
    if (item.status === 200) {
      itemsOk.push(item.msg)
      toast.dismiss(toastInfo);
      toast.success(item.msg);
    }
    else if(item.status === 400){
      itemsErr.push(item.msg)
      toast.dismiss(toastInfo);
      // toast.error(item.msg);
    }
    else {
        toast.error("error al subir administradores de fincas");
    }
  })
  console.log(itemsOk)
  console.log(itemsErr)
  const html = `
    <div style="display: flex;">
      <div style="flex: 1;">
        <ul style="font-size:13px; margin-top:10px; text-align:left;">
          ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
        </ul>
      </div>
    </div>
  `;
  if(itemsErr.length >0){
    Swal.fire({
      title: 'Error al subir estos AAFF',
      html: html,
      confirmButtonText: 'Cerrar',
      icon: 'error'
    });
  }
} catch (error) {
  toast.update(toastInfo, {
    render: "Ha ocurrido un error",
    type: toast.TYPE.ERROR,
    autoClose: true
  });
}

// plantillaAAFF(value)
//     .then((res) => {
//       console.log(res.data)
//       res.data.forEach( item => {
//         if (item.status === 200) {
//           toast.dismiss(toastInfo);
//           toast.success(item.msg);
//         }
//         else if(item.status === 400){
//             toast.dismiss(toastInfo);
//             toast.error(item.msg, {
//               autoClose: false
//             });
//         }
//         else {
//             toast.error("error al subir administradores de fincas");
//         }
//       })
//     })
//     .catch((err) => {
//       toast.update(toastInfo, {
//         render: "Ha ocurrido un error",
//         type: toast.TYPE.ERROR,
//         autoClose: true
//       });
//     console.error(err);
//     });
}
}

export const subirListado = async (evt) => {
// toast.info("Subiendo fincas...");
let toastInfo = toast.info("Subiendo fincas CAE...", { autoClose: false });
const value = evt.target.files[0];

try {
  let itemsOk = []
  let itemsErr = []
  const res = await plantillaFincas(value);
  res.data.forEach( item => {
    if (item.status === 200) {
      itemsOk.push(item.msg)
      toast.dismiss(toastInfo);
      toast.success(item.msg);
    }
    else if(item.status === 400){
      itemsErr.push(item.msg)
      toast.dismiss(toastInfo);
      // toast.error(item.msg);
    }
    else {
        toast.error("error al subir fincas");
    }
  })
  
  const html = `
    <div style="display: flex;">
      <div style="flex: 1;">
        <ul style="font-size:13px; margin-top:10px; text-align:left;">
          ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
        </ul>
      </div>
    </div>
  `;
  if(itemsErr.length >0){
    Swal.fire({
      title: 'Error al subir estas fincas',
      html: html,
      confirmButtonText: 'Cerrar',
      icon: 'error'
    });
  }
} catch (error) {
  toast.update(toastInfo, {
    render: "Ha ocurrido un error",
    type: toast.TYPE.ERROR,
    autoClose: true
  });
}
  // plantillaFincas(value)
  //     .then((res) => {
  //       res.data.forEach( item => {
  //         if (item.status === 200) {
  //           toast.dismiss(toastInfo);
  //           toast.success(item.msg);
  //         }
  //         else if(item.status === 400){
  //           toast.dismiss(toastInfo);
  //           toast.error(item.msg);
  //         }
  //         else {
  //           // toast.error("error al subir fincas");
  //           toast.update(toastInfo, {
  //             render: "error al subir fincas",
  //             type: toast.TYPE.ERROR,
  //             autoClose: true
  //           });
  //         }
  //       })
  //     })
  //     .catch((err) => {
  //     toast.update(toastInfo, {
  //       render: "Ha ocurrido un error",
  //       type: toast.TYPE.ERROR,
  //       autoClose: true
  //     });
  //     console.error(err);
  //     });
}

export const subirListadoMatcheo = (evt) => {
    let toastInfo = toast.info("Realizando matcheo...", { autoClose: false });
    const value = evt.target.files[0];
    if(value) {
    plantillaMatcheo(value)
      .then((res) => {
        if (res.message === "MATCH_CREATED") {
          toast.dismiss(toastInfo);
          toast.success("Matcheo realizado con éxito");
        } else {
          toast.dismiss(toastInfo);
          toast.error("error al realizar el Matcheo");
        }
        console.log(res);
      })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "Ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        console.error(err);
      });
  }
}
export const eliminarFincas = (aaff) => {
    console.log("eliminarFincasAAFF");
    Swal.fire({
      title: "¿Eliminar todas las fincas del aaff" + aaff.razonSocial + "?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteFincasAAFF(aaff.nif)
          .then((res) => {
            if (res.message === "PROPERTIE_DELETED") {
              toast.success("fincas eliminada");
            } else {
              toast.error("Error al eliminar todas las fincas");
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error al eliminar todas las fincas");
          });
      }
    });
  }