import axios from "axios";
import { getValidationError } from "../utilities";
import { toast } from "react-toastify";
import { logout } from "../services";

// https://api.laefincas.grupolae.net/api/

// axios.defaults.baseURL = "http://localhost:4001/api/";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// const URL_DEV = "https://apifincasdev.azurewebsites.net/api/";
// axios.defaults.baseURL = URL_DEV;
export const AxiosInterceptor = () => {
  

  const updateHeader = (request) => {
    const token = localStorage.getItem("token") ?? "";
    const newHeaders = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
      provide: "laefincas",
    };

    request.headers = newHeaders;

    return request;
  };

  axios.interceptors.request.use((request) => {
    if (request.url?.includes("assets")) return request;
    return updateHeader(request);
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error", error);
      // toast.error(
      //   getValidationError(error.code) + " " + JSON.stringify(error.response.data)
      // );
      console.log(getValidationError(error.code) + " " + JSON.stringify(error.response.data))

      // Unauthorized
      if (error.response.status === 401) {
        logout()
      }
      return error.response
    }
  );
};
