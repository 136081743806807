import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import Adjuntar from "../../../icons/upload.svg";
import Documento from "../../../icons/document.svg";
import Aviso from "../../../icons/email.svg";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { getFinca } from "../../../services";
import {
  abrirArchivo,
  subirLOPD,
  generarPDF,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import HojaVisitaRender from "../tecnico/HojaVisitaRenderKK";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Ventas from "../general/Ventas";
import { UsuarioContext } from "../../../providers/userContext";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";

const Comunidad = (props) => {
  const user = useContext(UsuarioContext)
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [hojasVisita, setHojasVisita] = useState([]);
  const contentRef = useRef(null);
  const [value, setValue] = useState(0);
  // const [generar, setGenerar] = useState(false);

  useEffect(() => {
    getFinca(props.params.nif).then((res) => {
      console.log(res)
      setFinca(res);
      setProveedores(res.suppliers);
      console.log(res.suppliers)
    });
  }, []);

  const generarArchivo = () => {
    // console.log("AA")
    <HojaVisitaRender generarPDF={true} />;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <BreadCrumb rutas={[{path:'/comunidades/', label:"Comunidades"}, {path:`/comunidades/${props.params.nif}`, label:`${finca?.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS COMUNIDAD</div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca?.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca?.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca?.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(finca?.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b>{" "}
            </p>
            <p>
              <b>CP:</b> {finca?.codigoPostal}
            </p>
            <p>
              <b>Email:</b> {finca?.email}
            </p>
            <p>
              <b>Provincia:</b> {finca?.provincia}
            </p>
            <p>
              <b>Telefono:</b> {finca?.telefonoContacto}{" "}
            </p>
            <p>
              <b>Presidente:</b> {finca?.nombrePresidente}{" "}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div style={{ paddingBottom:'5%' }}>
            <div className="gridSubhead">SERVICIOS COMUNIDAD</div>
            {console.log(finca?.gruposServicios?.includes(1), finca?.gruposServicios?.includes(2))}
            {console.log(value)}
            <Box sx={{ padding: '10px 40px', border: 'none' }}>
              <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                { finca?.gruposServicios?.includes(2) && <Tab style={{ color:"#92004D" }} label="LOPD" /> }
                { finca?.gruposServicios?.includes(1) && <Tab style={{ color:"#92004D" }} label="CAE" /> }
              </Tabs>
            </Box>
              {finca?.gruposServicios?.map((grupoServicio, index) => (
                finca.gruposServicios.includes(grupoServicio) && (
                  <TabPanel key={index} value={value} index={index}>
                    <div className="pageGrid" style={{ gap:0 }}>
                      {grupoServicio === 1 && (
                        <>
                          <div id="ventas" style={{ /* height: height + 'px' */ }}>
                            <Ventas nifFinca={props.params.nif} idFinca={finca.id} grupoServicio={1}/>
                          </div>
                          <div id="proveedores" className="tableContainer">
                            <div className="gridHeadProv">PROVEEDORES</div>
                            <div style={{ /* height: height-80 + 'px', overflow: 'auto' */ }}>
                              <table>
                              {/* <thead>
                                <tr
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  <th></th>
                                </tr>
                              </thead> */}
                              <tbody>
                                {proveedores.length === 0 ? <small style={{ paddingLeft: '14px' }}>No tiene proveedores</small> : 
                                proveedores.map((e, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        style={{
                                          width: "100%",
                                          padding: 10,
                                        }}
                                      >
                                        <Link to={"/proveedores/" + e.nif}>
                                          {e.razonSocial}
                                          <p style={{ opacity: 0.55, fontSize: 14 }}>
                                            {e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find( serv => serv.id === e.service_to_propertie?.service)?.nombre : null}
                                          </p>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                      {grupoServicio === 2 && (
                        <Ventas nifFinca={props.params.nif} grupoServicio={2}/>
                      )}
                      {/* Agrega más condiciones para otros grupos de servicio si es necesario */}
                    </div>
                  </TabPanel>
                )
              ))}
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
        .gridHeadProv {
          border-radius: 5px 5px 0 0;
          background-color: #92004D;
          color: #ffffff;
          padding: 10px 15px;
          font-weight: bold;
          font-size: 12px;
          height: 34px;
        }
        #ventas{
          width:70%;
        }
        #proveedores{
          width:30%;
        }
        .pageGrid{
          display:flex;
          flex-direction: row;
        }
        @media screen and (max-width: 992px) {
          #ventas{
            overflow-y: auto;
            width:100%;
          }
          #proveedores{
            width:100%;
          }
          .pageGrid{
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};

export default withRouter(Comunidad);
