import React, {useState, useEffect, useContext} from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getListAAFFs, getServiciosFincas, getAllTecnicos, getAllConsultores } from '../../services';
import { UsuarioContext } from '../../providers/userContext';
import {
  LinearProgress,
  styled,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel
} from "@mui/material";

const FiltrosVentas = ({onFilter}) => {

    const { rol } = useContext(UsuarioContext)
    const [busqueda, setBusqueda] = useState({})
    const [servicios, setServicios] = useState([])
    const [tecnicos, setTecnicos] = useState([])
    const [consultores, setConsultores] = useState([])
    const [aaffs, setAAFFs] = useState([])
    const user = useContext(UsuarioContext)
    const [typeVentaInicio, setTypeVentaInicio] = useState('text');
    const [typeVentaFin, setTypeVentaFin] = useState('text');
    const [typeCreacionInicio, setTypeCreacionInicio] = useState('text');
    const [typeCreacionFin, setTypeCreacionFin] = useState('text');
    const [listAnos, setListAnos] = useState([]);

    const handleFocusVentaInicio = () => setTypeVentaInicio('date');
    const handleBlurVentaInicio = () => {
      if (!busqueda.fechaVentaInicio) setTypeVentaInicio('text');
    };
    const handleFocusVentaFin = () => setTypeVentaFin('date');
    const handleBlurVentaFin = () => {
      if (!busqueda.fechaVentaFin) setTypeVentaFin('text');
    };
    const handleFocusCreacionInicio = () => setTypeCreacionInicio('date');
    const handleBlurCreacionInicio = () => {
      if (!busqueda.fechaCreacionInicio) setTypeCreacionInicio('text');
    };
    const handleFocusCreacionFin = () => setTypeCreacionFin('date');
    const handleBlurCreacionFin = () => {
      if (!busqueda.fechaCreacionFin) setTypeCreacionFin('text');
    };
    
    useEffect(() => {
      fetchData() 
      listadoAnos()
    },[])

    const listadoAnos = () => {
      const anoActual = new Date().getFullYear()
      let Anos = []
      for( let i=2020; i<= anoActual; i++){
        Anos.push(i)
      }
      setListAnos(Anos)
    }

    const fetchData = async () => {
      const response = await getServiciosFincas()
      if(user.rol === "AAFF"){
        setServicios(response.filter(item => user.servicios.includes(item.id)))
      }else{
        setServicios(response)
      }
      if(user.rol !== 'SUPP' && user.rol !== 'COMM' && user.rol !== 'AAFF' && user.rol !== 'TECH'){
        const responseT = await getAllTecnicos()
        setTecnicos(responseT)
        const responseC = await getAllConsultores()
        console.log(responseC)
        setConsultores(responseC)
      }
      const responseA = await getListAAFFs()
      setAAFFs(responseA)
    }
    function handleChange(evt) {
        const value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
    
      const getBusqueda = () => {
        console.log(busqueda);
        for (let key in busqueda) {
          if (
            busqueda[key] === "" ||
            busqueda[key] === null ||
            busqueda[key] === undefined
            ) {
              delete busqueda[key];
            }
        }
        console.log(busqueda);
        onFilter(busqueda)
      };

    return ( 
        <>
          <div className="buscadorFiltros">
            <div>
              <Box
                mx={1}
                display={"block"}
                flexDirection={"row"}
                gap={2}
              >
                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="ano-label">Año</InputLabel>
                    <Select
                      labelId="ano-label"
                      id="ano-select"
                      label="Año venta"
                      name="ano"
                      className="filtros"
                      value={busqueda?.ano}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{height: 40}}></MenuItem>
                      {listAnos?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={Number(e)}>
                              {e}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="servicio-label">Servicio</InputLabel>
                    <Select
                      labelId="servicio-label"
                      id="servicio-select"
                      label="Servicio"
                      name="servp_id"
                      className="filtros"
                      value={busqueda?.servicio}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{height: 40}}></MenuItem>
                      {servicios?.map((e, i) => {
                        return (
                          rol === "TECH" && (e.id === 1 ||e.id === 3) ?
                            <MenuItem key={i} value={Number(e.id)}>
                              {e.nombre}
                            </MenuItem>
                          :  
                          rol !== "TECH" ? 
                            <MenuItem key={i} value={Number(e.id)}>
                              {e.nombre}
                            </MenuItem>
                          : 
                          null
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className='filtros-select'>
                    <TextField
                      id="razonSocial-input"
                      label="Comunidad"
                      variant="outlined"
                      name='razonSocial'
                      className="filtros altura"
                      value={busqueda.razonSocial}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                  </FormControl>
                  <TextField
                    id="nif-input"
                    label="NIF"
                    variant="outlined"
                    name='nif'
                    className="filtros altura"
                    value={busqueda.nif}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{
                      "aria-label": "controlled",
                    }}
                  />
                  {rol !== "AAFF" && 
                    <TextField
                      id="codigoPostal-input"
                      label="Código postal"
                      variant="outlined"
                      name='codigoPostal'
                      className="filtros altura"
                      value={busqueda.codigoPostal}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                  }
                  {
                    (user.rol !== 'SUPP' && user.rol !== 'AAFF') && 
                    <>
                      {/* <FormControl variant="outlined" className='filtros-select'>
                        <InputLabel id="aaff-label">AAFF</InputLabel>
                        <Select
                          labelId="aaff-label"
                          id="aaff-select"
                          label="AAFF"
                          name="aaff_id"
                          className="filtros"
                          value={busqueda?.aaff_id}
                          onChange={handleChange}
                          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        >
                          <MenuItem value="" style={{height: 40}}></MenuItem>
                          {aaffs?.map((e, i) => {
                            return (
                              <MenuItem key={i} value={e.id}>
                                  {e.razonSocial}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl> */}
                      <TextField 
                        id="aaff-input"
                        labelId="aaff-label"
                        label="AAFF"
                        name="aaffNombre"
                        className="filtros altura"
                        value={busqueda.aaffNombre}
                        onChange={handleChange}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                      <TextField
                        type={typeVentaInicio}
                        id="fechaVentaInicio-input"
                        label="Fecha venta (Desde)"
                        variant="outlined"
                        name='fechaVentaInicio'
                        className="filtros altura"
                        value={busqueda.fechaVentaInicio}
                        onChange={handleChange}
                        onFocus={handleFocusVentaInicio}
                        onBlur={handleBlurVentaInicio}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                      <TextField
                        type={typeVentaFin}
                        id="fechaVentaFin-input"
                        label="Fecha venta (Hasta)"
                        variant="outlined"
                        name='fechaVentaFin'
                        className="filtros altura"
                        value={busqueda.fechaVentaFin}
                        onChange={handleChange}
                        onFocus={handleFocusVentaFin}
                        onBlur={handleBlurVentaFin}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </>
                  }
                  {
                    (user.rol !== 'SUPP' && user.rol !== 'COMM' && user.rol !== 'AAFF') && 
                    <>
                      <TextField
                        type={typeCreacionInicio}
                        id="fechaCreacionInicio-input"
                        label="Fecha realización (Desde)"
                        variant="outlined"
                        name='fechaCreacionInicio'
                        className="filtros altura"
                        value={busqueda.fechaCreacionInicio}
                        onChange={handleChange}
                        onFocus={handleFocusCreacionInicio}
                        onBlur={handleBlurCreacionInicio}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                      <TextField
                        type={typeCreacionFin}
                        id="fechaCreacionFin-input"
                        label="Fecha realización (Hasta)"
                        variant="outlined"
                        name='fechaCreacionFin'
                        className="filtros altura"
                        value={busqueda.fechaCreacionFin}
                        onChange={handleChange}
                        onFocus={handleFocusCreacionFin}
                        onBlur={handleBlurCreacionFin}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </>
                  }
                  {rol !== "AAFF" && 
                    <>
                      <FormControl variant="outlined" className='filtros-select'>
                        <InputLabel id="estado-label">Estado</InputLabel>
                        <Select
                          labelId="estado-label"
                          id="estado-select"
                          label="Estado"
                          name="visitada"
                          className="filtros"
                          value={busqueda?.visitada}
                          onChange={handleChange}
                          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        >
                          <MenuItem value="" style={{height: 40}}></MenuItem>
                          <MenuItem value={Boolean(false)}> Pendiente </MenuItem>
                          <MenuItem value={Boolean(true)}> Visitada </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className='filtros-select'>
                        <InputLabel id="videoVigilancia-label">Video vigilancia</InputLabel>
                        <Select
                          labelId="videoVigilancia-label"
                          id="videoVigilancia-select"
                          label="videoVigilancia"
                          name="surveillanceVideo"
                          className="filtros"
                          value={busqueda?.surveillanceVideo}
                          onChange={handleChange}
                          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        >
                          <MenuItem value="" style={{height: 40}}></MenuItem>
                          <MenuItem value={Boolean(false)}> NO </MenuItem>
                          <MenuItem value={Boolean(true)}> SÍ </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" className='filtros-select'>
                        <InputLabel id="trabajadores-label">Trabajadores</InputLabel>
                        <Select
                          labelId="trabajadores-label"
                          id="trabajadores-select"
                          label="trabajadores"
                          name="workers"
                          className="filtros"
                          value={busqueda?.workers}
                          onChange={handleChange}
                          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        >
                          <MenuItem value="" style={{height: 40}}></MenuItem>
                          <MenuItem value={Boolean(false)}> NO </MenuItem>
                          <MenuItem value={Boolean(true)}> SÍ </MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  }
                  {
                    (user.rol === 'ADMIN' || user.rol === 'CONSUL') && 
                      <>
                        <FormControl variant="outlined" className='filtros-select'>
                          <InputLabel id="tecnicos-label">Tecnicos</InputLabel>
                          <Select
                            labelId="tecnicos-label"
                            id="tecnicos-select"
                            label="Tecnicos"
                            name="tech_id"
                            className="filtros"
                            value={busqueda?.tecnicoNif}
                            onChange={handleChange}
                            onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                          >
                            <MenuItem value="" style={{height: 40}}></MenuItem>
                            {tecnicos?.map((e, i) => {
                              return (
                                <MenuItem key={i} value={e.id}>
                                    {e.nombre+" "+e.apellidos}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <FormControl variant="outlined" className='filtros-select'>
                          <InputLabel id="consultores-label">Consultores</InputLabel>
                          <Select
                            labelId="consultores-label"
                            id="consultores-select"
                            label="Consultores"
                            name="consul_id"
                            className="filtros"
                            value={busqueda?.consul_id}
                            onChange={handleChange}
                            onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                          >
                            <MenuItem value="" style={{height: 40}}></MenuItem>
                            {consultores?.map((e, i) => {
                              return (
                                <MenuItem key={i} value={e.user_id}>
                                    {e.nombre+" "+e.apellidos}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </>
                  }
              </Box>
            </div>
            <div>
              <Button className="btn-buscador" onClick={() => getBusqueda()}>
                  <BsSearch />
              </Button>
            </div>
          </div>
          {/* <div className="buscador">
             <div>
              <input
                  type="text"
                  name="servicio"
                  value={busqueda.servicio}
                  placeholder="Servicio"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input>
              <select 
                  name="servp_id" 
                  value={busqueda.servicio}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                  <option value="">Servicio</option>
                  {servicios?.map(s => {
                    return (
                      rol === "TECH" && (s.id === 1 ||s.id === 3) ?
                        <option value={Number(s.id)}>{s.nombre}</option>
                      : rol !== "TECH" ? 
                        <option value={Number(s.id)}>{s.nombre}</option>
                      : 
                      null
                    )
                  })}
              </select>
              <input
                  type="text"
                  name="razonSocial"
                  value={busqueda.razonSocial}
                  placeholder="Comunidad"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input>
              <input
                  type="text"
                  name="codigoPostal"
                  value={busqueda.codigoPostal}
                  placeholder="Código postal"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input>
              <input
                  type="date"
                  name="ventaDesde"
                  value={busqueda.ventaDesde}
                  placeholder="Fecha Venta Desde"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input>
              <input
                  type="date"
                  name="ventaHasta"
                  value={busqueda.ventaHasta}
                  placeholder="Fecha Venta Hasta"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input>
              <select 
                  name="visitada" 
                  value={busqueda.visitada}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                  <option value="">Estado</option>
                  <option value={Boolean(false)}>Pendiente</option>
                  <option value={Boolean(true)}>Visitada</option>
              </select>
              <input
                  type="text"
                  name="estado"
                  value={busqueda.estado}
                  placeholder="Estado"
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              ></input> 
            </div>
            <div>
              <button onClick={() => getBusqueda()}>
                  <BsSearch />
              </button>
            </div>
          </div> */}
          <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
        </>
     );
}
 
export default withRouter(FiltrosVentas);