import React, { useState } from 'react';
import { PDFViewer, Document, Page, Image, Text, View } from "@react-pdf/renderer";
import videovigilancia from '../../images/certificado_videovigilancia.png'
import { withRouter } from '../../providers/withRouter';
import { useEffect } from 'react';
import { getFinca } from '../../services';
import { CircularProgress } from '@mui/material';

const CertificadoVigilancia = (props) => {

    const backgroundImageUrl = videovigilancia; // Reemplaza con la URL de tu imagen de fondo

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const [isLoading, setIsLoading] = useState(true)
    const [finca, setFinca] = useState({})
    
    useEffect(()=>{
        fetchData()
    },[])
    const customDocumentSize = {
        width: 460, // Ancho del documento
        height: 650, // Alto del documento
      };

    const fetchData = async () => {
        const response = await getFinca(props.params.nif)
        setFinca(response)
        setIsLoading(false)
    }
    console.log(imageDimensions)
    return  isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document size={customDocumentSize}>
          <Page size={customDocumentSize}>
            <View>
                <Image src={backgroundImageUrl} style={{ width: 460, height: 650 }} />
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"265px",
                              color: 'black', 
                              fontSize: '11px',
                              marginHorizontal: '2cm'}}>
                  {finca.razonSocial}
                </Text>
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"350px",
                              color: 'black', 
                              fontSize: '11px',
                              marginHorizontal: '2cm'}}>
                  {finca.direccion}, {finca.codigoPostal}, {(finca.municipio).toUpperCase()}, ({(finca.provincia).toUpperCase()})
                </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
}
 
export default withRouter(CertificadoVigilancia);