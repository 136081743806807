import React, { useState } from "react";
import { toast } from "react-toastify";
// import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createCommercial } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

const NuevoComercial = () => {
  const [comercial, setComercial] = useState({});

  function handleSubmit(data) {
    const toastInfo = toast.info("Guardando comercial");
    createCommercial(data)
      .then((res) => {
        console.log(res)
        if (res.status === 201) {
          toast.update(toastInfo, {
            render: "Comercial creado con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });

          window.location.replace("/comerciales");
        }else if (res.status === 403) {
          toast.update(toastInfo, {
            render: "Error al crear el comercial",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        } else {
          toast.update(toastInfo, {
            render: "Ha ocurrido un error",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
       
        
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al crear el comercial");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setComercial({
      ...comercial,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comerciales / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{path:`/comerciales`, label:`Comerciales`}, {path:`/comerciales`, label:`Nuevo`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridHead">Crear nuevo Comercial</div>
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={comercial.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={comercial.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={comercial.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={comercial.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
          </Grid>
          <input
            type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(comercial)}
          ></input>
        </form>
      </div>
    </>
  );
};

export default NuevoComercial;
