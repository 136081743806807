import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import { Box, Typography, Button } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { Iconify } from "../../../utilities";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import { CircularProgress } from "@mui/material";
import {
  getAAFF,
  getTecnico,
  getFincasAAFF,
  getFinca,
} from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Warning from "../../../icons/warning.svg";
import { numProveedoresAAFF } from "../../../functions/funciones";

const ComunidadesRows = [
  {
    id: "comunidad",
    numeric: false,
    disablePadding: false,
    label: "COMUNIDAD",
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "direccion",
    numeric: false,
    disablePadding: false,
    label: "DIRECCIÓN",
  },
  {
    id: "cp",
    numeric: false,
    disablePadding: false,
    label: "C.P.",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "activa",
    numeric: false,
    disablePadding: false,
    label: "ACTIVA",
  },
  {
    id: "empleados",
    numeric: false,
    disablePadding: false,
    label: "EMPLEADOS",
  },
  {
    id: "identificacionRiesgos",
    numeric: false,
    disablePadding: false,
    label: "IDENTIFICACIÓN DE RIESGOS Y MEDIDAS PREVENTIVAS",
  },
  {
    id: "planEmergencia",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE EMERGENCIA",
  },
  {
    id: "planPrevencion",
    numeric: false,
    disablePadding: false,
    label: "PLAN DE PREVENCIÓN",
  },
  {
    id: "hojaVisita",
    numeric: false,
    disablePadding: false,
    label: "HOJA DE VISITA",
  },
];



const HojasVisita = (props) => {
  console.log(props)
  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
//   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [fincas, setFincas] = useState([]);
  const [tecnico, setTecnico] = useState({});
  const [mensaje, setMensaje] = useState("");
  const [aaff, setAaff] = useState("");
  const [data, setData] = useState({
    totalItems: 0,
    totalPages: 0,
    rows: [],
    currentPage: 1,
  });
  useEffect(() => {
    fetchData();
    setRecargar(false);
  }, [recargar]);
  
  const fetchData = async ({ offset, limit, filtros }) => {
    

    try {
      setLoading(true);
      const responseTecnico = await getTecnico(props.params.nifTecnico)
      console.log(responseTecnico)
      setTecnico(responseTecnico)
      const responseAAFF = await getAAFF(props.params.nifAAFF)
      setAaff(responseAAFF)
      console.log(responseAAFF.id)
      const payload = {
        filtros,
        params: { page: offset, limit },
      };
      const response = await getFincasAAFF(payload);
      console.log(response)
      const { meta, data } = response;
        console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  
  
  const formatData = (data) => {
    data.map((row) => (
      console.log(row)))
    return data.map((row) => ({
        comunidad: row.razonSocial,
        nif: row.nif,
        direccion:row.direccion,
        cp:row.cp,
        municipio: row.municipio,
        activa: row.status ? "SI": "NO",
        empleados: row.trabajadores === null ? "NO" : row.trabajadores,
        identificacionRiesgos: <img src={Cross} alt="" style={{ width: 20 }} />,
        planEmergencia: <img src={Cross} alt="" style={{ width: 20 }} />,
        planPrevencion: <img src={Cross} alt="" style={{ width: 20 }} />,
        hojaVisita: <img src={Cross} alt="" style={{ width: 20 }} />
        
    }));
  };
  
  return (
    <>
      <BreadCrumb rutas={[{path:'/tecnicos', label:`Tecnicos`}, {path:`/tecnicos/${tecnico.nif}/aaff`, label:`${tecnico.nombre + " " + tecnico.apellidos}`}, {path: `/tecnicos`, label: `${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent tableContainer">
        <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={ComunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay comunidades"}
                />
                </Box>
            </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(HojasVisita);
