import styled from "@emotion/styled";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import React from "react";

function ProgressBarWithColors(props) {
  const BorderLinearProgress = styled(LinearProgress)((props) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      //backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
      backgroundColor: "#eee",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "10px",
      backgroundColor:
        props.value < 25 ? "#FF5252" : props.value < 75 ? "#fcba03" : "#7bc977",
    },
  }));

  return (
    <Box display="flex" alignItems="center" flexDirection="row" gap={2}>
      <Box>
        <BorderLinearProgress
          {...props}
          variant="determinate"
          style={{ width: 100 }}
        />
      </Box>
      <Box minWidth={35}>{props.label}</Box>
    </Box>
  );
}

export default ProgressBarWithColors;
