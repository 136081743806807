import axios from "axios";

const urlPath = "admin";

export const getAdmins = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getAdmin = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/${id}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};