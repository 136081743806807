import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import { Box, Typography, Button } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { Iconify } from "../../../utilities";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import moment from "moment";
import {getAAFFs, getConsultor} from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Tick from "../../../icons/tick.svg";
import Warning from "../../../icons/warning.svg";
import { docsPendientesProvs, numProveedoresAAFF } from "../../../functions/funciones";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";

const AAFFRows = [
  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
    overrideFunc: (data, row) => (
      <Link to={"/aaff/" + row.nif + "/comunidades"}>
        {data}
      </Link>
    ),
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "numeroComunidades",
    numeric: false,
    disablePadding: false,
    label: "Nº COMUNIDADES",
    overrideFunc: (data) => (
      
      <Typography style={{ textAlign: 'left'}}>
          {data}
      </Typography>
    ),
  },
  {
    id: "proveedores",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDORES",
    overrideFunc: (data) => (
        <Typography style={{ textAlign: 'left'}}>
            {data}
        </Typography>
    ),
  },
  {
    id: "docsPendientes",
    numeric: false,
    disablePadding: false,
    label: "",
    overrideFunc: (row) => (
      <>
        {row > 0 ? 
          <img src={Warning} width={15} alt="pendientes"/>
        : null}
      </>
    ),
  },
];



const AAFFConsultor = (props) => {
  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
//   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [consultor, setConsultor] = useState({});
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
    setRecargar(false);
  }, [recargar]);
  
  const fetchData = async ({ offset, limit, filtros }) => {
    
    try {
      setLoading(true);
      const responseConsultor = await getConsultor(props.params.nif)
      setLimite(limit)
      const payload = {
        filtros:{...filtros, consul_id: responseConsultor.id},
        params: { page: offset, limit },
      };
      const response = await getAAFFs(payload);
      console.log(response)
      setConsultor(responseConsultor)
      const { meta, data } = response;
        console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  
  
  const formatData = (data) => {
    
    return data.map((row) => ({
        
      aff_name: row.razonSocial || row.propertie?.aaff?.nombreContacto,
      nif: row.nif || row.propertie?.nif,
      id: row.id,
      municipio: row.municipio || row.propertie?.municipio,
      provincia: row.provincia,
      numeroComunidades: row.properties?.filter(objeto => objeto.status === true).length,
      proveedores:numProveedoresAAFF(row.properties).length,
      docsPendientes: docsPendientesProvs(row.properties),
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda({...filtros, commer_id:consultor.id})
    fetchData({ offset: 1, limit: limite, filtros: {...filtros, consul_id: consultor.id} });
  } 
  
  return (
    <>
      <BreadCrumb rutas={[{path:'/consultores', label:`Consultores`}, {path:'/consultores', label:`${consultor.nombre + " " + consultor.apellidos}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} />  
        <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={AAFFRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay administradores de fincas"}
                />
                </Box>
            </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(AAFFConsultor);
