import React from 'react'
import * as Admin from "../routes/Admin"
import * as AAFF from "../routes/AAFF"
import * as Tecnico from "../routes/Tecnico"
import * as Proveedor from "../routes/Proveedor"
import * as Comercial from "../routes/Comercial"
import * as Consultor from "../routes/Consultor"
import * as Superadmin from "../routes/Superadmin.jsx"
// import Menu from '../components/Menu/Menu'
// import Navbar from '../components/navbar/navbar'
import { Navbar, Menu } from '../components'

function ProtectedRoute(props) {

        //Auth loaded and signed in
        if (props.user?.rol === "ADMIN") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} menu={<Admin.MenuLinks />} routesClientes={<Admin.MenuLinksClientes />} routesPersonal={<Admin.MenuLinksPersonal />} routesListados={<Admin.MenuLinksListado />} /> 
                        <div className='contenedor'>
                            <Admin.RouteBundle />
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routes={<Admin.MenuLinks />} routesClientes={<Admin.MenuLinksClientes />} routesPersonal={<Admin.MenuLinksPersonal />} routesListados={<Admin.MenuLinksListado />} />
                      <div className='contenedor'>
                        <Admin.RouteBundle />
                      </div>
                    </>
                )
            }
        } 
    
         if (props.user?.rol === "AAFF") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} routesClientes={<AAFF.MenuLinksClientes user={props.user}/>} /> 
                        <div className='contenedor'>
                            <AAFF.RouteBundle user={props.user}/>
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routesClientes={<AAFF.MenuLinksClientes user={props.user} />}/>
                      <div className='contenedor'>
                        <AAFF.RouteBundle user={props.user} />
                      </div>
                    </>
                )
            }
        }
        if (props.user?.rol === "TECH") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} menu={<Tecnico.MenuLinks />} routesClientes={<Tecnico.MenuLinksClientes />} /> 
                        <div className='contenedor'>
                            <Tecnico.RouteBundle />
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routes={<Tecnico.MenuLinks />} routesClientes={<Tecnico.MenuLinksClientes />} />
                      <div className='contenedor'>
                        <Tecnico.RouteBundle />
                      </div>
                    </>
                )
            }
        }
        if (props.user?.rol === "SUPP") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} routesClientes={<Proveedor.MenuLinksClientes />} /> 
                        <div className='contenedor'>
                            <Proveedor.RouteBundle />
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routesClientes={<Proveedor.MenuLinksClientes />} />
                      <div className='contenedor'>
                        <Proveedor.RouteBundle />
                      </div>
                    </>
                )
            }
        }
        if (props.user?.rol === "COMM") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} routesClientes={<Comercial.MenuLinksClientes />} routesListados={<Comercial.MenuLinksListado />} /> 
                        <div className='contenedor'>
                            <Comercial.RouteBundle />
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routesClientes={<Comercial.MenuLinksClientes />} routesListados={<Comercial.MenuLinksListado />} />
                      <div className='contenedor'>
                        <Comercial.RouteBundle />
                      </div>
                    </>
                )
            }
        } 
        if (props.user?.rol === "CONSUL") {
            if(props.type === 'menuMov'){
                return (
                    <>
                        <Menu logoUrl={props.logoUrl} menu={<Consultor.MenuLinks />} routesClientes={<Consultor.MenuLinksClientes />} routesPersonal={<Consultor.MenuLinksPersonal />} routesListados={<Consultor.MenuLinksListado />} /> 
                        <div className='contenedor'>
                            <Consultor.RouteBundle />
                        </div>
                    </>
                )
            }else{
                return (
                    <>
                      <Navbar showSidebar = {props.showSidebar} user={props.user} rol = {props.user} routes={<Consultor.MenuLinks />} routesClientes={<Consultor.MenuLinksClientes />} routesPersonal={<Consultor.MenuLinksPersonal />} routesListados={<Consultor.MenuLinksListado />} />
                      <div className='contenedor'>
                        <Consultor.RouteBundle />
                      </div>
                    </>
                )
            }
        } 
        return null; //Loading
    
}

export default ProtectedRoute;
