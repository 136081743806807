import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { ventas } from "../../functions/ventasPrueba";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import Minus from "../../icons/minus.svg";
import { abrirArchivo, encryptParameters, formatoFechasEs } from "../../functions/funciones";
import NoVisitado from "../noVisitadoComunidad";
import { Box, TextField, Typography, Button, Tooltip } from "@mui/material";
import { DataTable, ProgressBarWithColors } from "../../components";
import "./comunidad.css";
import Add from "../../icons/add.svg";
import Swal from 'sweetalert2';

import { DeleteSale, generarPDF, getFinca, getSales } from "../../services";
import { toast } from "react-toastify";
import FiltrosVentas from "../filtros/filtrosVentas";
import { FaPencilAlt } from "react-icons/fa";

import { TbCertificate } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { BsTrash } from "react-icons/bs";
import { UsuarioContext } from "../../providers/userContext";

export default function ComunidadDocs(props) {

  const {nifFinca} = props
  console.log(nifFinca)
  const location = useLocation()
  const user = useContext(UsuarioContext)
  const canEdit = user.rol === "ADMIN" ||  user.rol === "CONSUL"
  const comunidadesRows = [
    {
      id: "color",
      numeric: false,
      disablePadding: true,
      label: "",
      overrideFunc: (data, row) => (
        <Typography
          className="servicios"
          style={{ padding: 0, background: row }}
        ></Typography>
      ),
    },
    {
      id: "ano",
      numeric: false,
      disablePadding: false,
      label: "AÑO",
    },
    {
      id: "servicio",
      numeric: false,
      disablePadding: false,
      label: "SERVICIO",
    },
    ...(nifFinca === undefined ? [{
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    }] : []),
    ...(nifFinca === undefined ? [{
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={user.rol === "AAFF" ?  "/comunidades/"+ row.finca.nif  : "/aaff/" + row.nif_aaff + "/comunidades/"+ row.finca.nif} >{data}</Link>
      ),
    }] : []),
    ...(nifFinca === undefined ? [{
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    }] : []),
    {
      id: "fechaVenta",
      numeric: false,
      disablePadding: false,
      label: "FECHA VENTA",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "fechaEnvio",
      numeric: false,
      disablePadding: false,
      label: "FECHA ENVIO",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "documentos",
      numeric: false,
      disablePadding: false,
      label: "DOCUMENTOS",
      colSpan: 4,
      overrideFunc: (data, row) => (
        <Typography style={{display: 'flex', gap: 5, color: '#fff'}}>
        {(row.all_row.serviceprops.id === 1 || row.all_row.serviceprops.id === 3) && (
          <>

              {renderDocs("Hoja visita", <HiClipboardDocumentList />, "hoja-visita", convertirAPdf, row.all_row, "cae")}


              {renderDocs("Identificación de riesgos", <MdHealthAndSafety />, "identificacion-riesgos", convertirAPdf, row.all_row, "cae")}


              {renderDocs("Plan emergencia", <IoIosWarning />, "plan-emergencia", convertirAPdf, row.all_row, "cae")}

          </>
        )}
        {(row.all_row.serviceprops.id === 2 || row.all_row.serviceprops.id === 4) && (
          <>
          {/* <td style={{ width: "25%", paddingRight: '23px' }}>
            {renderDocs('Lopd', 'lopd', convertirAPdf, row.all_row, "lopd")}            
          </td> */}

            {renderDocs("Certificado", <TbCertificate  />, row.all_row.serviceprops.id === 4 ? 'certificado-auditoria' : 'certificado-implantacion', convertirAPdf, row.all_row, "lopd")}
            {renderDocs("Implantación", <TiDocumentText />, 'documentacion-implantacion', convertirAPdf, row.all_row, "lopd")}

            {row.all_row.serviceprops.id === 4 ? 
              renderDocs("Auditoría", <TiDocumentText />, 'documentacion-auditoria', convertirAPdf, row.all_row, "lopd")
            : null }

              {row.all_row.detailsLopd[0]?.surveillanceVideo ? renderDocs("Video vigilancia", <IoVideocam />, "video-vigilancia", convertirAPdf, row.all_row, "lopd") : ''}

          </>
        )}
      </Typography>
      )
    },
    ...(props.grupoServicio === 1 ?
    [{
      id: "noVisita",
      numeric: false,
      disablePadding: false,
      label: "No visitada",
      overrideFunc: (data, row) => (
        <Typography style={{width:'75px'}}>
            <NoVisitado finca={row.all_row} nif={row.finca.nif} tipo={"finca"} />
        </Typography>
      ),
    }]: []),
    ...(props.grupoServicio === 1 ?
      [{
      id: "fechaRealizada",
      numeric: false,
      disablePadding: false,
      label: "FECHA REALIZADA",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    }]: []),
    ...(canEdit ?
    [{
      id: "editar",
      numeric: false,
      disablePadding: true,
      label: "",
      overrideFunc: (data, row) => (
        <>
          <Link to={`/servicios/editar/${row.editar.id}`} target="_blank"><FaPencilAlt /></Link>
          <Link to={''} onClick={() => comprobarEliminarVenta(row.editar.id)} style={{ marginLeft: '10px'}}><BsTrash /></Link>
        </>
      ),
    }]:[]),
  ];

  const [listaVentas, setListaVentas] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(50);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    console.log(busqueda)
    console.log(props)
    // fetchData({ offset:1, limit:limite, filtros:busqueda });
    fetchData();
  }, [recargar]);

  const renderDocs = (nombre, icono, enlace, convertirAPdf, row, tipo) => {
    
    let documento;
    let url;
    if(tipo === "lopd"){
      documento = row.detailsLopd?.find(obj => obj.sale_id === row.id);
    }
    if(tipo === 'cae'){
      documento = row.detailsCae?.find(obj => obj.sale_id === row.id);
    }
    if (!documento) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', fontSize: 16}}>
          <Tooltip title={nombre} style={{ fontSize: '16px'}}>
            <small
              className="docsStatus" 
              style={{ background: '#FF5252', height: 'auto' }}
            >
              {icono}
            </small>
        </Tooltip>
        </div>
      );
    }
  
    const { pendiente, visitada } = documento;

    if (tipo === "lopd" || (tipo === 'cae' && (pendiente || visitada))) {
      if (row.workcenters === null) {
        url = `/doc-render/${row.properties?.nif}/${row.id}/${enlace}`
      } else {
        url = `/doc-render/${row.properties?.nif}/${row.workcenters.id}/${row.id}/${enlace}`
      }
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
        <Tooltip title={nombre} style={{ fontSize: '16px'}}>
        {!pendiente && tipo !== 'lopd' && !visitada? 
          <small
            className="docsStatus" 
            style={
              { background: '#FF5252'}
            }
          >
            {icono}
          </small>
          :
            <Link to={url} target={"_blank"}>
              <small
                className="docsStatus" 
                style={
                  tipo === 'lopd' || visitada ?
                    { background: '#7bc977' }
                  : pendiente ?
                    { background: '#FCBA03' }
                  : { background: '#FF5252' }
                }
              >
                {icono}
              </small>
            </Link>
          }
        </Tooltip>
      </div>
    );
  };
  
  const convertirAPdf = async ({ nombre, nif, workcenter_id, sale_id }) => {
    let toastInfo = undefined
    try {
      
      const toastInfo = toast.info("Abriendo archivo", { autoClose: false });
      //   const response = await generarPDF(`http://localhost:3001/doc-render/${nif}/${nombre}`);
      // await generarPDF(`http://localhost:3001/doc-render/${nif}/${sale_id}/${nombre}`, `${nombre}`, `${nif}`).then( res => {
      const datos = {
        url: workcenter_id !== null ? `http://localhost:3001/doc-render/${nif}/${workcenter_id}/${sale_id}/${nombre}` : `http://localhost:3001/doc-render/${nif}/${sale_id}/${nombre}`,    
        nombre: nombre,
        nif: nif
      }
      await generarPDF(datos).then( res => {
        const url = URL.createObjectURL(res.data);
        
        // Abre el PDF en una nueva ventana o pestaña
        const newWindow = window.open('', '_blank');
        const pdfWindow = newWindow;
        pdfWindow.document.write('Cargando PDF...');
        // toastInfo = toast.info("Cargando PDF", { autoClose: false });

        // Promesa para cargar la URL
        const loadURL = new Promise((resolve, reject) => {
          const iframe = document.createElement('iframe');
          iframe.src = url;
          iframe.style.display = 'none';

          iframe.onload = () => {
            resolve();
          };
          
          iframe.onerror = (error) => {
            reject(error);
          };

          document.body.appendChild(iframe);
        });

        loadURL
          .then(() => {
            // Espera a que el evento "load" se dispare en el iframe antes de abrir la ventana emergente
            pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco
            pdfWindow.document.write('Cargando PDF...');
            // toastInfo = toast.info("Cargando PDF", { autoClose: false });
            setTimeout(() => {
              pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco nuevamente
              pdfWindow.location.href = url; // Abrir la URL del PDF
              toast.update(toastInfo, {
                render: "Cargado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
            }, 1000); // Espera 1 segundo (ajusta según sea necesario)
          })
          .catch((error) => {
            console.error('Error al cargar la URL:', error);
            pdfWindow.document.write('Error al cargar la URL');
          });
      })
    //   const blob = new Blob([response.data], { type: 'application/pdf' });
      
    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const fetchData = async ({ offset = 1, limit = limite, filtros = busqueda } = {}) => {
    
    // setLimite(limit);
    try {
      setLoading(true);
      if (props.nifFinca === undefined) {
        const payload = {
          filtros: user.rol === 'ADMIN' ? filtros : {...filtros, consul_id:user.user_id},
          params: { page: offset, limit },
        };
        const response = await getSales(payload)
        const { meta, data } = response;
        // console.log(response)
        setListaVentas(data);
        setData({
          meta,
          rows: formatData(data),
        });
      }else{
        console.log(props.nifFinca)
        const finca = await getFinca(props.nifFinca)
        console.log('finca.id')
        console.log(finca)
        console.log(location.pathname !== '/servicios')
        let grupoServ_id = null
        if(location.pathname !== '/servicios'){
          grupoServ_id = props.grupoServicio
        }
        console.log(grupoServ_id)
        const payload = {
          filtros:{prop_id:finca.id, grupoServ_id},
          params: { page: offset, limit },
        };
        const response = await getSales(payload)
        const { meta, data } = response;
        console.log(data)
        setListaVentas(data)
        setData({
          meta,
          rows: formatData(data),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const formatData = (data) => {
    console.log(data)
    return data.map((row) => ({
      color: row.color,
      ano: new Date(row.saleDate).getFullYear(),
      servicio: row.serviceprops.nombre,
      nif: row.properties.nif,
      nif_aaff: row.aaff.nif,
      finca: row.properties,
      comunidad: row.workcenters === null ? row.properties.razonSocial : row.workcenters.razonSocial,
      codigoPostal: row.properties.codigoPostal,
      fechaVenta: row.saleDate,
      fechaEnvio: row.adviceDate,
      all_row: row,
      noVisita: typeof row.detailsCae[0] !== 'undefined' && row.detailsCae[0]?.unRealized,
      fechaRealizada: !row.detailsCae[0]?.unRealized && row.detailsCae[0]?.saleDate !== null && row.detailsCae[0]?.saleDate !== undefined ? row.detailsCae[0]?.saleDate : '',
      editar: row,
      details_lopd: row.detailsLopd.length,
      details_cae: row.detailsCae[0]?.visitada === undefined ? null : row.detailsCae[0]?.visitada,
      unRealized: row.detailsCae[0]?.unRealized 
    }));
  };

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarEliminarVenta = async (id) => {
    // Llama a la función importada y pasa la devolución de llamada
      eliminarVenta(id).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
  };

  const eliminarVenta = async (id) => {
  console.log("eliminar venta " + id);
  return new Promise(async (resolve, reject) => {
    try {
      const result = await Swal.fire({
        title: "¿Quieres eliminar la venta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#888",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar"
      });

      if (result.isConfirmed) {
        const toastInfo = toast.info("Eliminando venta", { autoClose: false });
        await DeleteSale(id)
          .then(() => {
            toast.update(toastInfo, {
              render: "Eliminado correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            resolve(true);
          })
          .catch(error => {
            console.log(error);
            toast.update(toastInfo, {
              render: "Error al eliminar",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
            reject(error);
          });
      } else {
        // Si el usuario cancela la eliminación
        resolve(false);
      }
    } catch (error) {
      console.error("Error al eliminar la venta:", error);
      reject(error);
    }
  });
};
  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    console.log(busqueda);
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    fetchData({ offset: 1, limit: limite, filtros: busqueda });
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 
  
  return (
    <>
      <div className="pageContent tableContainer">
        {props.nifFinca === undefined && (
          <>
            <div className="contentBar" style={{padding: '0 10px'}}>
              <div className="contentBtns"></div>
              <Link to="/servicios/create" target={"_blank"}>
                <div className="contentButton">
                    <img src={Add} alt="Más" />
                </div>
              </Link>
            </div>
            {nifFinca === undefined && // si esta en la ficha de la comunidad los filtros no aparecen
              <FiltrosVentas onFilter={filtrarDatos} />
            }
          </>
        )}

        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay ventas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .tableContainer table tbody th.docs {
          text-align: center;
        }
        .tableContainer table tbody td {
          padding: 0;
        }
      `}</style>
    </>
  );
}
