import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  View,
  Page,
  Text,
  Image,
  StyleSheet,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Portada from "../../../images/PortadaFinca.png";
import Encabezado from "../../../images/Encabezado.jpg";
import Logo from "../../../images/LogoLAE.png";
import Tick from "../../../icons/tick.svg";
import { withRouter } from "../../../providers/withRouter";
import { getAAFF, getFincasAAFF } from "../../../services";
import CircularProgress from "@mui/material/CircularProgress";

const InformeSituacionComunidades = (props) => {
  const styles = StyleSheet.create({
    pagina: {
      // marginTop: "50px",
      padding: "0px 50px",
      fontSize: "8px",
    },
    pdfFooter: {
      marginTop: "70px",
    },
    table: {
      display: "table",
      width: "auto",
      marginBottom: 30,

      borderBottomWidth: 1,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColHeader: {
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      backgroundColor: "#375491",
      color: "#ffffff",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      marginBottom: 5,
      fontSize: 8,
    },
    p: {
      lineHeight: "1.5",
      textAlign: "justify",
      paddingBottom: 20,
    },
    containerHeader: {
      flexDirection: "row",
      borderStyle: "solid",
      borderWidth: 1,
      borderBottomWidth: 0,
      alignItems: "center",
      height: 30,
      textAlign: "center",
      fontWeight: "ultrabold",
      flexGrow: 1,
      fontSize: "6px",
    },
    fincaHeader: {
      width: "40%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingTop: 12,
    },
    nifHeader: {
      width: "16%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingTop: 12,
    },
    lopdHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    hojavisitaHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    planPrevencionHeader: {
      width: "11%",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
    },
    planEmergenciaHeader: {
      width: "11%",
    },
    rowCell: {
      flexDirection: "row",
      borderWidth: 1,
      borderBottomWidth: 0,
      alignItems: "center",
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
      fontSize: "6px",
    },
    fincaCell: {
      width: "40%",
      textAlign: "left",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingLeft: 2,
      paddingTop: 12,
    },
    nifCell: {
      width: "16%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
      paddingTop: 12,
    },
    lopdCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
    },
    hojavisitaCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
    },
    planPrevencionCell: {
      width: "11%",
      textAlign: "center",
      borderRightColor: "#000",
      borderRightWidth: 1,
      paddingVertical: 7.5,
      height: 30,
    },
    planEmergenciaCell: {
      width: "11%",
      textAlign: "center",
      height: 30,
    },
    svg: {
      height: "15pt",
      width: "15pt",
      margin: "auto",
    },
  });

  const [fincas, setFincas] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const Cross = (
    <Svg fill="#FF5252" viewBox="0 0 512 512" style={styles.svg}>
      <Path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" />
    </Svg>
  );
  const Tick = (
    <Svg fill="#7bc977" viewBox="0 0 512 512" style={styles.svg}>
      <Path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0" />
    </Svg>
  );
  useEffect(() => {
    // console.log(props)
    let aaffID = 0;
    getAAFF(props.params.nif).then((a) => {
      aaffID = a.id;
      console.log(a.id);
      getFincasAAFF({ aaff_id: a.id }).then((res) => {
        console.log(res);
        setFincas(res.data);
      });
      setIsloading(false);
    });
  }, []);

  return (
    <>
      {console.log(fincas)}
      {isLoading ? (
        <CircularProgress
          style={{
            color: "#92004D",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document size="A4" margin="2cm">
            <Page style={styles.body}>
              <View>
                <Image
                  id="portada"
                  className="portada"
                  style={{ width: "100%", height: "100vh" }}
                  src={Portada}
                />
              </View>

              <View>
                <View style={{marginTop: 0, paddingBottom: 20, marginHorizontal: '2cm'}} fixed>
                    <View >
                        <Image src={Logo} style={{marginTop: 20, width:'20%'}} />
                    </View>
                </View>
                {/* <View style={styles.pdfHeader} fixed>
                  <Image src={Encabezado} alt="encabezado" />
                </View> */}
                <View style={styles.pagina}>
                  <Text style={styles.p}>Estimado administrador de fincas.</Text>
                  <Text style={styles.p}>
                    Tras los primeros meses de trabajo administrativo, visitas
                    presenciales en las comunidades y redacción de informes de
                    prevención de riesgos os mostramos a continuación la situación
                    documental actual:
                  </Text>
                  <View style={styles.table}>
                    <View style={styles.containerHeader}>
                      <Text style={styles.fincaHeader}>FINCA</Text>
                      <Text style={styles.nifHeader}>NIF</Text>
                      <Text
                        style={styles.lopdHeader}
                      >{`CERTIFICADO \nLOPD`}</Text>
                      <Text
                        style={styles.hojavisitaHeader}
                      >{`HOJA DE \nVISITA`}</Text>
                      <Text
                        style={styles.planPrevencionHeader}
                      >{`IDENTIFICACIÓN DE RIESGOS \nY MEDIDAS PREVENTIVAS`}</Text>
                      <Text
                        style={styles.planEmergenciaHeader}
                      >{`PLAN \nEMERGENCIA`}</Text>
                    </View>
                    {fincas.length !== 0 ? (
                      fincas.map((finca, i) => {
                        return (
                          <View>
                            <View key={i} style={styles.rowCell}>
                              <Text style={styles.fincaCell}>
                                {finca.razonSocial}
                              </Text>
                              <Text style={styles.nifCell}>{finca.nif}</Text>
                              {finca.workcenters?.length === 0 ? (
                                <>
                                  <View style={styles.lopdCell}>{Cross}</View>
                                  <View style={styles.hojavisitaCell}>
                                    {Tick}
                                  </View>
                                  <View style={styles.planPrevencionCell}>
                                    {Tick}
                                  </View>
                                  <View style={styles.planEmergenciaCell}>
                                    {Tick}
                                  </View>
                                </>
                              ) : (
                                <>
                                  <View style={styles.lopdCell}></View>
                                  <View style={styles.hojavisitaCell}></View>
                                  <View style={styles.planPrevencionCell}></View>
                                  <View style={styles.planEmergenciaCell}></View>
                                </>
                              )}
                            </View>
                            {finca.workcenters?.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                {finca.workcenters?.map((ct, j) => {
                                  return (
                                    <View style={styles.rowCell}>
                                      <Text style={styles.fincaCell}></Text>
                                      <Text style={styles.nifCell}>
                                        {ct.razonSocial}
                                      </Text>
                                      <View style={styles.lopdCell}>{Cross}</View>
                                      <View style={styles.hojavisitaCell}>
                                        {Tick}
                                      </View>
                                      <View style={styles.planPrevencionCell}>
                                        {Tick}
                                      </View>
                                      <View style={styles.planEmergenciaCell}>
                                        {Tick}
                                      </View>
                                    </View>
                                  );
                                })}
                              </>
                            )}
                          </View>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </View>
                  <Text style={styles.p}>
                    Nuestro departamento técnico seguirá trabajando mensualmente
                    en la reclamación activa de documentación.
                  </Text>
                  <Text style={styles.p}>
                    Quedamos a su entera disposición para aclarar cualquier duda
                    que puedan tener,
                  </Text>
                  <Text style={styles.p}>Atentamente,</Text>
                  <Image src={Logo} alt="logo" style={{ width: "100px" }} />
                </View>
                <View style={styles.pdfFooter} fixed></View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default withRouter(InformeSituacionComunidades);
