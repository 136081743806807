import { useState, useEffect, useContext } from "react";
import moment from "moment";

import { getAaffListSuplier, getAAFFs, getListadoAaffsSupp } from "../services";
import { UsuarioContext } from "../providers/userContext";
import { Link } from "react-router-dom";

const formatData = (data) => {
  return data.map((row) => ({
    aff_name: row.razonSocial || row.propertie?.aaff?.nombreContacto,
    nif: row.nif || row.propertie?.nif,
    fechaEncargo: moment(row.fechaEncargo).format("DD/MM/YYYY"),
    id: row.id,
    communityNumbers: row.properties ?? [],
    communityPercent: row.properties ?? [],
    direction: row.direccion || row.propertie?.direccion,
    cp: row.codigoPostal || row.propertie?.codigoPostal,
    provincia: row.provincia || row.propertie?.provincia,
    municipio: row.municipio || row.propertie?.municipio,
    empleados: (row.trabajadores || row.propertie?.trabajadores) ?? "No",
    servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
    hojasVisita: row.visitSheets,
    visitada: row.visitada || row.propertie?.visitada,
    noVisitada: row,
    telefono: row.telefono || row.propertie?.telefono,
  }));
};

function useAFFs() {
  const {rol} = useContext(UsuarioContext)
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(limit)
    setLimite(limit)
    const payload = {
      params: { page: offset, limit },
      filtros,
    };
    try {
      setLoading(true);
      // const response = await getAAFFs({
      //   params: { page: offset, limit },
      //   filtros,
      // });
      

      let response = []
      if(rol === 'SUPP'){
        response = await getListadoAaffsSupp(payload);
      }else{
        response = await getAAFFs(payload);
        
      }

      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetchData,
    loading,
    limite
  };
}

export default useAFFs;
