import React, { useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = ({rutas}) => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    let local = location.pathname.charAt(1).toUpperCase()+location.pathname.slice(2);
    if(local.split('/').length==2 || local.split('/').length==3){    
        local = local.split('/')[0]
    }    

    useEffect(() => {
        // console.log(rutas)
        setBreadcrumbs(rutas);
      }, [rutas]);

    return ( 
        
        <>
            <div style={{ marginLeft: 10, padding:'20px 0', fontSize: 12}}>
                <Link to="/" style={{color:'#9b9898 !important'}}>Inicio</Link>
                {breadcrumbs.map((breadcrumb, index) => (
                    <span key={index}>
                    &nbsp;/&nbsp;
                    {index === breadcrumbs.length - 1 ? (
                        breadcrumb.label
                    ) : (
                        <Link to={breadcrumb.path} style={{color:'#9b9898 !important'}}>{breadcrumb.label}</Link>
                    )}
                    </span>
                ))}
            </div>
            {/* <nav className="pt-3" aria-label="breadcrumb">
               {data === undefined ?  
                <ol className="breadcrumb">
                        <li className="breadcrumb-item">CRM</li>
                        <li className="breadcrumb-item active" aria-current="page">{ local }</li>
                </ol>:
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">CRM</li>
                    <li className="breadcrumb-item ">{ local }</li>
                    <li className="breadcrumb-item active" aria-current="page">{ data }</li>
                </ol>
                }
            </nav> */}
        </>
           

        
    );
}
 
export default BreadCrumb;