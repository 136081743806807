import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import {
  DataTable,
  ProgressBarWithColors,
  SmoothModal,
} from "../../../components";
import { Iconify } from "../../../utilities";
import { useAAFFs } from "../../../hooks";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { Link } from "react-router-dom";

const motivosNoVisita = [
  "El AAFF no quiere visita en la renovación",
  "El AAFF no quiere que empecemos todavía las visitas",
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
  alignItems: "center",
}));

const AAFF = () => {
  const user = useContext(UsuarioContext);

  const [recargar, setRecargar] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [motivoNoVisita, setMotivoNoVisita] = useState(motivosNoVisita[0]);
  const { loading, data, fetchData, limite } = useAAFFs();
  const [busqueda, setBusqueda] = useState({});

  const handleOpenNewTab = ({ nif }) => {
    if (!nif) return;
    window.open(`aaff/${nif}/comunidades`, "_top");
  };

  const handlePagination = ({ offset, limit }) => {
    const { id } = user
    // setPagination({ page: offset, limit });
    fetchData({ offset, limit, filtros:busqueda });
  };

  const handleOpenModal = () => setOpenModal(true);

  const closeModal = () => setOpenModal(false);

  const comunidadesRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to= {`/aaff/${row.nif}/comunidades`}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
      overrideFunc: (data, row) => (
        <Link to= {`/aaff/${row.nif}/comunidades`}>
          {data}
        </Link>
      )
    },
    {
      id: "telefono",
      numeric: false,
      disablePadding: false,
      label: "TELÉFONO",
    },
  ];

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      <SmoothModal open={openModal} onClose={closeModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"}>
            Motivo de No Visita
          </Typography>

          <Box sx={{ width: "90%" }}>
            <Select
              fullWidth
              displayEmpty
              value={motivoNoVisita}
              onChange={setMotivoNoVisita}
            >
              {motivosNoVisita.map((motivo) => (
                <MenuItem key={motivo} value={motivo}>
                  {motivo}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <ColorButton>
              <Typography>Guardar</Typography>
            </ColorButton>
            <Button onClick={closeModal}>Cancelar</Button>
          </Box>
        </Box>
      </SmoothModal>
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  ADMINISTRADORES DE FINCAS
              </div>
          </div>
      </div>
      <Box className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} />  
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data?.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data?.current_page}
                meta={data?.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default withRouter(AAFF);
