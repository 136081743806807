import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteIcon from '@mui/icons-material/Delete';
function srcset(image, width, height, rows = 2, cols = 2) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function CustomImageList({imgs, addImagesToDelete}) {
  console.log(imgs)
  const imageListStyle = {
    width: 399,
    height: 200,
    // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
    transform: 'translateZ(0)',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // Ajusta el número de columnas según tus necesidades
    gap: 2,
  };
  return (
    <ImageList
      sx={imageListStyle}
      // rowHeight={200}
      gap={1}
    >
      {imgs.map((item, index) => {
        const cols = item.featured ? 2 : 1;
        const rows = item.featured ? 2 : 1;

        return (
          <ImageListItem className={`img-${item.indice}`} key={item.img} cols={cols} rows={rows}>
            <img
              key={index} src={item?.img}
              // {...srcset(item.img, 250, 200, rows, cols)}
              alt={item?.title}
              loading="lazy"
            />
            <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                  'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              title={item.title}
              position="top"
              actionIcon={
                <IconButton
                  onClick={()=>addImagesToDelete(item)}
                  sx={{ color: 'white' }}
                >
                  <DeleteIcon />
                </IconButton>
              }
              actionPosition="right"
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}